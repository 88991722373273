export default {
    topBriefContent: {
      title: `智能人效 ，降本增效`,
      subTitle: `Data+AI 助力提升零售企业门店<br />人效，实现企业人力资源增效`,
      titleStyle: {
        color: '#0475FF',
      },
      playVideo: false,
      videoPath: '',
      rightImg: require("~/assets/images/efficiency/banner.jpg"),
    },
  
    graphicFirstContent:{
      headline: '产品特点',
      summarize: '',
      descMaxWidth: '228px',
      graphicList:[{
        img: require('~/assets/images/efficiency/feature_1.png'),
        title: '可衡量可量化',
        desc: `可实施“调研-分析-动产-建议指导-效果跟踪闭环”，自动分析使用前后业绩、人效、编制、班次联动关系，可精确衡量编制优化带来的降本增效价值。`,
      },{
        img: require('~/assets/images/efficiency/feature_2.png'),
        title: '人效深度分析',
        desc: '具备完整的零售门店人效深度分析方法论，结合大数据分析技术与行业分析经验，层层下钻定位问题，解决思路行之有效。',
        listStyle: {
            marginTop: '41px'
        },
        imgStyle: {
            width: '172px',
            height: '150px',
        }
      },{
        img: require('~/assets/images/efficiency/feature_3.png'),
        title: '一键上线',
        desc: '一键完成所有前期技术工作。免工程实施和维护成本，当天对接客户，当天即可投入试用。',
        listStyle: {
            marginTop: '2px'
        },
        imgStyle: {
            position: 'relative',
            top: '30px',
        }
      },{
        img: require('~/assets/images/efficiency/feature_4.png'),
        title: '自我优化',
        desc: '算法自动迭代优化，根据业务实际情况持续进化。',
        listStyle: {
            marginTop: '2px'
        },
        imgStyle: {
            position: 'relative',
            top: '35px',
        }
      }]
    },
  
    horizontalGraphicContent: {
        headline: '功能介绍',
        list:[
          {
            align: 'left',
            img: require('~/assets/images/efficiency/function_1.png'),
            title: '人 效 编 制 建 议',
            text: `自动化分析区域人效，动态优化编制建议。`,
            efficiency: true,
          },
          {
            align: 'right',
            img: require('~/assets/images/efficiency/function_2.png'),
            title: '人 效 问 题 诊 断',
            text: `自动诊断每家门店的业绩问题，给出合理建议。`,
            efficiency: true,
          },
          {
            align: 'left',
            img: require('~/assets/images/efficiency/function_3.png'),
            title: '门 店 自 动 排 班',
            text: `排班运筹模型，根据门店编制与销量情况智能排班。`,
            efficiency: true,
          },
          {
            align: 'right',
            img: require('~/assets/images/efficiency/function_4.png'),
            title: '人 员 能 力 评 估',
            text: `从往期的业绩表现等综合评分获取人员能力评估。`,
            efficiency: true,
          }
        ],
    },
  
    appData: {
      title: '应用场景',
      bgImg: require('~/assets/images/efficiency/group.png'),
      list: [
        {
          title: '优化节流',
          subTitle: '截止2019年3月下旬，通过排版优化和人员优化，企业释放1720余人，2019年全年节约人员开支超8600万人民币。',
        },
        {
          title: '提升人效',
          subTitle: '在销售业绩不受影响的同时，优化后整体人效得到提升。',
        },
        {
          title: '广受好评',
          subTitle: '该项目在企业内部的推广速度远超出项目组预期，受到业务部门和公司管理层的热烈欢迎。',
        },
      ]
    }
  }
  