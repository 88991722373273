<template>
  <div class="nuza-container">
    <div class="nuza-banner">
      <div class="nuza-wrap">
        <div class="title">
          DataNuza
        </div>
        <div class="desc">
          如何在合适的时间、合适的地点为合适的消费者
          <br>提供合适的商品？
        </div>
        <!-- <a
          href="#form"
          class="apply-btn"
          @click="showCode"
        >产品咨询</a> -->
      </div>
    </div>
    <div class="nuza-bg">
      <img
        src="../../assets/images/nuza/logo.png"
        alt
      >
      <div class="des">
        一站式消费者运营平台，凝练大数据和AI算法能力，为企业提供全链路的消费者运营服务，
        助力企业实现客群运营、精准营销，为顾客生命周期提频增值，为企业降低营销成本、提升转化率。
      </div>
    </div>
    <div class="nuza-structure">
      <div class="title">
        平台架构
      </div>
      <div class="sub-title">
        DataNuza赋能消费者全生命周期运营
      </div>
      <img
        src="../../assets/images/nuza/banner3.png"
        alt
      >
      <div class="hide-content">
        <div>业务实践</div>

        <div>业务场景</div>

        <div>客群运营-顾客生命周期提频增值</div>
        <div>基于客群生命周期洞察，锁定客群流转瓶颈与待运营客群</div>
        <div>圈选待运营客群，进一步洞察人群结构与消费特征</div>
        <div>基于客群特征制定营销策略，实施精准营销</div>

        <div>精准营销 – 降低营销成本，提升营销转化率</div>
        <div>基于营销计划与顾客分类标签，圈选目标客群</div>
        <div>结合目标客群特征，组合商品、渠道、内容与营销规则</div>
        <div>量化追踪营销活动漏斗转化数据，持续优化营销策略</div>

        <div>系统工具</div>

        <div>DataNuza</div>

        <div>CDP：“人群洞察与圈选”</div>
        <div>品牌客群生命周期洞察</div>
        <div>基于标签的目标客群圈选</div>
        <div>目标客群画像洞察（BI）</div>
        <div>目标客群生命周期及消费洞察</div>

        <div>MAP：“营销创建与复盘”</div>
        <div>营销自动化工具（MAT）</div>
        <div>预置营销活动模板</div>
        <div>便捷的营销活动配置工具</div>
        <div>营销活动漏斗分析</div>

        <div>CAP – “营销渠道与内容”</div>
        <div>接入托管自有营销渠道</div>
        <div>多渠道营销素材管理</div>
        <div>预置营销玩法素材模板</div>
        <div>营销渠道效率分析</div>

        <div>数据中台</div>

        <div>数据中台</div>

        <div>业务模型</div>

        <div>顾客标签（Customer’s Tags）</div>
        <div>洞察指标（Customer’s Metrics）</div>
        <div>OneID</div>
        <div>模型层</div>
        <div>算法建模</div>
        <div>明细事实数据</div>
        <div>汇总事实数据</div>
        <div>维度数据</div>

        <div>企业一方数据</div>
        <div>平台电商</div>
        <div>自有电商</div>
        <div>活动系统</div>
        <div>微信公众号</div>
        <div>线下POS</div>
        <div>企业微信</div>
        <div>CRM</div>
        <div>全场景客服</div>
        <div>ERP</div>

        <div>二方数据</div>
        <div>广告投放、曝光触达等</div>

        <div>三方数据</div>
        <div>天气、商圈等</div>
      </div>
    </div>
    <div class="nuza-ability">
      <div class="content">
        <div class="title">
          核心能力
        </div>
        <div class="sub-title">
          三大核心模块，助力品牌精准占据消费者心智
        </div>
        <div class="box">
          <div class="item">
            <div class="top">
              <div class="top-title">
                CDP
              </div>
              <div class="top-subtitle">
                人群洞察与圈选能力
              </div>
            </div>
            <ul class="bottom">
              <li>
                <i class="square" />品牌客群生命周期洞察
              </li>
              <li>
                <i class="square" />基于标签的目标客群圈选
              </li>
              <li>
                <i class="square" />目标客群画像洞察（BI）
              </li>
              <li>
                <i class="square" />目标客群生命周期及消费洞察
              </li>
            </ul>
          </div>
          <div class="item">
            <div class="top">
              <div class="top-title">
                MAP
              </div>
              <div class="top-subtitle">
                营销创建与复盘能力
              </div>
            </div>
            <ul class="bottom">
              <li>
                <i class="square" />营销自动化工具（MAT）
              </li>
              <li>
                <i class="square" />预置营销活动模板
              </li>
              <li>
                <i class="square" />便捷的营销活动配置工具
              </li>
              <li>
                <i class="square" />营销活动漏斗分析
              </li>
            </ul>
          </div>
          <div class="item">
            <div class="top">
              <div class="top-title">
                CAP
              </div>
              <div class="top-subtitle">
                营销渠道与内容赋能
              </div>
            </div>
            <ul class="bottom">
              <li>
                <i class="square" />接入托管自有营销渠道
              </li>
              <li>
                <i class="square" />多渠道营销素材管理
              </li>
              <li>
                <i class="square" />预置营销玩法素材模板
              </li>
              <li>
                <i class="square" />营销渠道效率分析
              </li>
            </ul>
          </div>
        </div>
        <div class="hide-content">
          <div>产品功能与业务闭环</div>
          <div>活动复盘MAP</div>
          <div>运营监控CDP</div>
          <div>营销投放MAP</div>
          <div>客群洞察CDP</div>
          <div>素材准备CAP</div>
          <div>客群圈选CDP</div>
          <div>触点管理CAP</div>
        </div>
      </div>
    </div>
    <div class="nuza-product">
      <div class="title">
        产品优势
      </div>
      <div class="content">
        <div class="item">
          <div class="word left">
            <div class="number">
              <div class="number-content">
                1
              </div>
            </div>
            <div class="sub-title">
              强大的企业一方消费者数据整合变现能力
            </div>
            <div class="des">
              借助数据中台，深度融合企业一方业务系统，提取消费者多触点、全链路消费
              与行为数据，生成消费者标签与洞察指标，以T+1或实时方式增量更新。
            </div>
          </div>
          <div class="images right">
            <img
              src="../../assets/images/nuza/product1.png"
              alt
            >
          </div>
        </div>
        <div class="item">
          <div class="images left">
            <img
              src="../../assets/images/nuza/product2.png"
              alt
            >
          </div>
          <div class="word right">
            <div class="number">
              <div class="number-content">
                2
              </div>
            </div>
            <div class="sub-title">
              一站式精准营销与多渠道消费者运营能力
            </div>
            <div class="des">
              基于CDP、MAP与CAP三个产品模组，为客户提供一站式的客群洞察、顾客运营、精准营销、触点接入、素材管理、活动设计等全链路业务闭环工具。
            </div>
          </div>
        </div>
        <div class="item">
          <div class="word left">
            <div class="number">
              <div class="number-content">
                3
              </div>
            </div>
            <div class="sub-title">
              有工具、有方法，快速赋能一线业务人员
            </div>
            <div
              class="des"
            >
              内置基于成熟消费者运营方法论设计的数据洞察模块（CDP），更有丰富的营销规则与活动玩法模板，帮助顾客运营与精准营销经验欠丰富的客户快速掌握数字化业务能力。
            </div>
          </div>
          <div class="images right">
            <img
              src="../../assets/images/nuza/product3.png"
              alt
            >
          </div>
        </div>
      </div>
    </div>
    <div class="nuza-customer">
      <div class="banner-container">
        <div class="energy-title">
          <p class="title-mian">
            客户成功
          </p>
        </div>
        <div class="energy-content">
          <div class="fade-box">
            <transition
              name="fade"
              mode="out-in"
            >
              <div
                :key="energyContent.name"
                class="energy-banner"
                :style="{
                  backgroundImage: 'url(' + energyContent.banner + ')',
                  backgroundSize: 'cover',
                }"
              >
                <div>
                  <img
                    :src="energyContent.logoHover"
                    alt
                  >
                  <p>{{ energyContent.name }}</p>
                  <div class="des-content">
                    <div
                      class="des"
                      v-html="energyContent.des"
                    />
                  </div>
                </div>
                <el-button
                  v-if="energyContent.name !== '得益乳业'"
                  type="primary"
                  size="medium"
                  @click="toMore(energyContent.url)"
                >
                  了解更多
                </el-button>
              </div>
            </transition>
          </div>
          <div class="energy-list">
            <div
              v-for="(item, index) in energyData"
              :key="item.name"
              :class="index === activeIndex ? 'itemActive' : 'item'"
              @mouseenter="() => toSwitch(item, index)"
            >
              <img
                :src="index === activeIndex ? item.logoHover : item.logo"
                alt
              >
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      width="400px"
      title="咨询方式"
      class="dialog"
      :visible.sync="dialogTableVisible"
    >
      <p class="tip"> 
        请扫描微信二维码
      </p>
      <img
        src="~@/assets/image/contactCode.png"
        alt=""
        class="qrcode"
      >
    </el-dialog>
  </div>
</template>
<script>
import zhuoshiniBanner from "~/assets/images/shouye/zhuoshiniBanner.png";
import zhuoshiniLogo from "~/assets/images/customerLogo/zhuoshini.png";
import zhuoshiniLogoHover from "~/assets/images/customerLogo/zhuoshini-hover.png";
import deyiBanner from "~/assets/images/nuza/deyiBanner.png";
import deyiLogo from "~/assets/images/customerLogo/deyi.png";
import deyiLogoHover from "~/assets/images/customerLogo/deyi-hover.png";
export default {
  data() {
    return {
        dialogTableVisible: false,
      energyData: [
        {
          name: "卓诗尼",
          banner: zhuoshiniBanner,
          logo: zhuoshiniLogo,
          logoHover: zhuoshiniLogoHover,
          des:
            "依托DataNuza，整合会员全域数据，构建会员标签体系，并为品牌提供了简单易<br/>用的会员智能化运营工具，依据对效果的判断反哺客群运营策略。市场活动投放更<br/>为精准，费用降低，同时票券、营销活动的触达转化率不断刷新历史记录，三个月<br/>内触达转化率就从1%左右提升到了3%-6%，最高到了8%。",
          url:
            "https://mp.weixin.qq.com/s?__biz=MzIzMzczNjgyNQ==&mid=2247486443&idx=1&sn=4e5dd88834488edb8818b972a9a623b2&chksm=e88054fddff7ddeb4225d2fd1ca38fa358c10933d1a0f251238deba5093c79743a00dfb727a6&token=1116684313&lang=zh_CN#rd"
        },
        {
          name: "得益乳业",
          banner: deyiBanner,
          logo: deyiLogo,
          logoHover: deyiLogoHover,
          des:
            "基于业务调研理解，创新搭建消费者触点场景，依托DataNuza，建立了可闭环、<br/>可实现、精准化的消费者数据化运营链路。青岛地区作为创新业务试点，短短2个<br/>月时间内在线化订单金额提升300%以上，平均客单价提升50%以上，ROI达到约<br/>1:7.51。",
          url: ""
        }
      ],
      energyContent: {
        name: "卓诗尼",
        banner: deyiBanner,
        logo: deyiLogo,
        logoHover: deyiLogoHover,
        des:
          "依托DataNuza，整合会员全域数据，构建会员标签体系，并为品牌提供了简单<br/>易用的会员智能化运营工具，依据对效果的判断反哺客群运营策略。市场活动投<br/>放更为精准，费用降低，同时票券、营销活动的触达转化率不断刷新历史记录，<br/>三个月内触达转化率就从1%左右提升到了3%-6%，最高到了8%。",
        url:
          "https://mp.weixin.qq.com/s?__biz=MzIzMzczNjgyNQ==&mid=2247486443&idx=1&sn=4e5dd88834488edb8818b972a9a623b2&chksm=e88054fddff7ddeb4225d2fd1ca38fa358c10933d1a0f251238deba5093c79743a00dfb727a6&token=1116684313&lang=zh_CN#rd"
      },
      activeIndex: 0
    };
  },
  created() {
    const item = this.energyData[0];
    this.energyContent = {
      name: item.name,
      banner: item.banner,
      logo: item.logo,
      logoHover: item.logoHover,
      des: item.des,
      url: item.url
    };
  },
  methods: {
    showCode(){
        this.dialogTableVisible = true
    },
    toMore(url) {
      window.open(url);
    },
    toSwitch(item, index) {
      this.energyContent = {
        name: item.name,
        banner: item.banner,
        logo: item.logo,
        logoHover: item.logoHover,
        des: item.des,
        url: item.url
      };
      this.activeIndex = index;
    }
  },
  head() {
    return {
      title: "CDP_大数据_消费者运营_数据中台_奇点云",
      meta: [
        {
          name: "keywords",
          content: "CDP，大数据，消费者运营，数据中台，奇点云"
        },
        {
          name: "description",
          content:
            "如何实现消费者运营？一站式大数据智能服务平台，为企业客户提供数字化消费者运营解决方案。运用CDP平台进行人群的圈选与洞察，为后续的消费者运营策略做基础。"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.dialog{
    text-align: center;
}
.tip{
    margin-bottom: 20px;
}

.qrcode{
    width: 200px;
    height: 200px;
}
.nuza-container {
  min-width: 1280px;
//   padding-top: 68px;
  margin-bottom: -100px;
  .nuza-title {
    font-size: 46px;
    font-weight: 600;
    line-height: 65px;
    letter-spacing: 2px;
  }
  .nuza-wrap {
    width: 1280px;
    margin: 0 auto;
  }
  .nuza-banner {
    background: url("~@/assets/images/nuza/banner1.jpg") center 0;
    background-size: auto 100%;
    height: 740px;
    .nuza-wrap {
      padding-top: 228px;
      .title {
        width: 245px;
        font-size: 50px;
        font-weight: 600;
        color: #ffffff;
        letter-spacing: 2px;
        padding-bottom: 30px;
      }
      .desc {
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        width: 442px;
        padding-bottom: 80px;
        line-height: 32px;
      }
      .apply-btn {
        display: block;
        width: 140px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #07b4d4;
        border-radius: 2px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .nuza-bg {
    width: 1280px;
    height: 580px;
    background: url("~@/assets/images/nuza/banner2.png") center 0;
    background-size: cover;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 87px;
    text-align: center;
    padding: 174px 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    img {
      width: 152px;
      height: 120px;
    }
    .des {
      font-size: 20px;
      color: #ffffff;
      line-height: 36px;
      letter-spacing: 1px;
    }
  }
  .nuza-structure {
    width: 1280px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 146px;
    .title {
      font-size: 46px;
      color: #151515;
      font-weight: 600;
    }
    .sub-title {
      font-size: 28px;
      color: #1868ee;
      font-weight: 600;
      padding-top: 10px;
      padding-bottom: 40px;
    }
    img {
      width: 100%;
    }
  }
  .nuza-ability {
    background: url("~@/assets/images/nuza/banner4.jpg") center 0;
    background-size: auto 100%;
    height: 1680px;
    padding-top: 110px;
    .content {
      text-align: center;
      .title {
        font-size: 46px;
        font-weight: 600;
        color: #ffffff;
        line-height: 65px;
        letter-spacing: 2px;
      }
      .sub-title {
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        padding-top: 10px;
        padding-bottom: 52px;
      }
      .box {
        width: 1280px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
      }
      .item {
        width: 380px;
        height: 380px;
        border-radius: 8px;
        color: #fff;
        text-align: left;
        overflow: hidden;
        .top {
          width: 100%;
          height: 140px;
          background: url("~@/assets/images/nuza/top.png") center 0;
          background-size: cover;
          padding-left: 40px;
          padding-top: 28px;
          padding-bottom: 28px;
          .top-title {
            font-size: 30px;
            margin-bottom: 10px;
          }
          .top-subtitle {
            font-size: 22px;
          }
        }
        .bottom {
          list-style: none;
          height: 240px;
          padding-left: 40px;
          font-size: 18px;
          padding-top: 37px;
          padding-bottom: 37px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background-color: rgba(6, 32, 63, 0.4);
          &:hover {
            background-color: #1181de;
            cursor: pointer;
            transition: all 0.5s;
          }
          .square {
            display: inline-block;
            transform: rotate(45deg);
            width: 11px;
            height: 11px;
            background: #fff;
            border-radius: 1px;
            margin-right: 25px;
            opacity: 0.4;
          }
        }
      }
    }
  }
  .nuza-product {
    padding-bottom: 127px;
    .title {
      color: #151515;
      font-size: 46px;
      text-align: center;
      padding-top: 128px;
      padding-bottom: 60px;
      font-weight: 600;
    }
    .content {
      width: 1280px;
      margin: 0 auto;
      .item {
        overflow: hidden;
        .left {
          float: left;
        }
        .right {
          float: right;
        }
        .word {
          padding-top: 86px;
          width: 573px;
          .number {
            width: 130px;
            height: 130px;
            border: 1px solid #ccc;
            position: relative;
            .number-content {
              position: absolute;
              left: 30px;
              bottom: 0;
              width: 30px;
              font-size: 46px;
              font-weight: bold;
              color: #151515;
              border-bottom: 4px solid #07b4d4;
            }
          }
          .sub-title {
            color: #151515;
            font-size: 26px;
            font-weight: 600;
            padding-top: 40px;
            padding-bottom: 40px;
          }
          .des {
            font-size: 16px;
            color: #151515;
            line-height: 34px;
          }
        }
        .images {
          width: 573px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .nuza-customer {
    height: 1120px;
    background-color: #f7f9fc;
    .banner-container {
      width: 1280px;
      height: 100%;
      margin: 0 auto;
      .energy-title {
        padding-top: 75px;
        padding-bottom: 50px;
        text-align: center;
        color: #151515;
        .title-mian {
          font-size: 46px;
          font-weight: 600;
          margin-bottom: 10px;
        }
      }
      .energy-content {
        width: 100%;
        height: 800px;
        margin-bottom: 80px;
        position: relative;
        overflow: hidden;
        .fade-enter,
        .fade-leave-to {
          opacity: 0;
        }
        .fade-enter-active,
        .fade-leave-active {
          transition: opacity 0.5s;
        }
        .fade-box {
          height: 640px;
          width: 100%;
          overflow: hidden;
        }
        .energy-banner {
          width: 100%;
          height: 640px;
          text-align: center;
          color: #fff;
          padding: 159px 0;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: center;
          img {
            height: 30px;
            margin-bottom: 10px;
          }
          p {
            font-size: 28px;
            font-weight: 600;
          }
          .des-content {
            display: flex;
            justify-content: center;
          }
          .des {
            text-align: left;
            font-size: 16px;
            margin-top: 39px;
            line-height: 28px;
          }
          .el-button--primary {
            background-color: #1ccada;
            border: 1px solid #1ccada;
            border-radius: 0;
            width: 160px;
            height: 40px;
            span {
              font-size: 16px;
            }
            &:hover {
              background-color: #1ccada;
              border: 1px solid #1ccada;
            }
          }
        }
        .energy-list {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 160px;
          display: flex;
          .item {
            flex: 1;
            padding: 42px 0;
            padding-left: 51px;
            background-color: #fff;
            img {
              height: 30px;
            }
            p {
              font-weight: 600;
              color: #151515;
              margin-top: 16px;
              font-size: 20px;
            }
            &:hover {
              cursor: pointer;
            }
          }
          .itemActive {
            flex: 1;
            height: 240px;
            padding: 81px 0;
            padding-left: 51px;
            margin-top: -81px;
            background-color: #1868ee;
            border-radius: 6px;
            position: relative;
            img {
              height: 30px;
            }
            p {
              font-weight: 600;
              color: #fff;
              margin-top: 16px;
              font-size: 20px;
            }
            &::after {
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              background: url("~@/assets/images/customerLogo/watermark.png")
                right bottom no-repeat;
              background-size: auto 100%;
            }
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1440px) {
    .nuza-wrap {
      width: 1000px;
    }
    .nuza-title {
      font-size: 40px;
    }
    .nuza-banner {
      height: 560px;
      .nuza-wrap {
        padding-top: 160px;
        .desc {
          font-size: 16px;
        }
      }
    }
    .nuza-bg {
      width: 1000px;
      height: 454px;
      padding: 130px 70px;
      img {
        width: 100px;
        height: 80px;
      }
      .des {
        font-size: 18px;
        color: #ffffff;
      }
    }
    .nuza-structure {
      width: 1000px;
      .title {
        font-size: 40px;
      }
      .sub-title {
        font-size: 28px;
      }
    }
    .nuza-ability {
      height: 1310px;
      padding-top: 80px;
      .content {
        .title {
          font-size: 40px;
        }
        .sub-title {
          font-size: 16px;
          padding-top: 8px;
          padding-bottom: 42px;
        }
        .box {
          width: 1000px;
        }
        .item {
          width: 290px;
          height: 290px;
          .top {
            height: 106px;
            padding-left: 30px;
            padding-top: 20px;
            padding-bottom: 20px;
            .top-title {
              font-size: 28px;
              margin-bottom: 8px;
            }
            .top-subtitle {
              font-size: 20px;
            }
          }
          .bottom {
            height: 184px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-left: 30px;
            font-size: 16px;
            padding-top: 28px;
            padding-bottom: 28px;
            .square {
              width: 9px;
              height: 9px;
              background: #fff;
              border-radius: 1px;
              margin-right: 23px;
            }
          }
        }
      }
    }
    .nuza-product {
      .title {
        font-size: 40px;
        padding-top: 128px;
        padding-bottom: 60px;
      }
      .content {
        width: 1000px;
        .item {
          .word {
            padding-top: 58px;
            width: 476px;
            .number {
              width: 100px;
              height: 100px;
              .number-content {
                left: 20px;
                bottom: 0;
                width: 25px;
                font-size: 40px;
              }
            }
            .sub-title {
              font-size: 24px;
              padding-top: 36px;
              padding-bottom: 36px;
            }
            .des {
              font-size: 14px;
              line-height: 34px;
            }
          }
          .images {
            width: 476px;
          }
        }
      }
    }
    .nuza-customer {
      height: 950px;
      .banner-container {
        width: 1000px;
        .energy-title {
          .title-mian {
            font-size: 40px;
          }
        }
        .energy-content {
          height: 625px;
          .fade-box {
            height: 500px;
            overflow: hidden;
          }
          .energy-banner {
            width: 100%;
            height: 500px;
            padding: 112px 0;
            img {
              height: 30px;
              margin-bottom: 10px;
            }
            p {
              font-size: 24px;
            }
            .des {
              font-size: 14px;
              margin-top: 20px;
              line-height: 28px;
            }
          }
          .energy-list {
            height: 125px;
            .item {
              padding: 30px 0;
              padding-left: 51px;
              img {
                height: 30px;
              }
              p {
                margin-top: 5px;
                font-size: 20px;
              }
            }
            .itemActive {
              height: 188px;
              padding: 62px 0;
              padding-left: 51px;
              margin-top: -63px;
              border-radius: 6px;
              img {
                height: 30px;
              }
              p {
                margin-top: 5px;
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>