import { render, staticRenderFns } from "./cockpit.vue?vue&type=template&id=eb0be5fa&scoped=true&"
import script from "./cockpit.vue?vue&type=script&lang=js&"
export * from "./cockpit.vue?vue&type=script&lang=js&"
import style0 from "./cockpit.vue?vue&type=style&index=0&id=eb0be5fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb0be5fa",
  null
  
)

export default component.exports