<template>
  <div class="maleon-container">
    <div class="maleon-banner">
      <div class="maleon-wrap">
        <div>
          <div class="title">
            DataMaleon
          </div>
          <div
            class="desc"
          >
            帮助企业自主搭建专业的数据可视化应用，实现生动的数据呈现、实时监控、智慧决策、数据分析等功能，简单高效、炫酷生动，满足多元场景展示需求，用视觉语言展现数据美学。
          </div>
          <!-- <a
            href="#form"
            class="apply-btn"
            @click="showCode"
          >咨询我们</a> -->
        </div>
        <video
          muted
          loop
          autoplay
          style="width: 100%"
          x5-playsinline
          webkit-playsinline
          playsinline
          :poster="`${require('~/assets/images/maleon/poster-banner.jpg')}`"
        >
          <source
            src="@/assets/video/maleon-banner.mp4"
            type="video/mp4"
          >
        </video>
      </div>
    </div>
    <div class="maleon-structure">
      <div>
        <div class="title">
          风格主题，一键切换
        </div>
        <div class="desc">
          多款风格主题，智能配色一键美化生动展现数据美学
        </div>
      </div>
      <video
        muted
        loop
        autoplay
        x5-playsinline
        webkit-playsinline
        playsinline
        :poster="`${require('~/assets/images/maleon/poster-theme.jpg')}`"
      >
        <source
          src="../../assets/video/maleon-theme.mp4"
          type="video/mp4"
        >
      </video>
    </div>
    <div class="maleon-structure">
      <div>
        <div class="title">
          海量可视化组件
        </div>
        <div
          class="desc"
          style="width: 520px; margin-left: 380px"
        >
          针对业务监控、大型会议、参观展览、营销大促等不同场景提供丰富的可视化组件与场景模板
        </div>
      </div>
      <video
        muted
        loop
        autoplay
        x5-playsinline
        webkit-playsinline
        playsinline
        :poster="`${require('~/assets/images/maleon/poster-component.jpg')}`"
      >
        <source
          src="../../assets/video/maleon-component.mp4"
          type="video/mp4"
        >
      </video>
    </div>
    <div class="maleon-structure">
      <div style="position: relative; z-index: 2">
        <div class="title">
          拖拽式零编码开发大屏
        </div>
        <div
          class="desc"
          style="width: 322px; margin-left: 479px"
        >
          零代码，只需拖拉拽
          <br>自由发挥想象力，新手也能成“马良”
        </div>
      </div>
      <video
        muted
        loop
        autoplay
        x5-playsinline
        webkit-playsinline
        playsinline
        :poster="`${require('~/assets/images/maleon/poster-drop.jpg')}`"
        style="position: relative; top: -150px"
      >
        <source
          src="../../assets/video/maleon-drop.mp4"
          type="video/mp4"
        >
      </video>
    </div>
    <div
      class="maleon-structure"
      style="top: -150px"
    >
      <el-carousel
        type="card"
        trigger="click"
      >
        <el-carousel-item
          v-for="(item,index) in carousel"
          :key="index"
        >
          <div class="carousel-title">
            {{ item.title }}
          </div>
          <div
            class="carousel-desc"
            v-html="item.desc"
          />
          <img :src="item.img">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="maleon-structure">
      <div style="margin-top: 0">
        <div class="title">
          他们都在用
        </div>
        <img
          src="@/assets/images/maleon/customer.png"
          style="margin-top: 100px; z-index: 2; position: relative;"
        >
      </div>
    </div>
    <el-dialog
      width="400px"
      title="咨询方式"
      class="dialog"
      :visible.sync="dialogTableVisible"
    >
      <p class="tip"> 
        请扫描微信二维码
      </p>
      <img
        src="~@/assets/image/contactCode.png"
        alt=""
        class="qrcode"
      >
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "DataMaleon",
  data() {
    return {
         dialogTableVisible: false,
      title: "奇点云-DataMaleon",
      carousel: [
        {
          title: "多端支持",
          desc: `无论大屏、PC、PAD、手机端<br />都能无缝对接，完美匹配`,
          img: require("~/assets/images/maleon/slider-1.png")
        },
        {
          title: "敏捷迭代",
          desc: `“保存即更新，所见即所得”<br />敏捷迭代，及时响应多变需求`,
          img: require("~/assets/images/maleon/slider-2.png")
        },
        {
          title: "多数据源支持",
          desc: `支持常规RDS、NoSQL、Hive、Hbase等<br />支持秒级实时更新数据`,
          img: require("~/assets/images/maleon/slider-3.png")
        },
        {
          title: "灵活适配大屏",
          desc: `灵活支持各种分辨率<br />从1K到多个4k，以及非标准比例`,
          img: require("~/assets/images/maleon/slider-4.png")
        }
      ]
    };
  },
//   mounted() {
//     this.$store.commit("global/setMaleonBottomBg", true);
//   },
  beforeDestroy() {
    this.$store.commit("global/setMaleonBottomBg", false);
  },
  methods:{
    showCode(){
        this.dialogTableVisible = true
    },
  },
  head() {
    return {
      title: "数据可视化_实时监控_数据分析_DataMaleon_奇点云",
      meta: [
        {
          name: "keywords",
          content: "数据可视化，实时监控，数据分析，DataMaleon，奇点云"
        },
        {
          name: "description",
          content:
            "如何实现数据可视化？奇点云DataMaleon帮助企业自主搭建专业的数据可视化应用，实现生动的数据呈现、实时监控、智慧决策、数据分析等功能。"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.dialog{
    text-align: center;
}
.tip{
    margin-bottom: 20px;
}
.qrcode{
    width: 200px;
    height: 200px;
}
.maleon-container {
  min-width: 1280px;
//   margin-top: 68px;
  background: #000;
  .maleon-banner {
    .maleon-wrap {
      & > div {
        position: relative;
        top: 100px;
        left: 40px;
        width: 1280px;
        margin: 0 auto;
        z-index: 2;
        .title {
          width: 1058px;
          font-size: 46px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #fff;
          line-height: 68px;
        }
        .desc {
          margin-top: 30px;
          width: 860px;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: rgba(255, 255, 255, 0.8);
          line-height: 30px;
        }
        .apply-btn {
          margin-top: 40px;
          padding: 15px 43px;
          display: inline-block;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #fff;
          line-height: 16px;
          border: 2px solid rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
  .maleon-structure {
    position: relative;
    z-index: 2;
    & > div {
      width: 1280px;
      margin: 0 auto;
      margin-top: 100px;
      text-align: center;
      .title,
      .carousel-title {
        font-size: 46px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #fff;
        line-height: 68px;
      }
      .carousel-desc {
        margin-top: 30px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: rgba(255, 255, 255, 0.8);
        line-height: 28px;
        text-align: center;
      }
      .desc {
        margin-top: 30px;
        margin-left: 490px;
        width: 300px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: rgba(255, 255, 255, 0.8);
        line-height: 28px;
        text-align: center;
      }
      img {
        width: 100%;
      }
    }
    video {
      width: 100%;
    }
  }
  .el-carousel {
    margin-top: 0 !important;
    width: 100% !important;
    :global(.el-carousel__container) {
      min-height: 700px;
    }
    @media only screen and (min-width: 1280px) and (max-width: 1440px) {
      :global(.el-carousel__container) {
        min-height: 540px;
      }
    }
    @media only screen and (min-width: 1920px) {
      :global(.el-carousel__container) {
        min-height: 700px;
      }
    }
    :global(.el-carousel__item) {
      .el-carousel__mask {
        background-color: transparent !important;
      }
      img {
        width: 100%;
        // height: 100%;
        opacity: 0.2;
      }
      .carousel-title,
      .carousel-desc {
        opacity: 0;
      }
    }
    .el-carousel__item.is-active {
      img,
      .carousel-title,
      .carousel-desc {
        opacity: 1;
      }
    }
    :global(.el-carousel__indicators) {
      margin-top: 50px;
      .el-carousel__indicator {
        padding: 0;
        width: 100px;
        height: 4px;
        margin: 0 15px;
        background: rgba(255, 255, 255, 0.2);
        .el-carousel__button {
          width: 100%;
          height: 100%;
        }
      }
      .el-carousel__indicator.is-active {
        .el-carousel__button {
          background: #1ccada;
          animation: progress 3s forwards;
        }
      }
    }
    @keyframes progress {
      0% {
        width: 0%;
      }
      100% {
        width: 100%;
      }
    }
  }
}
</style>

