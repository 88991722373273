<template>
  <div class="hubble-container">
    <div class="hubble-banner">
      <div class="hubble-wrap">
        <p class="title">
          DataHubble
        </p>
        <p class="desc">
          如何快速应用数据，提升业务价值？
        </p>
        <!-- <el-button class="banner-btn">产品咨询</el-button> -->
        <!-- <a
          href="#form"
          class="banner-btn"
          @click="showCode"
        >产品咨询</a> -->
      </div>
    </div>
    <div class="hubble-introduction">
      <img
        src="~@/assets/images/hubble/introduction-logo.png"
        alt=""
      >
      <p>企业数据门户，旨在为企业提供统一的报表平台、指标中心。作为人人可用的业务数据搜索引擎，用户可零代码便捷取数，有效帮助洞察数据、使用数据，为业务提升价值。</p>
    </div>
    <div class="hubble-structure">
      <div class="section-header">
        <p class="title">
          产品架构
        </p>
        <p class="summary">
          DataHubble，让数据价值触手可及
        </p>
      </div>
      <div>
        <img
          src="~@/assets/images/hubble/structure.png"
          alt=""
        >
      </div>
    </div>
    <div class="hubble-ability">
      <div class="section-header">
        <p class="title">
          核心能力
        </p>
        <p class="desc">
          六大核心能力，助力高效挖掘数据价值
        </p>
      </div>
      <div class="section-body">
        <div class="section-body-item">
          <img
            class="number"
            src="~@/assets/images/hubble/01@2x.png"
            alt=""
          >
          <img
            class="name"
            src="~@/assets/images/hubble/统一易用的报表中心@2x.png"
            alt=""
          >
          <div class="desc">
            <img
              class="point"
              src="~@/assets/images/hubble/ability-point.png"
              alt=""
            >
            <p>集成FineReport、 FineBI、SAPBO、Tableau等三方BI报表</p>
          </div>  
          <div class="desc">
            <img
              class="point"
              src="~@/assets/images/hubble/ability-point.png"
              alt=""
            >
            <p>支持各报表应用单点登录</p>
          </div>   
        </div>
        <div class="section-body-item">
          <img
            class="number"
            src="~@/assets/images/hubble/02@2x.png"
            alt=""
          >
          <img
            class="name"
            src="~@/assets/images/hubble/全面立体的指标中心@2x.png"
            alt=""
          >
          <div class="desc">
            <img
              class="point"
              src="~@/assets/images/hubble/ability-point.png"
              alt=""
            >
            <p>具备完备的指标说明，包括版本、业务口径、技术口径、sql等示例展示</p>
          </div>  
          <div class="desc">
            <img
              class="point"
              src="~@/assets/images/hubble/ability-point.png"
              alt=""
            >
            <p>支持指标版本跟踪</p>
          </div>  
          <div class="desc">
            <img
              class="point"
              src="~@/assets/images/hubble/ability-point.png"
              alt=""
            >
            <p>支持指标数据血缘关系追踪</p>
          </div>   
        </div>  
        <div class="section-body-item">
          <img
            class="number"
            src="~@/assets/images/hubble/03@2x.png"
            alt=""
          >
          <img
            class="name"
            src="~@/assets/images/hubble/灵活极速的自助取数@2x.png"
            alt=""
          >
          <div class="desc">
            <img
              class="point"
              src="~@/assets/images/hubble/ability-point.png"
              alt=""
            >
            <p>拖拽即可完成自助数据查询</p>
          </div>  
          <div class="desc">
            <img
              class="point"
              src="~@/assets/images/hubble/ability-point.png"
              alt=""
            >
            <p>百万级数据量，依旧秒级出数据</p>
          </div>   
          <div class="desc">
            <img
              class="point"
              src="~@/assets/images/hubble/ability-point.png"
              alt=""
            >
            <p>低学习成本，五分钟即可上手</p>
          </div>
        </div>
        <div class="section-body-item">
          <img
            class="number"
            src="~@/assets/images/hubble/04@2x.png"
            alt=""
          >
          <img
            class="name"
            src="~@/assets/images/hubble/智能洞察的自助分析@2x.png"
            alt=""
          >
          <div class="desc">
            <img
              class="point"
              src="~@/assets/images/hubble/ability-point.png"
              alt=""
            >
            <p>支持语义搜索，并可1秒内得到数据结论</p>
          </div>  
          <div class="desc">
            <img
              class="point"
              src="~@/assets/images/hubble/ability-point.png"
              alt=""
            >
            <p>自动发现数据趋势与问题，并向用户推送数据洞察</p>
          </div>   
          <div class="desc">
            <img
              class="point"
              src="~@/assets/images/hubble/ability-point.png"
              alt=""
            >
            <p>支持快速创建、编辑并分享实用的数据报告</p>
          </div>  
        </div>  
        <div class="section-body-item">
          <img
            class="number"
            src="~@/assets/images/hubble/05@2x.png"
            alt=""
          >
          <img
            class="name"
            src="~@/assets/images/hubble/便捷统一的系统管理@2x.png"
            alt=""
          >
          <div class="desc">
            <img
              class="point"
              src="~@/assets/images/hubble/ability-point.png"
              alt=""
            >
            <p>门户菜单模块灵活配置</p>
          </div>  
          <div class="desc">
            <img
              class="point"
              src="~@/assets/images/hubble/ability-point.png"
              alt=""
            >
            <p>用户管理统一可控</p>
          </div>   
          <div class="desc">
            <img
              class="point"
              src="~@/assets/images/hubble/ability-point.png"
              alt=""
            >
            <p>支持与集成三方工具的单点登录</p>
          </div> 
        </div>           
        <div class="section-body-item">
          <img
            class="number"
            src="~@/assets/images/hubble/06@2x.png"
            alt=""
          >
          <img
            class="name"
            src="~@/assets/images/hubble/坚如磐石的数据安全@2x.png"
            alt=""
          >
          <div class="desc">
            <img
              class="point"
              src="~@/assets/images/hubble/ability-point.png"
              alt=""
            >
            <p>可配置子系统、报表、应用等权限</p>
          </div>  
          <div class="desc">
            <img
              class="point"
              src="~@/assets/images/hubble/ability-point.png"
              alt=""
            >
            <p>支持数据模型行列控制</p>
          </div>   
          <div class="desc">
            <img
              class="point"
              src="~@/assets/images/hubble/ability-point.png"
              alt=""
            >
            <p>支持用户行为日志、审计、高危阻断</p>
          </div> 
        </div>  
      </div>
    </div>
    <div class="hubble-custmer">
      <div class="section-header">
        <p class="title">
          客户成功
        </p>
      </div>
      <div class="section-body">
        <div :class="custmerImg">
          <div class="section-body-top">
            <p class="custmer-name">
              {{ custmerName }}
            </p>
            <div class="custmer-desc">
              {{ custmerDesc }}
            </div>
            <el-button
              v-show="false"
              class="more-btn"
            >
              了解更多
            </el-button>
          </div>
        </div>
        <div class="body-bottom">
          <div
            class="body-bottom-item-left"
            @mouseenter="focusCustmer(0)"
          >
            中国饮料知名品牌N
          </div>
          <div
            class="body-bottom-item-right"
            @mouseenter="focusCustmer(1)"
          >
            中国乳制品前10强企业T
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      width="400px"
      title="咨询方式"
      class="dialog"
      :visible.sync="dialogTableVisible"
    >
      <p class="tip"> 
        请扫描微信二维码
      </p>
      <img
        src="~@/assets/image/contactCode.png"
        alt=""
        class="qrcode"
      >
    </el-dialog>
  </div>
</template>

<script>
    export default {
        data(){
            return{
                 dialogTableVisible:false,
                custmerImg:'custmerN',
                custmerId:0,
                custmerName:'中国饮料知名品牌N',
                custmerDesc:'DataHubble助N企业集成多种BI系统，提供统一入口查看；依托DataSimba，DataHubble关联数据中台指标库与报表指标，企业可便捷地查看报表指标定义；同时，提供公告管理功能，实现系统级与报表级公告通知，及时向用户反馈报表情况。',
                custmerList:[
                    {
                        name:'中国饮料知名品牌N',
                        desc:'DataHubble助N企业集成多种BI系统，提供统一入口查看；依托DataSimba，DataHubble关联数据中台指标库与报表指标，企业可便捷地查看报表指标定义；同时，提供公告管理功能，实现系统级与报表级公告通知，及时向用户反馈报表情况。',
                        class: 'custmerN'
                    },
                    {
                        name:'中国乳制品前10强企业T',
                        desc:'DataHubble助T企业汇聚融合各板块业务，统一管理数据，激活数据核心价值，为业务赋能。DataHubble改变了传统手工低效的数据分析方式，从领导到一线人员，企业用户都能通过把数据快速用起来，对业务进行洞察、分析、决策。',
                        class:'custmerT'
                    }
                ]
            }
        },
        methods:{
            showCode(){
                this.dialogTableVisible = true
            },
            focusCustmer(id){
                this.custmerName = this.custmerList[id].name;
                this.custmerDesc = this.custmerList[id].desc;
                this.custmerImg = this.custmerList[id].class
            }
        }
    }
</script>

<style lang="scss" scoped>
.dialog{
    text-align: center;
}
.tip{
    margin-bottom: 20px;
}

.qrcode{
    width: 200px;
    height: 200px;
}
// @media screen and (max-height:1920px) {
    .hubble-container{
        width: 100%;
        // padding-top: 68px;
    }
    .hubble-banner{
        width: 100%;
        height: 740px;
        background: url('~@/assets/images/hubble/banner.png') center 0;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        .hubble-wrap{
            width: 1280px;
            margin: 0 auto;
            padding-top: 228px;
        }
        .title {
            width: 245px;
            font-size: 50px;
            font-weight: 600;
            color: #ffffff;
            letter-spacing: 2px;
            padding-bottom: 30px;
        }
        .desc {
            font-size: 20px;
            font-weight: 400;
            color: #ffffff;
            width: 442px;
            padding-bottom: 80px;
            line-height: 32px;
        }
        .banner-btn {
            display: block;
            width: 140px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #07b4d4;
            border: black;
            // border-radius: 2px;
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .hubble-introduction{
        width: 1280px;
        height: 580px;
        margin:0 auto;
        text-align: center;
        background: url('~@/assets/images/hubble/introduction-bg.png') center 0;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: relative;
        margin-top: 100px;
        img{
            width: 181px;
            height: 130px;
            margin: 0 auto;
            margin-top: 164px;
        }
        p{
            width: 739px;
            height: 108px;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #FFFFFF;
            line-height: 36px;
            letter-spacing: 1px;
            margin:0 auto;
            margin-top: 40px; 
        }
    }
    .section-header{
        text-align: center;
        width: 1280px;
        margin: 0 auto;
        padding: 87px 0 40px 0;
        .title{
            font-size: 46px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #151515;
            line-height: 65px;
            letter-spacing: 2px;
            margin-bottom: 10px;
        }
        .summary{
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #1868EE;
            line-height: 40px;
            letter-spacing: 1px;
        }
        .desc{
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 28px;
        }
    }
    .hubble-structure{
        width: 1280px;
        margin: 0 auto;
        margin-bottom: 87px;
        img{
            width: 100%;
            height: 586px;
        }
    }
    .hubble-ability{
        width: 1920px;
        height: 1300px;
        background: url('~@/assets/images/hubble/ability-bg.png');
        background-size: 100% 100%;
        color: #FFFFFF;
        .title{
            color: #FFFFFF;
        }
        .section-body{
            width: 1280px!important;
            display: flex;
            margin: 0 auto;
            justify-content: space-between;
            flex-wrap: wrap;
            .section-body-item{
                padding: 38px 33px;
                width:380px;
                height: 420px;
                background: url('~@/assets/images/hubble/ability-item.png');
                background-size: 100% 100%;
                margin-bottom: 70px;
                .number{
                    width: 28px;
                    height: 22px;
                    display: block;
                    margin-bottom: 18px;
                    margin-left: 5px;
                }
                    .name{
                        width:252px;height:37px;margin-bottom:62px
                    }
                .desc{
                    display: flex;
                    align-items: flex-start;
                    margin-top: 10px;
                    .point{
                        width: 8px;
                        height: 8px;
                    }
                    p{
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 32px;
                        margin-left: 15px;
                        margin-bottom: 12px;
                        margin-top: -10px;
                    }
                }
            }
        }
    }
    .hubble-custmer{
        .section-body{
            width: 1280px;
            height: 800px;
            background: gray;
            margin: 0 auto;
            position: relative;
            // padding: 199px 0 158px 0;
            text-align: center;
            .section-body-top{
                padding: 199px 0 158px 0;
                height: 640px;
            }
            .custmer-name{
                font-size: 38px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 55px;
                margin-bottom: 39px;
            }
            .custmer-desc{
                width: 884px;
                height: 107px;
                font-size: 22px;
                font-family: PingFangSC-Regular, PingFang SC;
                color: #FFFFFF;
                line-height: 36px;
                margin: 0 auto;
                margin-bottom: 60px;
            }
            .custmerT{
                    background: url('~@/assets/images/hubble/custmerT-bg.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
            }
            .custmerN{
                    background: url('~@/assets/images/hubble/custmerN-bg.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
            }
            .body-bottom{
                width: 100%;
                position: absolute;
                bottom: 0;
                height: 160px;
                background: #FFFFFF;
                display: flex;
                .body-bottom-item-left{
                    width: 50%;
                    padding: 0 75px;
                    font-size: 27px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #151515;
                    line-height: 38px;
                    text-align: left;
                    display: flex;
                    align-items: center;
                    z-index: 11;
                    // opacity: 1;
                    &:hover{
                        // opacity: 0;
                        width: 50%;
                        padding: 0 75px;
                        font-size: 27px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #151515;
                        line-height: 38px;
                        text-align: left;
                        display: flex;
                        align-items: center;
                        z-index: 11;
                        color: #FFFFFF;
                        height: 240px;
                        background: #1868EE;
                        bottom: 0;
                        position: absolute;
                    }
                }
                .body-bottom-item-right{
                    width: 50%;
                    padding: 0 75px;
                    font-size: 27px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    line-height: 38px;
                    text-align: left;
                    z-index: 11;
                    position: absolute;
                    right: 0;
                    opacity: 1;
                    height: 160px; //absolute ，不加这一行使flex失效
                    display: flex;
                    align-items: center;
                    color: #151515;
                    // opacity: 1;
                    &:hover{
                        // opacity: 0;
                        width: 50%;
                        padding: 0 75px;
                        font-size: 27px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #151515;
                        line-height: 38px;
                        text-align: left;
                        display: flex;
                        align-items: center;
                        z-index: 11;
                        color: #FFFFFF;
                        height: 240px;
                        background: #1868EE;
                        bottom: 0;
                        position: absolute;
                    }
                }
            }   
        }
        .more-btn{
            width: 160px;
            height: 40px;
            background: #07B4D4;
            color:#FFFFFF;
            font-size: 22px;
            font-family: PingFangSC-Regular, PingFang SC;
            line-height: 30px;
            display: inline-flex;
            justify-content: center;
            align-items: center;

        }
    }   
// }
@media screen and (max-width: 1440px) {
    .hubble-container{
  width: 100%;
//   padding-top: 68px;
}
.hubble-banner{
    width: 100%;
    height: 555px;
    background: url('~@/assets/images/hubble/banner.png') center 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
      .hubble-wrap {
        padding-top: 170px;
        width: 960px;
        margin: 0 auto;
        .desc {
          font-size: 16px;
        }
      }
      .banner-btn{
        width: 140px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #07b4d4;
        border-radius: 2px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
      }
}
.hubble-introduction{
    width: 960px;
    height: 435px;
    margin:0 auto;
    text-align: center;
    background: url('~@/assets/images/hubble/introduction-bg.png') center 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    margin-top: 75px;
    img{
        width: 136px;
        height: 98px;
        margin: 0 auto;
        margin-top: 123px;
    }
    p{
        width: 554px;
        height: 81px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 27px;
        letter-spacing: 1px;
        margin: 0 auto;
        margin-top: 30px;
    }
}
.section-header{
    text-align: center;
    width: 960px;
    margin: 0 auto;
    padding: 83px 0 39px 0;
    .title{
        font-size: 35px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #151515;
        line-height: 49px;
        letter-spacing: 2px;
    }
    .summary{
        font-size: 21px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #1868EE;
        line-height: 30px;
        letter-spacing: 1px;
    }
    .desc{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #FFFFFF;
        line-height: 21px;
        margin-top: 8px;
    }
}
.hubble-structure{
    width: 960px;
    margin: 0 auto;
    margin-bottom: 65px;
    img{
        width: 100%;
        height: 440px;
    }
}
.hubble-ability{
    width: 1440px;
    height: 975px;
    background: url('~@/assets/images/hubble/ability-bg.png');
    background-size: 100% 100%;
    color: #FFFFFF;
    .title{
        color: #FFFFFF;
    }
    .section-body{
        width: 960px!important;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        flex-wrap: wrap;
        .section-body-item{
            padding: 29px 25px;
            width:285px;
            height: 315px;
            background: url('~@/assets/images/hubble/ability-item.png');
            background-size: 100% 100%;
            margin-bottom: 53px;
            .number{
                width: 21px;
                height: 17px;
                display: block;
                margin-bottom: 14px;
                margin-left: 5px;
            }
            .name{
                width:190px;height:28px;margin-bottom:56px
            }
            .desc{
                 display: flex;
                 align-items: flex-start;
                 margin-top: 10px;
                .point{
                    width: 8px;
                    height: 8px;
                }
                p{
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 24px;
                    margin-left: 8px;
                    margin-bottom: 9px;
                    margin-top: -10px;
                }
            }
        }
    }
}
.hubble-custmer{
    .section-body{
        width: 960px;
        height: 600px;
        margin: 0 auto;
        position: relative;
        text-align: center;
        .section-body-top{
            padding: 149px 0 119px 0;
            height: 480px;
        }
        .custmer-name{
            font-size: 21px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 30px;
            margin-bottom: 39px;
        }
        .custmer-desc{
            width: 611px;
            height: 59px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
            margin: 0 auto;
            margin-bottom: 65px;
        }
        .custmerT{
                background: url('~@/assets/images/hubble/custmerT-bg.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
        }
        .custmerN{
                background: url('~@/assets/images/hubble/custmerN-bg.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
        }
        .body-bottom{
            width: 100%;
            position: absolute;
            bottom: 0;
            height: 120px;
            background: #FFFFFF;
            display: flex;
            .body-bottom-item-left{
                width: 50%;
                padding: 0 38px;
                font-size: 15px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #151515;
                line-height: 21px;
                text-align: left;
                display: flex;
                align-items: center;
                z-index: 11;
                // opacity: 1;
                &:hover{
                    // opacity: 0;
                    width: 50%;
                    padding: 0 38px;
                    font-size: 15px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 21px;
                    text-align: left;
                    display: flex;
                    align-items: center;
                    z-index: 11;
                    color: #FFFFFF;
                    height: 180px;
                    background: #1868EE;
                    bottom: 0;
                    position: absolute;
                }
            }
            .body-bottom-item-right{
                width: 50%;
                padding: 0 38px;
                font-size: 15px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 21px;
                text-align: left;
                z-index: 11;
                position: absolute;
                right: 0;
                opacity: 1;
                height: 120px; //absolute ，不加这一行使flex失效
                display: flex;
                align-items: center;
                color: #151515;
                &:hover{
                    font-size: 15px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 21px;
                    display: flex;
                    align-items: center;
                    z-index: 11;
                    color: #FFFFFF;
                    height: 180px;
                    background: #1868EE;
                    bottom: 0;
                    position: absolute;
                }
            }
        }   
    }
    .more-btn{
        width: 120px;
        height: 30px;
        background: #07B4D4;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 17px;
        display: inline-flex;
        justify-content: center;
        align-items: center;

    }
}
}
</style>