<template>
  <div class="system" :style="banner">
      <slot></slot>
      <div class="desc" v-html="desc"></div>
  </div>
</template>

<script>
export default {
  props: {
    desc:String,
    pic: String,
  },
 computed:{
    banner(){
      return this.pic ? {
            backgroundImage: 'url(' + this.pic + ')',
            backgroundSize: 'auto 100%',
          } : {}
    },
  },
}
</script>

<style lang="scss" scoped>
 .system {
    background: url("~@/assets/images/products/replenish/replenish-bg.png")
      center 0;
    background-size: auto 100%;
    height: 764px;
    padding-top: 120px;
    .desc {
      padding-top: 56px;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      color: #151515;
      line-height: 32px;
      letter-spacing: 1px;
    }
  }
   @media screen and (max-width: 1440px) {
    .system {
      height: 580px;
      padding-top: 80px;
      .desc {
        padding: 34px;
        font-size: 14px;
        line-height: 28px;
      }
    }
   }
</style>