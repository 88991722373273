export default {
    topBriefContent: {
      title: `商情信息，一手掌握`,
      subTitle: `Data+AI 助力提升零售企业实时获取第一手商情，掌握流行趋势，帮助商品策划、销售运营，提高商品 ROI`,
      titleStyle: {
        color: '#0475FF',
      },
      playVideo: false,
      videoPath: '',
      rightImg: require("~/assets/images/businessConditions/banner.jpg"),
    },
  
    graphicFirstContent:{
      headline: '产品特点',
      summarize: '',
      descMaxWidth: '228px',
      graphicList:[{
        img: require('~/assets/images/businessConditions/feature_1.png'),
        title: '多源数据聚合',
        desc: `聚合全网多源数据，利用大数据算法进行商品关键词拆解，依托规则引擎进行品类归类，实现多源数据聚合归一。`,
        listStyle: {
            position: 'relative',
            top: '13px'
        },
        imgStyle: {
            width: '185px',
            height: '189px',
        }
      },{
        img: require('~/assets/images/businessConditions/feature_2.png'),
        title: '多源数据洞察',
        desc: '可通过行业->品类商品排行榜->单款商品的分析方法，层层递进和下钻，通过完善的指标体系发现问题并解决问题。',
        listStyle: {
            marginTop: '41px'
        },
        imgStyle: {
            width: '156px',
            height: '160px',
        }
      },{
        img: require('~/assets/images/businessConditions/feature_3.png'),
        title: '灵活比对',
        desc: '品牌数据可自主选择对比视角，多方位发现差异。',
        listStyle: {
            position: 'relative',
            top: '35px',
        },
        imgStyle: {
            position: 'relative',
            top: '30px',
            width: '199px',
            height: '164px'
        }
      },{
        img: require('~/assets/images/businessConditions/feature_4.png'),
        title: '行业定制',
        desc: '针对企业品牌特点，可定制化商情范围，行业数据更具指导性。',
        listStyle: {
            position: 'relative',
            top: '22px',
        },
        imgStyle: {
            position: 'relative',
            top: '35px',
            width: '188px',
            height: '175px'
        }
      }]
    },

    horizontalGraphicContent: {
        headline: '功能介绍',
        list:[
          {
            align: 'left',
            img: require('~/assets/images/businessConditions/function_1.png'),
            title: '品 类 洞 察',
            text: `根据商品分析方法论，全面剖析品类结构。构建品类单品竞争评估体系，并一目了然可视化呈现。可通过体系化的品类指标，360°评估单品，辅助商品企划。`,
            efficiency: true,
          },
          {
            align: 'right',
            img: require('~/assets/images/businessConditions/function_2.png'),
            title: '行 业 比 对',
            text: `将品类单品与行业同类单品横向比较，随时了解单品竞争力。多角度、多指标同时比对，自由选择，深入挖掘症结所在，为商品订、铺、补环节提供数据建议。`,
            efficiency: true,
          },
          {
            align: 'left',
            img: require('~/assets/images/businessConditions/function_3.png'),
            title: '实 时 榜 单',
            text: `行业榜单实时更新，掌握商情第一手信息。完整描述榜单商品信息和对比信息，快速发现榜单商品差异。`,
            efficiency: true,
          }],
    },
  }