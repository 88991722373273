<template>
  <div class="introduce">
    <div class="common-wrap" :style="banner">
      <slot></slot>
      <div class="desc" v-html="desc"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    desc:String,
    pic: String,
  },
 computed:{
    banner(){
      return this.pic ? {
            backgroundImage: 'url(' + this.pic + ')',
            backgroundSize: 'auto 100%',
          } : {}
    },
  },
};
</script>

<style lang="scss" scoped>
.introduce {
  padding: 100px 0;
  .common-wrap {
    background: url("~@/assets/images/simba/simba-ban.png") center 0;
    background-size: auto 100%;
    height: 580px;
    text-align: center;
    .logo {
      padding: 204px 0 40px 0;
      img {
        height: 60px;
      }
    }
    .q-title {
        padding: 180px 0 48px 0;
        color: #ffffff;
      }
    .desc {
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      letter-spacing: 1px;
    }
  }
}
@media screen and (max-width: 1440px) {
  .introduce {
    padding: 50px 0;
    .common-wrap {
      height: 454px;
      .logo {
        padding: 164px 0 26px 0;
        img {
          height: 40px;
        }
      }
      .q-title {
          padding: 134px 0 32px 0;
        }
      .desc {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }
}
</style>