<template>
  <div class="page-container efficiency-container">
    <swipe
      title="智能人效"
      desc="数据赋能企业管理，经营动态随时掌握"
      btn="产品咨询"
    />
    <div class="efficiency-introduce">
      <div class="common-wrap">
        <Title>智能人效管理系统</Title>
        <div class="desc">
          智能人效管理系统，基于大数据和AI算法技术，实现智能定编、智能排班、人效智能诊断、员工能力
          <br>画像、同区域人力统筹、离职优秀员工返聘和敏捷兼职等应用能力，为企业提供一站式人效运营服
          <br>务，切实提升零售企业门店人效，降低用人成本。
        </div>
        <div class="introduce-footer">
          <div class="item">
            <img
              class="icon"
              src="~@/assets/images/efficiency/icon01.png"
            >
            <div class="name">
              自动诊断
              <br>人效问题
            </div>
          </div>
          <div class="item">
            <img
              class="icon"
              src="~@/assets/images/efficiency/icon02.png"
            >
            <div class="name">
              智能定编
              <br>排班
            </div>
          </div>
          <div class="item">
            <img
              class="icon"
              src="~@/assets/images/efficiency/icon03.png"
            >
            <div class="name">
              精细运营
              <br>门店人力资源
            </div>
          </div>
          <div class="item">
            <img
              class="icon"
              src="~@/assets/images/efficiency/icon04.png"
            >
            <div class="name">
              自动计算
              <br>降本增效结果
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="efficiency-function">
      <div class="common-wrap">
        <Title>产品功能</Title>
        <el-tabs
          v-model="activeName"
          class="function-tabs"
          type="card"
          @tab-click="handleClick"
        >
          <el-tab-pane
            label="人效问题诊断"
            name="1"
          >
            <div class="function-item">
              <div class="info">
                <div class="title">
                  人效问题诊断
                </div>
                <div class="desc">
                  自动诊断每家门店的业绩问题，给出合理建议。
                </div>
              </div>
              <img src="~@/assets/images/efficiency/function01.png">
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="人员能力评估"
            name="2"
          >
            <div class="function-item">
              <div class="info">
                <div class="title">
                  人员能力评估
                </div>
                <div class="desc">
                  从往期的业绩表现等数据综合评估人员能力。
                </div>
              </div>
              <img src="~@/assets/images/efficiency/function02.png">
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="门店自动排班"
            name="3"
          >
            <div class="function-item">
              <div class="info">
                <div class="title">
                  门店自动排班
                </div>
                <div class="desc">
                  排班运筹模型，根据门店编制与销量情况智能排班。
                </div>
              </div>
              <img src="~@/assets/images/efficiency/function03.png">
            </div>
          </el-tab-pane>
          <el-tab-pane
            label="人效编制建议"
            name="4"
          >
            <div class="function-item">
              <div class="info">
                <div class="title">
                  人效编制建议
                </div>
                <div class="desc">
                  自动化分析区域人效，动态优化编制建议。
                </div>
              </div>
              <img src="~@/assets/images/efficiency/function04.png">
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="common-wrap">
      <div class="efficiency-trait">
        <Title>产品特点</Title>
        <el-row>
          <el-col :span="8">
            <div class="trait-item">
              <img
                class="icon"
                src="~@/assets/images/efficiency/trait01.png"
              >
              <div class="label">
                可闭环可量化
              </div>
              <div class="value">
                实施“调研-分析-动产-建议指导-效果
                <br>跟踪闭环”，自动分析使用前后业绩、
                <br>人效、编制、班次联动关系，可精确衡
                <br>量编制优化带来的降本增效价值。
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="trait-item">
              <img
                class="icon"
                src="~@/assets/images/efficiency/trait02.png"
              >
              <div class="label">
                人效深度分析
              </div>
              <div class="value">
                具备完整的泛零售门店人效深度分析方
                <br>法论，结合大数据分析技术与行业分析
                <br>经验，层层下钻定位问题，解决思路行
                <br>之有效。
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="trait-item">
              <img
                class="icon"
                src="~@/assets/images/efficiency/trait03.png"
              >
              <div class="label">
                一键上线
              </div>
              <div class="value">
                一键完成所有前期技术工作。免工程实
                <br>施和维护成本，当天对接客户，当天即
                <br>可投入试用。
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row class="trait-row">
          <el-col :span="12">
            <div class="trait-item">
              <img
                class="icon"
                src="~@/assets/images/efficiency/trait04.png"
              >
              <div class="label">
                简单易用
              </div>
              <div class="value">
                无需改变业务流程，“零成本”试错。
                <br>技术门槛低，简单易执行。
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="trait-item">
              <img
                class="icon"
                src="~@/assets/images/efficiency/trait05.png"
              >
              <div class="label">
                自我优化
              </div>
              <div class="value">
                算法自动迭代优化，根据业务实际情况
                <br>持续进化。
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="efficiency-logic">
        <Title>业务逻辑</Title>
        <div class="logic-list">
          <div class="logic-item">
            <div class="logic-content">
              <div class="title">
                数据预处理
                <img
                  class="line"
                  src="~@/assets/images/efficiency/green-line.png"
                >
              </div>
              <div class="logic-flex">
                <p>分析数据特征</p>
                <p>数据脱敏处理</p>
                <p>数据ETL</p>
              </div>
            </div>
            <div class="desc">
              根据业务调研结果，剔除无效数据。对客户数据进行脱敏处理，确保信息安全。将脱敏数据清洗后导入数据分析引擎。
            </div>
            <img
              class="arrow"
              src="~@/assets/images/efficiency/arrow-green.png"
            >
          </div>
          <div class="logic-item logic-item-blue">
            <div class="logic-content">
              <div class="title">
                维度模型
                <img
                  class="line"
                  src="~@/assets/images/efficiency/blue-line.png"
                >
              </div>
              <div class="logic-flex">
                <p>ODS</p>
                <p>DWD / DIM</p>
                <p>DWS</p>
                <p>ADS</p>
              </div>
            </div>
            <div class="desc">
              汇总来自不同数据库的业务数据。统一命名规则、类型、精度后，在维度模型中进行数据汇聚和指标计算。
            </div>
            <img
              class="arrow"
              src="~@/assets/images/efficiency/arrow-blue.png"
            >
          </div>
          <div class="logic-item logic-item-purple">
            <div class="logic-content">
              <div class="title">
                标签中心
                <img
                  class="line"
                  src="~@/assets/images/efficiency/purple-line.png"
                >
              </div>
              <div class="logic-flex">
                <p>人力资源和分组</p>
                <p>门店标签和分组</p>
                <p>其他标签</p>
              </div>
            </div>
            <div class="desc">
              计算人员、门店、特殊节假日等细部特征和相似性，打上多种数据标签，进行分组，以便进行试运行测试和观察人效提升效果。
            </div>
            <img
              class="arrow"
              src="~@/assets/images/efficiency/arrow-blue.png"
            >
          </div>
          <div class="logic-item logic-item-blue">
            <div class="logic-content">
              <div class="title">
                BI 展现
                <img
                  class="line"
                  src="~@/assets/images/efficiency/blue-line.png"
                >
              </div>
              <div class="logic-flex">
                <p>人效分析</p>
                <p>降本增效跟踪</p>
                <p>编制和人力分析</p>
                <p>资源浪费或不足预警</p>
              </div>
            </div>
            <div class="desc">
              提供傻瓜式和自助式风格展现。更好的简化HR，销售总经理、经理、店长的工作量。
            </div>
            <img
              class="arrow"
              src="~@/assets/images/efficiency/arrow-green.png"
            >
          </div>
          <div class="logic-item">
            <div class="logic-content">
              <div class="title">
                人效运营
                <img
                  class="line"
                  src="~@/assets/images/efficiency/green-line.png"
                >
              </div>
              <div class="logic-flex">
                <p>运营自动建议</p>
                <p>兼职自动调度</p>
                <p>人力资源地</p>
              </div>
            </div>
            <div
              class="desc"
            >
              自动指明分公司和每家门店存在的主要问题。对于业务峰值高，客流量变化大的业务场景，提供兼职预约和自动调度。离职人员关注公司公众号，就可查看附近门店实时兼职需求。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="business-successful">
      <div class="common-wrap">
        <Title>客户成功</Title>
        <energy :energy-data="energyData" />
      </div>
    </div>
  </div>
</template>

<script>
import Title from "./components/Title/index";
import Swipe from "./components/Swipe/index";
import energy from "./components/energy/index";

import efficiency from "~/assets/dictionary/efficiency";

import LogoHover from "~/assets/images/customerLogo/kuaiyufushi-hover.png";

export default {
  components: {
    Title,
    Swipe,
    energy
  },
  transition: "bounce",
  data() {
    return {
      ...efficiency,
      title: "奇点云-智能人效",
      energyData: [
        {
          name: "快鱼服饰",
          logoHover: LogoHover,
          banner: require("~/assets/images/customerLogo/kuaiyufushiBanner.png"),
          des:
            "依托智能人效，助力快鱼服饰实现排班优化、人员优化，释放人力1720余人，全年节<br/>约人员开支超8000万元人民币。在销售业绩不受影响的同时，优化后整体人效得到<br/>提升。该项目在企业内部的推广速度远超出项目组预期，受到业务部门和公司管理层<br/>的热烈欢迎。"
        }
      ],
      activeName: "1",
      time: null
    };
  },
  mounted() {
    this.shuf();
  },
  beforeDestroy() {
    clearInterval(this.time);
  },
  methods: {
    shuf() {
      this.time = setInterval(() => {
        const index = Number(this.activeName) + 1;
        if (index > 4) {
          this.activeName = "1";
        } else {
          this.activeName = String(index);
        }
      }, 3000);
    },
    handleClick() {
      clearInterval(this.time);
      this.shuf();
    }
  },
  head() {
    return {
      title: "大数据_数据智能应用_零售数据中台_智能人效_奇点云",
      meta: [
        {
          name: "keywords",
          content: "大数据，数据智能应用，零售数据中台，智能人效，奇点云"
        },
        {
          name: "description",
          content:
            "什么是智能人效？奇点云智能人效管理系统，基于大数据和AI算法，依托数据智能应用平台和零售数据中台，为企业提供一站式人效运营服务。"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.efficiency-container {
  .swipe-box {
    background: url("~@/assets/images/efficiency/swipe.jpg") center 0;
    background-size: auto 100%;
  }
  .efficiency-introduce {
    // padding: 100px 0;
    .common-wrap {
      background: url("~@/assets/images/efficiency/bg.png") center 0;
      background-size: auto 100%;
      height: 580px;
      text-align: center;
      position: relative;
      .q-title {
        padding: 132px 0 48px 0;
        color: #ffffff;
      }
      .desc {
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        letter-spacing: 1px;
      }
      .introduce-footer {
        position: absolute;
        display: flex;
        left: 0;
        right: 0;
        bottom: 0;
        height: 164px;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        .item {
          height: 100%;
          flex: 1;
          display: flex;
          align-items: center;
          border-right: 1px solid rgba(255, 255, 255, 0.2);
          text-align: left;
          padding-left: 90px;
          .icon {
            height: 46px;
          }
          .name {
            padding-left: 15px;
            font-size: 18px;
            font-weight: 500;
            color: #ffffff;
            line-height: 28px;
          }
          &:nth-last-child(2) {
            padding-left: 70px;
          }
          &:nth-last-child(1) {
            padding-left: 70px;
            border-right: none;
          }
        }
      }
    }
  }
  .efficiency-function {
    background: #f7f9fc;
    padding: 100px 0 110px 0;
    .q-title {
      padding-bottom: 60px;
    }
    .function-tabs {
      height: 486px;
      background: #ffffff;
      box-shadow: 0px 12px 24px 0px rgba(213, 213, 213, 0.3);
      :global(.el-tabs__header ){
        margin: 0;
        font-weight: 500;
        background: #eff2f6;
        border: none;
        .el-tabs__nav {
          width: 100%;
          border: none;
          display: flex;
          .el-tabs__item {
            flex: 1;
            height: 94px;
            line-height: 88px;
            text-align: center;
            color: #151515;
            font-size: 26px;
            border-left: none;
            border-top: 6px solid transparent;
            border-bottom: none;
            border-right: 1px solid #e6e8eb;
            &:nth-last-child(1) {
              border-right: none;
            }
          }
          .is-active {
            background: #ffffff;
            border-top: 6px solid #1868ee;
            color: #2570ef;
          }
          .el-tabs__active-bar {
            height: 6px;
            top: 0;
            background-color: #1868ee;
          }
        }
      }
    }
    .function-item {
      display: flex;
      padding: 10px 0;
      .info {
        width: 38%;
        padding: 110px 0 0 90px;
        .title {
          font-size: 34px;
          font-weight: 600;
          color: #151515;
          letter-spacing: 1px;
          padding-bottom: 20px;
        }
        .desc {
          font-size: 16px;
          font-weight: 400;
          color: #151515;
        }
      }
      img {
        height: 372px;
      }
    }
  }
  .efficiency-trait {
    padding: 120px 0;
    .q-title {
      padding-bottom: 80px;
    }
    .trait-row {
      padding: 80px 180px 0 180px;
    }
    .trait-item {
      text-align: center;
      .icon {
        height: 84px;
      }
      .label {
        padding: 20px 0;
        font-size: 26px;
        font-weight: 600;
        color: #151515;
        letter-spacing: 1px;
      }
      .value {
        font-size: 16px;
        font-weight: 400;
        color: #151515;
        line-height: 34px;
      }
    }
  }
  .efficiency-logic {
    padding-bottom: 120px;
    .logic-list {
      display: flex;
      padding-top: 70px;
      margin: 0 -20px;
      .logic-item {
        flex: 1;
        position: relative;
        margin: 0 20px;
        padding: 10px 10px 0 10px;
        height: 584px;
        background: linear-gradient(
          180deg,
          rgba(0, 155, 195, 0.06) 0%,
          rgba(0, 155, 195, 0.06) 74%,
          rgba(0, 155, 195, 0) 100%
        );
        .logic-content {
          background: rgba(0, 155, 195, 0.1);
          border-radius: 2px;
          padding: 20px 0;
          .title {
            text-align: center;
            font-size: 24px;
            font-weight: 600;
            color: #3cbdcb;
            background: linear-gradient(180deg, #45d8e8 0%, #005485 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            position: relative;
            .line {
              position: absolute;
              height: 2px;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
          .logic-flex {
            display: flex;
            height: 286px;
            flex-direction: column;
            justify-content: space-between;
            padding: 10px 16px 0 16px;
            p {
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 10px 0;
              background: rgba(0, 155, 195, 0.1);
              border-radius: 2px;
              border: 1px dotted #008caf;
              font-size: 18px;
              font-weight: 500;
              color: #008caf;
            }
          }
        }
        .desc {
          padding-top: 26px;
          font-size: 16px;
          font-weight: 400;
          color: #008caf;
          line-height: 26px;
        }
        .arrow {
          position: absolute;
          right: -33px;
          height: 20px;
          top: 232px;
        }
      }
      .logic-item-blue {
        background: linear-gradient(
          180deg,
          rgba(17, 109, 219, 0.06) 0%,
          rgba(17, 109, 219, 0.06) 74%,
          rgba(17, 109, 219, 0) 100%
        );
        .logic-content {
          background: rgba(0, 90, 197, 0.1);
          .title {
            background: linear-gradient(180deg, #3e9cff 0%, #0073c5 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .logic-flex {
            p {
              border: 1px dotted #005ac5;
              background: rgba(0, 90, 197, 0.06);
              color: #005ac5;
            }
          }
        }
        .desc {
          color: #005ac5;
        }
      }
      .logic-item-purple {
        background: linear-gradient(
          180deg,
          rgba(76, 71, 252, 0.06) 0%,
          rgba(76, 71, 252, 0.06) 74%,
          rgba(76, 71, 252, 0) 100%
        );
        .logic-content {
          background: #e1e3fa;
          .title {
            background: linear-gradient(180deg, #9077f4 0%, #2a0085 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .logic-flex {
            p {
              border: 1px dotted #0f09dc;

              background: rgba(15, 9, 220, 0.06);
              color: #0f09dc;
            }
          }
        }
        .desc {
          color: #0f09dc;
        }
      }
    }
  }
  .business-successful {
    background: #f7f9fc;
    padding: 75px 0 130px 0;
    margin-bottom: -100px;
    .q-title {
      padding-bottom: 50px;
    }
    .energy-content {
      height: 800px;
    }
  }
  @media screen and (max-width: 1440px) {
    .efficiency-introduce {
      padding: 50px 0;
      .common-wrap {
        height: 454px;
        .q-title {
          padding: 100px 0 32px 0;
        }
        .desc {
          font-size: 16px;
          line-height: 28px;
        }
        .introduce-footer {
          height: 124px;
          .item {
            padding-left: 60px;
            .icon {
              height: 32px;
            }
            .name {
              padding-left: 12px;
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }
    }
    .efficiency-function {
      padding: 50px 0 60px 0;
      .q-title {
        padding-bottom: 40px;
      }
      .function-tabs {
        height: 386px;
        :global(.el-tabs__header) {
          .el-tabs__nav {
            .el-tabs__item {
              height: 64px;
              line-height: 58px;
              font-size: 20px;
              border-top: 4px solid transparent;
            }
            .is-active {
              border-top: 4px solid #1868ee;
            }
            .el-tabs__active-bar {
              height: 4px;
            }
          }
        }
      }
      .function-item {
        .info {
          padding: 84px 0 0 45px;
          .title {
            font-size: 28px;
            padding-bottom: 14px;
          }
          .desc {
            font-size: 14px;
          }
        }
        img {
          height: 300px;
        }
      }
    }
    .efficiency-trait {
      padding: 70px 0;
      .q-title {
        padding-bottom: 60px;
      }
      .trait-row {
        padding: 60px 150px 0 150px;
      }
      .trait-item {
        .icon {
          height: 70px;
        }
        .label {
          padding: 16px 0;
          font-size: 22px;
        }
        .value {
          font-size: 14px;
        }
      }
    }
    .efficiency-logic {
      padding-bottom: 80px;
      .logic-list {
        padding-top: 50px;
        .logic-item {
          margin: 0 14px;
          padding: 7px 7px 0 7px;
          height: 454px;
          .logic-content {
            padding: 12px 0;
            .title {
              font-size: 17px;
              .line {
                height: 1.5px;
              }
            }
            .logic-flex {
              height: 200px;
              padding: 7px 12px 0 12px;
              p {
                margin: 6px 0;
                font-size: 15px;
              }
            }
          }
          .desc {
            padding-top: 18px;
            font-size: 14px;
            line-height: 22px;
          }
          .arrow {
            right: -25px;
            height: 16px;
            top: 162px;
          }
        }
      }
    }
    .business-successful {
      padding: 58px 0 84px 0;
      .q-title {
        padding-bottom: 32px;
      }
      .energy-content {
        height: 480px;
      }
    }
  }
}
</style>

