<template>
  <div class="page-container recommendation-container">
    <swipe
      title="智能推荐"
      desc="千人千面，精准触达"
      btn="产品咨询"
    />
    <Introduce
      :pic="require('~/assets/images/recommendation/bg.png')"
      desc="依托数据中台整合用户、交易、商品、商家等数据，基于数据算法模型，建立用户画像，对商<br/>品推荐进行预测，实现千人千面的精准推荐，有效提升转化效果。"
    >
      <Title>智能推荐系统</Title>
    </Introduce>
    <div class="recommendation-Ability">
      <div class="common-wrap">
        <Title>核心能力</Title>
        <div class="ability-flex">
          <div class="ability-item">
            <img
              class="icon"
              src="~@/assets/images/recommendation/power-icon01.png"
            >
            <div class="content">
              基于全域数据，构建数据的规范化、标准
              <br>化，建立统一的标签体系。
            </div>
          </div>
          <div class="ability-item">
            <img
              class="icon"
              src="~@/assets/images/recommendation/power-icon02.png"
            >
            <div class="content">
              建立用户分析机制和精准营销的数据业务应
              <br>用，实现用户标签管理与精准画像。
            </div>
          </div>
          <div class="ability-item">
            <img
              class="icon"
              src="~@/assets/images/recommendation/power-icon03.png"
            >
            <div class="content">
              基于行业算法模型与用户画像，预测用户感
              <br>兴趣的商品，在四大场景自动化作出商品的
              <br>精准推荐，有效提升连带率与转化率。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="recommendation-application">
      <div class="common-wrap">
        <Title>应用场景</Title>
        <div class="application-flex">
          <div class="application-item">
            <img
              class="icon"
              src="~@/assets/images/recommendation/icon01.png"
            >
            <div class="content">
              APP 首页为您推荐
            </div>
          </div>
          <div class="application-item">
            <img
              class="icon"
              src="~@/assets/images/recommendation/icon02.png"
            >
            <div class="content">
              商品详情页同商家商品推荐
            </div>
          </div>
          <div class="application-item">
            <img
              class="icon"
              src="~@/assets/images/recommendation/icon03.png"
            >
            <div class="content">
              购物车猜你喜欢
            </div>
          </div>
          <div class="application-item">
            <img
              class="icon"
              src="~@/assets/images/recommendation/icon04.png"
            >
            <div class="content">
              订单支付成功商品推荐
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="recommendation-architecture">
      <div class="common-wrap">
        <Title>产品架构</Title>
        <div class="architecture-pic">
          <img src="~@/assets/images/recommendation/process.png">
          <div class="hide-content">
            <div>业务中台</div>

            <div>应用层</div>
            <div>首页推荐</div>
            <div>商情页推荐</div>
            <div>购物车推荐</div>
            <div>订单支付推荐</div>
            <div>推荐分析</div>

            <div>数据中台</div>

            <div>数据模型</div>
            <div>商品数据模型</div>
            <div>商户数据模型</div>
            <div>会员数据模型</div>
            <div>活动数据模型</div>
            <div>流量数据模型</div>
            <div>交易数据模型</div>

            <div>算法模型</div>
            <div>会员评分模型</div>
            <div>活动预测模型</div>
            <div>商家评价模型</div>
            <div>活动成效模型</div>
            <div>会员行为模型</div>
            <div>商品推荐模型</div>

            <div>离线计算</div>
            <div>实时计算</div>
            <div>算法平台</div>

            <div>数据源</div>

            <div>用户数据流量</div>
            <div>诸葛 IO</div>
            <div>业务中台数据</div>
            <div>交易/会员/商品/商家</div>
            <div>第三方数据</div>
            <div>电商爬虫</div>
            <div>集团数据</div>
            <div>CRM/ERP/OA</div>
          </div>
        </div>
      </div>
    </div>
    <div class="business-successful">
      <div class="common-wrap">
        <Title>客户成功</Title>
        <energy :energy-data="energyData" />
      </div>
    </div>
  </div>
</template>

<script>
import recommendation from "~/assets/dictionary/recommendation";

import Title from "./components/Title/index";
import Swipe from "./components/Swipe/index";
import Introduce from "./components/Swipe/introduce";

import energy from "./components/energy/index";

import mamaLogoHover from "~/assets/images/customerLogo/mamagou-hover.png";

export default {
  components: {
    Title,
    Swipe,
    Introduce,
    energy
  },
  transition: "bounce",
  data() {
    return {
      ...recommendation,
      title: "奇点云-智能推荐",
      energyData: [
        {
          // name: "妈妈购",
          logoHover: mamaLogoHover,
          banner: require("~/assets/images/customerLogo/mamagouBanner.png"),
          des:
            "依托智能推荐引擎，基于spark计算引擎调用算法模型，基于用户行为数据，完善用户画<br/>像和在线商品的匹配，推荐相关商品；在品牌APP端实现首页、商详页、购物车、订单支<br/>付成功页等4个场景的智能推荐，对用户进行实时个性化的推荐，推荐商品千人千面。<br/>应用智能推荐引擎后，连带率同比提升21%，推荐商品转化率同比提升48%。"
        }
      ]
    };
  },
  head() {
    return {
      title: "数据中台_数据中台架构_用户画像_智能推荐_奇点云",
      meta: [
        {
          name: "keywords",
          content: "数据中台，数据中台架构，用户画像，智能推荐，奇点云"
        },
        {
          name: "description",
          content:
            "如何建立用户画像，实现智能推荐？奇点云是国内领先的AI驱动的数据中台创导者，运用其数据中台架构，实现千人千面的精准推荐。"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.recommendation-container {
  .swipe-box {
    background: url("~@/assets/images/recommendation/swipe.png") center 0;
    background-size: auto 100%;
  }
  .recommendation-Ability {
    background: url("~@/assets/images/recommendation/power-bg.png") center 0;
    background-size: auto 100%;
    height: 800px;
    padding-top: 144px;
    .q-title {
      color: #ffffff;
      padding-bottom: 70px;
    }
    .ability-flex {
      display: flex;
      margin: 0 -20px;
      .ability-item {
        text-align: center;
        box-sizing: border-box;
        height: 320px;
        flex: 1;
        margin: 0 20px;
        padding-top: 30px;
        background: linear-gradient(
          180deg,
          rgba(17, 169, 196, 0.04) 0%,
          rgba(12, 118, 157, 0.3) 100%
        );
        border-radius: 8px;
        // border: 3px solid rgba(255,255,255,0);
        transition: all 0.5s;
        .icon {
          height: 150px;
        }
        .content {
          padding-top: 28px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 32px;
        }
        &:hover {
          background: rgba(17, 169, 196, 0.3);
          // border: 3px solid rgba(255,255,255,0.1);
          transition: all 0.5s;
        }
      }
    }
  }
  .recommendation-application {
    background: #f7f9fc;
    padding: 100px 0 138px 0;
    .application-flex {
      display: flex;
      padding-top: 70px;
      margin: 0 -10px;
      .application-item {
        margin: 0 10px;
        height: 305px;
        background: #ffffff;
        border-radius: 6px;
        text-align: center;
        transition: all 1s;
        padding-top: 48px;
        flex: 1;
        .icon {
          height: 140px;
        }
        .content {
          font-size: 18px;
          font-weight: 400;
          color: #151515;
          padding-top: 30px;
        }
        &:hover {
          transition: all 1s;
          box-shadow: rgba(0, 0, 0, 0.08) 0 22px 36px 14px;
        }
      }
    }
  }
  .recommendation-architecture {
    padding: 100px 0;
    .architecture-pic {
      padding-top: 40px;
      img {
        width: 100%;
      }
    }
  }
  .business-successful {
    background: #f7f9fc;
    padding: 75px 0 130px 0;
    margin-bottom: -100px;
    .q-title {
      padding-bottom: 50px;
    }
    .energy-content {
      height: 800px;
    }
  }
  @media screen and (max-width: 1440px) {
    .recommendation-Ability {
      padding-top: 90px;
      height: 580px;
      .q-title {
        padding-bottom: 40px;
      }
      .ability-flex {
        margin: 0 -14px;
        .ability-item {
          margin: 0 14px;
          height: 280px;
          .icon {
            height: 120px;
          }
          .content {
            padding-top: 16px;
            font-size: 13px;
            line-height: 28px;
          }
        }
      }
    }
    .recommendation-application {
      padding: 70px 0 100px 0;
      .application-flex {
        padding-top: 50px;
        .application-item {
          padding-top: 32px;
          height: 240px;
          .icon {
            height: 120px;
          }
          .content {
            padding-top: 20px;
            font-size: 14px;
          }
        }
      }
    }
    .recommendation-architecture {
      padding: 70px 0;
      .architecture-pic {
        padding-top: 26px;
      }
    }
    .business-successful {
      padding: 58px 0 84px 0;
      .q-title {
        padding-bottom: 32px;
      }
      .energy-content {
        height: 480px;
      }
    }
  }
}
</style>

