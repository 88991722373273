<template>
   <div class="advantage">
      <div class="common-wrap">
        <slot></slot>
        <ul>
          <li v-for="(item,index) in list" :key="index">
            <div class="icon">
              <img :src="item.icon" />
            </div>
            <div class="info">
              <div class="title">{{item.title}}</div>
              <div class="desc" v-html="item.desc">
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    pic: String,
    list: Array,
  },
  computed:{
    banner(){
      return this.pic ? {
            backgroundImage: 'url(' + this.pic + ')',
            backgroundSize: 'auto 100%',
          } : {}
    },
  },
}
</script>

<style lang="scss" scoped>
 .advantage {
    background: url("~@/assets/images/products/replenish/power-bg.jpg") center 0;
    background-size: auto 100%;
    height: 890px;
    .q-title {
      color: #ffffff;
      padding: 110px 0 60px 0;
    }
    ul {
      li {
        display: flex;
        align-items: center;
        height: 160px;
        background: linear-gradient(
          90deg,
          rgba(187, 73, 250, 0.04) 0%,
          rgba(29, 85, 207, 0.5) 100%
        );
        border-radius: 6px;
        border-left: none;
        margin-bottom: 30px;
        .icon {
          padding: 0 66px 0 60px;
          img {
            width: 120px;
          }
        }
        .info {
          flex: 1;
          .title {
            font-size: 26px;
            font-weight: 600;
            color: #ffffff;
            letter-spacing: 1px;
            padding-bottom: 13px;
          }
          .desc {
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            line-height: 26px;
            opacity: 0.8;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1440px) {
     .advantage {
      height: 660px;
      .q-title {
        padding: 80px 0 40px 0;
      }
      ul li {
        height: 120px;
        margin-bottom: 20px;
        .icon {
          padding: 0 46px 0 40px;
          img {
            width: 80px;
          }
        }
        .info {
          .title {
            font-size: 20px;
            padding-bottom: 6px;
          }
          .desc {
            font-size: 12px;
          }
        }
      }
    }
  }
</style>