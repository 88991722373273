<template>
  <div class="detail">
    <i
      class="el-icon-back back text"
      @click="$router.push('/')"
    >返回</i>
    <header>
      <el-row class="info">
        <el-col :span="6">
          <img
            :src="productInfo.url"
            alt=""
          >
        </el-col>
        <el-col :span="18">
          <p class="title">
            产品咨询
          </p>
          <p class="text">
            名称:  {{ productInfo.name }}
          </p>
          <p class="text">
            联系人:  {{ productInfo.contacts }}
          </p>
          <p class="text">
            联系人电话:  {{ productInfo.phone }}
          </p>
          <p class="text">
            {{ productInfo.qq? 'QQ: '+productInfo.qq: '微信: '+ productInfo.wechat }}
          </p>
          <p class="text">
            价格:  {{ productInfo.price }}
          </p>
          <p class="text">
            产品介绍:  {{ productInfo.introduction }}
          </p>
        </el-col>
      </el-row>
    </header>
    <DataSimbaVue v-if="productInfo.name === 'dataSimba'" />
    <DataNuzaVue v-if="productInfo.name === 'dataNuza'" />
    <DataMaleonVue v-if="productInfo.name === 'dataMaleon'" />
    <DataHubbleVue v-if="productInfo.name === 'dataHubble'" />
    <EfficiencyVue v-if="productInfo.name === '智能人效'" />
    <NissinVue v-if="productInfo.name === '智能日清'" />
    <CockpitVue v-if="productInfo.name === '智能管理驾驶舱'" />
    <ReplenishVue v-if="productInfo.name === '智能补货'" />
    <RecommendationVue v-if="productInfo.name === '智能推荐'" />
    <BusinessConditionsVue v-if="productInfo.name === '智能商情'" />
    <ConsumerVue v-if="productInfo.name === '消费者运营服务'" />
    <GlobalFooterVue />
  </div>
</template>

<script>
import DataSimbaVue from './dataSimba.vue';
import DataNuzaVue from './dataNuza.vue';
import DataHubbleVue from './dataHubble.vue';
import DataMaleonVue from './dataMaleon.vue';
import EfficiencyVue from './efficiency.vue';
import NissinVue from './nissin.vue';
import CockpitVue from './cockpit.vue';
import ReplenishVue from './replenish.vue';
import RecommendationVue from './recommendation.vue';
import BusinessConditionsVue from './businessConditions.vue';
import GlobalFooterVue  from "../../components/GlobalFooter.vue";
import ConsumerVue from './consumer.vue';
    export default {
        name:'ProductDetail',
        components:{
            DataSimbaVue,
            GlobalFooterVue,
            DataNuzaVue,
            DataHubbleVue,
            DataMaleonVue,
            EfficiencyVue,
            NissinVue,
            CockpitVue,
            ReplenishVue,
            RecommendationVue,
            BusinessConditionsVue,
            ConsumerVue
        },
        data(){
            return{
                currentProduct:'',
                productInfo:{}
            }
        },
        created(){
            this.productInfo = JSON.parse(localStorage.getItem('productDetail'))
        },
        mounted() {
            window.addEventListener("setItemEvent", (e) => {
                this.productInfo = JSON.parse(e.newValue)
            });
        } 
    }
</script>

<style lang="scss" scoped>
.title{
    font-size: 22px;
    color: blue;
    margin-bottom: 20px;
}
.back{
    margin: 20px;
    cursor: pointer;
}
header{
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
}

img{
    width: 100px;
    height: 100px;
    margin:auto 0
}
.text{
    font-size: 16px;
    margin-bottom: 18px;
    &:last-child{
        margin-bottom: 0;
    }
}
.info{
    width: 1280px;
}
@media screen and (max-width: 1440px) {
    .info{
        width: 960px;
        
    }
}
</style>