export default {
  title: '奇点云-DataSimba',
  topBriefContent: {
    title: `奇点DataSimba`,
    subTitle: `一站式<br />大数据智能服务平台`,
    titleStyle: {
      color: '#0475FF',
    },
    rightImg: require("~/assets/images/simba/top-header.jpg"),
  },
  graphicFirstContent:{
    headline: '产品优势',
    summarize: '',
    graphicList:[
      {
        img: require('~/assets/images/simba/data-1.png'),
        title: '轻量级部署，低成本投入',
        desc: `极速部署，即开即用，管控节点 docker 化，计算节点虚拟化；最小化4台虚拟机，8G+16G 内存配置即可低成本上线。`,
        listStyle: {
          marginTop: '100px'
        },
        imgStyle: {
            width: '142px',
            height: '138px',
        }
      },
      {
        img: require('~/assets/images/simba/data-2.png'),
        title: '多源数据适配，非入侵式数据集成',
        desc: '适配主流关系型、非关系型数据源，快速构建数据同步作业；非入侵式数据集成，基于 CDC 技术实时捕获增量日志变化，对业务端零干扰。',
        listStyle: {
          marginTop: '100px'
        },
        imgStyle: {
          width: '148px',
          height: '126px',
          marginTop: '12px',
        }
      },
      {
        img: require('~/assets/images/simba/data-3.png'),
        title: '简单易用低门槛，多人协作高效率',
        desc: '集成大数据核心组件，提供智能开发 IDE，通过简单的 SQL 编译即可让企业拥有大数据能力；多人协作管理、开发，大大提高开发效率，有效缩短开发周期。',
        listStyle: {
          marginTop: '100px'
        },
        imgStyle: {
          width: '140px',
          height: '133px',
          marginTop: '5px',
        }
      },
      {
        img: require('~/assets/images/simba/data-4.png'),
        title: '快速规范建模，高效稳定调度',
        desc: '提供规范的数据分层模型、加工及服务能力，保障建模流程规范可控；用户可自定义数据处理逻辑，作业可实现定时、依赖方式的自动调度，保障作业定时保质产出。',
        listStyle: {
          marginTop: '100px'
        },
        imgStyle: {
          width: '140px',
          height: '117px',
        }
      },
      {
        img: require('~/assets/images/simba/data-5.png'),
        title: '一站式数据开发，系统覆盖全链路',
        desc: '数据开发在线化、可视化，从开发、调试、发布到监控运维，覆盖数据开发全链路，一站到底，流畅简便。',
        listStyle: {
          marginTop: '100px'
        },
        imgStyle: {
          width: '140px',
          height: '122px',
          marginTop: '-5px',
        }
      },
      {
        img: require('~/assets/images/simba/data-6.png'),
        title: '保障数据安全，数据自主权在握',
        desc: '支持私有化部署及混合部署，用户无需将数据传输到外部环境，彻底保障数据自主权。同时支持数据权限控制、数据脱敏、实时审计、高危风险告警，确保数据绝对安全。',
        listStyle: {
          marginTop: '100px'
        },
        imgStyle: {
          width: '140px',
          height: '118px',
          marginTop: '-1px',
        }
      }
    ]
  },
  graphicThirdContent:{
    headline: '核心价值',
    summarize: '',
    graphicList:[
      {
        img: require('~/assets/images/simba/data-value-1.png'),
        title: '',
        desc: '数据智能应用辅助运营决策，或智能化自动运营决策，为企业直接带来降本增效'
      },
      {
        img: require('~/assets/images/simba/data-value-2.png'),
        title: '',
        desc: '打通用户全触点数据，沉淀企业数据资产，驱动企业数字化转型，开启数据驱动业务创新之路'
      },
      {
        img: require('~/assets/images/simba/data-value-3.png'),
        title: '',
        desc: '统一的开放的智能的大数据计算及服务平台基础设施，减少后续新业务带来的重复投入'
      },
    ]
  },
  appData: {
    title: '应用场景',
    bgImg: require('~/assets/images/simba/group.jpg'),
    list: [
      {
        title: '企业数据中台',
        subTitle: '集成-开发-数据服务<br />一站式完成',
      },
      {
        title: '大数据计算及分析',
        subTitle: '利用集群及强大的计算引擎<br />完成海量大量数据分析',
      },
      {
        title: '数据业务化创新',
        subTitle: '集成全域数据，利用机器学习<br />自动计算门店补货',
      },
    ]
  },
}
