<template>
  <div class="consumer-container">
    <swipe
      title="消费者运营服务"
      desc="当我们在谈「以消费者为中心」时，实际上在谈什么？"
      btn="服务咨询"
    />
    <div class="consumer-digital">
      <Title>消费者运营服务</Title>
      <div class="desc">
        消费者运营服务旨在帮助企业建立 「从消费者视角驱动销售增长」的经营逻辑和运营体系
        <br>产出可落地、直接应用于业务的解决方案
        <br>让消费者 （会员）运营脱虚向实，直接分解并带动销售与利润增长。
      </div>
    </div>
    <div class="consumer-system">
      <div class="common-wrap">
        <Title>CVM : 第三代数字化消费者运营体系</Title>
        <div class="desc">
          以提升会员销售和经营效率为目标
        </div>
        <div class="layer-content">
          <div class="item item-01">
            营销价值
          </div>
          <div class="item item-02">
            周期内销售
          </div>
          <div class="item item-03">
            消费者数量
          </div>
          <div class="item item-04">
            平均购买金额
          </div>
          <div class="hide-content">
            <span>销售</span>
            <span>C</span>
            <span>V</span>
            <span>M</span>
          </div>
        </div>
      </div>
    </div>
    <div class="consumer-service">
      <Title>服务内容</Title>
      <div class="common-wrap">
        <div class="service-list">
          <div class="service-item">
            <div class="service-header">
              <div class="name">
                消费者数字化运营策略服务
              </div>
              <div class="tag">
                <img
                  class="icon"
                  src="~@/assets/images/products/service/consumer/icon01.png"
                >
                策略
              </div>
            </div>
            <div class="service-body">
              <div class="title">
                人群差异化运营策略
              </div>
              <ul>
                <li>概况：年度消费者洞察报告</li>
                <li>目标：月度消费者运营分析报告</li>
                <li>执行：细分人群提升可执行方案</li>
                <li>优化：人群运营效果评估优化</li>
                <li>命题研究：新品推广、促销影响</li>
              </ul>
            </div>
          </div>
          <div class="service-item">
            <div class="service-header">
              <div class="name">
                天猫数据银行服务
              </div>
              <div class="tag tag-02">
                <img
                  class="icon"
                  src="~@/assets/images/products/service/consumer/icon02.png"
                >
                公域
              </div>
            </div>
            <div class="service-body">
              <div class="title">
                提升营销效率和会员价值
              </div>
              <ul>
                <li>现状：消费者数字化评估</li>
                <li>目标：消费者数字化蓝图</li>
                <li>
                  <span>落地：关键落地4个环节方案</span>
                  <p class="start">
                    数字化方案、移动端链接方案
                  </p>
                  <p>会员销售增长方案、场景化方案</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="service-item">
            <div class="service-header">
              <div class="name">
                私域代运营服务
              </div>
              <div class="tag tag-02">
                <img
                  class="icon"
                  src="~@/assets/images/products/service/consumer/icon03.png"
                >
                私域
              </div>
            </div>
            <div class="service-body">
              <div class="title">
                私域活跃和销售转化
              </div>
              <ul>
                <li>搭建4位一体的私域运营阵地</li>
                <li>消费者进入私域方案</li>
                <li>消费者私域活跃度运营</li>
                <li>消费者私域购买转化</li>
                <li>按效果付费</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="consumer-success">
      <Title>客户成功</Title>
      <div class="common-wrap">
        <div class="energy-content">
          <div class="energy-content-over">
            <transition
              name="fade"
              mode="out-in"
            >
              <div
                :key="energyContent.name"
                class="energy-banner"
                :style="{
                  backgroundImage: 'url(' + energyContent.banner + ')',
                  backgroundSize: 'cover',
                }"
              >
                <div v-show="energyContent.name === '林清轩'">
                  <img
                    class="logo"
                    :src="energyContent.logoHover"
                    alt
                  >
                  <div class="name-content">
                    <div class="tag tag-02">
                      <img
                        class="icon"
                        src="~@/assets/images/products/service/consumer/icon03.png"
                      >
                      公域
                    </div>
                    <div class="name">
                      天猫数据银行服务和会员运营服务
                    </div>
                  </div>
                  <div
                    class="scroll-content"
                    :class="{ active: scrollState }"
                  >
                    <div class="scroll-step">
                      <div class="scroll-step-flex scroll-step-02">
                        <div class="item">
                          <div class="header">
                            ＜
                            <span class="number">5</span> 元
                          </div>
                          <div class="desc">
                            入会成本
                          </div>
                        </div>
                        <div class="item">
                          <div class="header">
                            <span class="number">ROI 1:7</span>
                          </div>
                          <div class="desc">
                            纯新客交易
                          </div>
                        </div>
                        <div class="item">
                          <div class="header">
                            <span class="number">30</span>%以上
                          </div>
                          <div class="desc">
                            3月内复购
                          </div>
                        </div>
                      </div>
                      <div class="info">
                        平均1人带5人入会，进入页面后入会并领取权益比例超过65%，远高于普通潜客的入会率（10%-50%）
                        <br>分享引导大于等于5个入会的用户有20个，最多一个用户引导入会人数超过400个
                      </div>
                    </div>
                    <div class="next-step">
                      <div class="item">
                        <div class="title">
                          GMV预测及人群运营目标拆解
                        </div>
                        <p>
                          基于GMV目标进行预估与人群运营目标
                          <br>拆分
                        </p>
                      </div>
                      <div class="item">
                        <div class="title">
                          品牌指定命题研究
                        </div>
                        <p>
                          基于人、货、场维度不同生意命题研究
                          <br>研究命题上限为3个，命题研究具体方
                          <br>向视品牌实际情况而定
                        </p>
                      </div>
                      <div class="item">
                        <div class="title">
                          大促活动支持
                        </div>
                        <p>
                          5次数据银行+新客策天猫线上活动支持
                          <br>年货节、情人节、38、520、618、
                          <br>99、双十一、双十二、超品日、小黑盒
                          <br>以及品牌自定义大促等，任选5次
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="scroll-btn"
                    :class="{ 'up-btn': scrollState }"
                    @click="scrollState = !scrollState"
                  >
                    <img src="~@/assets/images/products/service/consumer/arrow.png">
                  </div>
                </div>
                <div v-show="energyContent.name === '得益乳业'">
                  <img
                    class="logo"
                    :src="energyContent.logoHover"
                    alt
                  >
                  <div class="name-content">
                    <div class="tag tag-03">
                      <img
                        class="icon"
                        src="~@/assets/images/products/service/consumer/icon03.png"
                      >
                      私域
                    </div>
                    <div class="name">
                      私域运营链路搭建与代运营服务
                    </div>
                  </div>
                  <div
                    class="scroll-content big-scroll-content"
                    :class="{ active: scrollState }"
                  >
                    <div class="scroll-step">
                      <div class="scroll-step-flex scroll-step-03">
                        <div class="item">
                          <div class="header">
                            <span class="number">802</span>%
                          </div>
                          <div class="desc">
                            在线化业务占比环比增长
                          </div>
                        </div>
                        <div class="item">
                          <div class="header">
                            <span class="number">902</span>%
                          </div>
                          <div class="desc">
                            在线化业务GMV环比增长
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="next-step">
                      <div class="item">
                        <div class="title">
                          多场景消费者在线化链路建设
                        </div>
                        <p>
                          商超场景（对客流量大，品牌原本难以
                          <br>触达人群的在线化）、自营专卖门店场
                          <br>景（抓住自营门店主要精准客群及自然
                          <br>流量）、益家订商城（战略型核心在线
                          <br>化转化阵地引导链路）。
                        </p>
                      </div>
                      <div class="item">
                        <div class="title">
                          私域矩阵建设
                        </div>
                        <p>
                          通过四位一体私域矩阵建设提升私域活
                          <br>跃：企业微信（打通员工绩效，引导建
                          <br>设私域会员核心链接）、微信公众平台
                          <br>（品牌形象及活动图文触达）、微信社
                          <br>群（多种玩法福利增加粘性及活跃
                          <br>度）、模板消息（精准服务通知，积分
                          <br>变动、个性化人群发券等）。
                        </p>
                      </div>
                      <div class="item">
                        <div class="title">
                          私域转化阵地建设
                        </div>
                        <p>
                          通过引导用户在品牌自有在线化平台
                          <br>（小程序、C端app等）完成交易，增
                          <br>加在线化交易额，逐步实现企业在线化
                          <br>转型。
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="scroll-btn"
                    :class="{ 'up-btn': scrollState }"
                    @click="scrollState = !scrollState"
                  >
                    <img src="~@/assets/images/products/service/consumer/arrow.png">
                  </div>
                </div>
                <div v-show="energyContent.name === '某大型商超集团'">
                  <!-- <img class="logo" :src="energyContent.logoHover" alt /> -->
                  <div class="name-content">
                    <div class="tag">
                      <img
                        class="icon"
                        src="~@/assets/images/products/service/consumer/icon03.png"
                      >
                      策略
                    </div>
                    <div class="name">
                      消费者数字化运营策略服务
                    </div>
                  </div>
                  <div
                    class="scroll-content"
                    :class="{ active: scrollState }"
                  >
                    <div class="scroll-step">
                      <div class="scroll-step-flex">
                        <div class="item">
                          <div class="header">
                            <span class="number">372</span>元
                            <img
                              class="up"
                              src="~@/assets/images/products/service/consumer/up.png"
                            >
                            <span class="number">261</span>%
                          </div>
                          <div class="desc">
                            平均笔单
                            <br>盈利效果
                          </div>
                        </div>
                        <div class="item">
                          <div class="header">
                            <span class="number">279</span>元
                            <img
                              class="up"
                              src="~@/assets/images/products/service/consumer/up.png"
                            >
                            <span class="number">4</span>倍
                          </div>
                          <div class="desc">
                            LV1
                            <br>原笔单 0-100元
                          </div>
                        </div>
                        <div class="item">
                          <div class="header">
                            <span class="number">446</span>元
                            <img
                              class="up"
                              src="~@/assets/images/products/service/consumer/up.png"
                            >
                            <span class="number">3.5</span>倍
                          </div>
                          <div class="desc">
                            LV2
                            <br>原笔单100-200元
                          </div>
                        </div>
                        <div class="item">
                          <div class="header">
                            <span class="number">497</span>元
                            <img
                              class="up"
                              src="~@/assets/images/products/service/consumer/up.png"
                            >
                            <span class="number">2.6</span>倍
                          </div>
                          <div class="desc">
                            LV3
                            <br>原笔单200-300元
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="next-step">
                      <div class="item">
                        <div class="title">
                          活动目标人群查找与确定
                        </div>
                        <p>
                          根据往期消费行为将会员价值等级划分
                          <br>V1-V5
                          <br>通过Nuza CDP系统根据客单对人群进
                          <br>行二次细分
                        </p>
                      </div>
                      <div class="item">
                        <div class="title">
                          活动目标人群洞察
                        </div>
                        <p>
                          系统功能：人群管理-自定义人群-人群
                          <br>洞察+人群画像
                          <br>运用标签：消费者价值等级+平均笔单
                          <br>+消费频次+活跃度+年龄+性别+消费时
                          <br>间等
                        </p>
                      </div>
                      <div class="item">
                        <div class="title">
                          目标人群运营
                        </div>
                        <p>
                          千人千权：通过系统的圈人功能定位目
                          <br>标人群，以自主领券（大转盘抽奖）和
                          <br>手工发券针对不同人群发放不同优惠券
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="scroll-btn"
                    :class="{ 'up-btn': scrollState }"
                    @click="scrollState = !scrollState"
                  >
                    <img src="~@/assets/images/products/service/consumer/arrow.png">
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <div
            v-if="energyData.length > 1"
            class="energy-list"
          >
            <div
              v-for="(item, index) in energyData"
              :key="item.name"
              :class="index === activeIndex ? 'itemActive' : 'item'"
              @mouseenter="() => toSwitch(item, index)"
            >
              <img
                class="brand-logo"
                :src="index === activeIndex ? item.logoHover : item.logo"
                alt
              >
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "./components/Title/index";
import Swipe from "./components/Swipe/index";
export default {
  components: {
    Title,
    Swipe
  },
  transition: "bounce",
  data() {
    return {
      energyData: [
        {
          name: "林清轩",
          banner: require("~/assets/images/customerLogo/linqinxuanBanner.png"),
          logo: require("~/assets/images/customerLogo/linqinxuan.png"),
          logoHover: require("~/assets/images/customerLogo/linqinxuan-hover.png")
        },
        {
          name: "得益乳业",
          banner: require("~/assets/images/nuza/deyiBanner.png"),
          logo: require("~/assets/images/customerLogo/deyi.png"),
          logoHover: require("~/assets/images/customerLogo/deyi-hover.png")
        },
        {
          name: "某大型商超集团",
          banner: require("~/assets/images/simba/yongwang-bg.png"),
          // logo: require("~/assets/images/customerLogo/yongwang.png"),
          // logoHover: require("~/assets/images/customerLogo/yongwang_hover.png"),
          des:
            "依托DataSimba，实现数据集成、规范建模研发加工计算等，通过对业务过程的梳理，<br/>建立数据模型，同步至DataNuza，支撑数据驱动消费者精细化运营——有效实现品<br/>类渗透率和消费者消费频次的提升，在某单次活动中销售总额提升200+%。<br/>高效稳定的运维底座支撑某大型商超集团数据化运营任务的调度执行，并清晰展现<br/>用户数据沟通，让数据使用和运营更便捷。"
          // url:
          //   "https://mp.weixin.qq.com/s?__biz=MzIzMzczNjgyNQ==&mid=2247487195&idx=1&sn=239fcd51c0ac8a9efbe5b423eb187d17&chksm=e88051cddff7d8db153df66b3d3091032db59f3a711541667d3ebcf0ed8bb2314c0937ffe6d9&token=61507205&lang=zh_CN#rd",
        }
      ],
      activeIndex: 0,
      energyContent: {},
      scrollState: false
    };
  },
  created() {
    const item = this.energyData[0];
    this.energyContent = {
      name: item.name,
      banner: item.banner,
      logo: item.logo,
      logoHover: item.logoHover,
      des: item.des,
      url: item.url
    };
  },
  methods: {
    toSwitch(item, index) {
      this.energyContent = {
        name: item.name,
        banner: item.banner,
        logo: item.logo,
        logoHover: item.logoHover,
        des: item.des,
        url: item.url
      };
      this.activeIndex = index;
      this.scrollState = false;
    }
  },
  head() {
    return {
      title: "消费者运营_数字化转型_消费者运营服务_奇点云",
      meta: [
        {
          name: "keywords",
          content: "消费者运营，数字化转型，消费者运营服务，奇点云"
        },
        {
          name: "description",
          content:
            "如何有效实现消费者运营？一站式大数据智能服务平台，旨在为企业提供数字化转型、数据中台建设及解决方案。通过奇点云数据中台，为企业提供端到端的消费者运营服务。"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.consumer-container {
//   padding-top: 68px;
  min-width: 1280px;
  .swipe-box {
    background: url("~@/assets/images/products/service/consumer/bg.jpg") center
      0;
    background-size: auto 100%;
  }
  .consumer-digital {
    padding-top: 120px;
    height: 680px;
    background: url("~@/assets/images/products/service/consumer/digital-bg.jpg")
      center 0 no-repeat;
    background-size: auto 100%;
    .desc {
      text-align: center;
      padding-top: 56px;
      font-size: 18px;
      font-weight: 400;
      color: #151515;
      line-height: 36px;
      letter-spacing: 1px;
    }
  }
  .consumer-system {
    height: 712px;
    background: url("~@/assets/images/products/service/consumer/system-bg.jpg")
      center 0;
    background-size: auto 100%;
    padding-top: 120px;
    text-align: center;
    color: #ffffff;
    .q-title {
      background: linear-gradient(180deg, #04e4ff 0%, #0283ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .desc {
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      letter-spacing: 1px;
      padding-top: 19px;
    }
    .layer-content {
      position: relative;
      .item {
        width: 126px;
        height: 40px;
        line-height: 36px;
        border-radius: 2px;
        font-size: 18px;
        font-weight: 500;
        line-height: 36px;
        position: absolute;
        top: 334px;
      }
      .item-01 {
        background: rgba(129, 64, 231, 0.2);
        border: 2px dashed #8140e7;
        top: 134px;
        right: 40px;
        width: 110px;
      }
      .item-02 {
        background: rgba(252, 104, 156, 0.2);
        border: 2px dashed #fc689c;
        left: 260px;
      }
      .item-03 {
        background: rgba(36, 204, 230, 0.2);
        border: 2px dashed #24cce6;
        left: 50%;
        margin-left: -63px;
      }
      .item-04 {
        background: rgba(71, 125, 244, 0.2);
        border: 2px dashed #477df4;
        right: 260px;
      }
    }
  }
  .consumer-service {
    padding: 120px 0;
    .q-title {
      padding-bottom: 80px;
    }
    .service-list {
      display: flex;
      margin: 0 -12px;
      .service-item {
        flex: 1;
        margin: 0 12px;
        height: 458px;
        background: #f7f9fc;
        border-radius: 6px;
        transition: all 1s;
        .service-header {
          height: 140px;
          background: url("~@/assets/images/products/service/consumer/content-01.png");
          background-size: cover;
          padding: 40px 0 0 40px;
          .name {
            font-size: 27px;
            font-weight: 500;
            color: #ffffff;
            padding-bottom: 10px;
          }
        }
        .service-body {
          padding: 32px 40px 0 40px;
          .title {
            font-size: 20px;
            font-weight: 500;
            color: #151515;
            padding-bottom: 20px;
          }
          ul {
            list-style: none;
            padding: 0;
            li {
              font-size: 16px;
              font-weight: 400;
              color: #151515;
              line-height: 32px;
              padding-bottom: 10px;
              &::before {
                content: "";
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 1px;
                background: #092653;
                opacity: 0.4;
                position: relative;
                margin-right: 16px;
                transform: rotate(45deg);
                top: -1px;
              }
              p {
                padding: 0 0 10px 70px;
              }
              .start {
                padding-top: 10px;
              }
            }
          }
        }
        &:nth-child(2) {
          .service-header {
            background: url("~@/assets/images/products/service/consumer/content-02.png");
            background-size: cover;
          }
        }
        &:nth-child(3) {
          .service-header {
            background: url("~@/assets/images/products/service/consumer/content-03.png");
            background-size: cover;
          }
        }
        &:hover {
          transition: all 1s;
          box-shadow: rgba(0, 0, 0, 0.08) 0 22px 36px 14px;
        }
      }
    }
  }
  .tag {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 26px;
    background: linear-gradient(66deg, #1d40e9 0%, #478cff 100%);
    border-radius: 2px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    .icon {
      height: 16px;
      margin-right: 4px;
    }
  }
  .tag-02 {
    background: linear-gradient(84deg, #00a6bf 1%, #3fd9f1 100%);
  }
  .tag-03 {
    background: linear-gradient(90deg, #4b08c9 0%, #8459ff 100%);
  }
  .consumer-success {
    background: #f7f9fc;
    padding: 75px 0 130px 0;
    margin-bottom: -100px;
    .q-title {
      padding-bottom: 50px;
    }
    .energy-content {
      height: 800px;
      position: relative;
      overflow: hidden;
      .energy-content-over {
        height: 640px;
        width: 100%;
        overflow: hidden;
      }
      .fade-enter,
      .fade-leave-to {
        opacity: 0;
      }
      .fade-enter-active,
      .fade-leave-active {
        transition: opacity 0.5s;
      }
      .energy-banner {
        width: 100%;
        height: 640px;
        text-align: center;
        color: #fff;
        padding: 83px 0;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        .logo {
          height: 30px;
          margin-bottom: 10px;
        }
        .name-content {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 74px;
          .name {
            padding-left: 10px;
            font-size: 28px;
            font-weight: 600;
          }
        }
        .scroll-content {
          height: 200px;
          overflow: hidden;
          .scroll-step {
            transition: all 0.5s;
            opacity: 1;
            transform: translateY(0);
            height: 100%;
            .scroll-step-flex {
              display: flex;
              justify-content: center;
              padding-top: 40px;
              .item {
                padding: 0 40px;
                .header {
                  font-size: 14px;
                  font-weight: 400;
                  padding-bottom: 10px;
                  .up {
                    height: 22px;
                    margin-left: 14px;
                  }
                  .number {
                    font-size: 34px;
                    font-family: DINAlternate-Bold;
                    font-weight: bold;
                  }
                }
                .desc {
                  font-size: 14px;
                  font-weight: 400;
                  color: #ffffff;
                  line-height: 24px;
                }
              }
            }
            .info {
              padding-top: 36px;
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
              line-height: 26px;
            }
            .scroll-step-02 {
              .item {
                padding: 0 80px;
              }
            }
            .scroll-step-03 {
              .item {
                padding: 0 54px;
              }
            }
          }
          .next-step {
            display: flex;
            justify-content: center;
            height: 100%;
            transform: translateY(0);
            transition: all 0.5s;
            opacity: 0;
            .item {
              padding: 0 37px;
              text-align: left;
              .title {
                font-size: 20px;
                font-weight: 500;
                color: #ffffff;
                padding-bottom: 20px;
              }
              p {
                font-size: 16px;
                font-weight: 400;
                color: #ffffff;
                line-height: 32px;
              }
            }
          }
        }
        .big-scroll-content {
          height: 280px;
        }
        .active {
          .scroll-step {
            transform: translateY(-100%);
            transition: all 0.5s;
            opacity: 0;
          }
          .next-step {
            transform: translateY(-100%);
            transition: all 0.5s;
            opacity: 1;
          }
        }
        .scroll-btn {
          margin: 36px auto 0 auto;
          text-align: center;
          cursor: pointer;
          transform: rotate(0);
          transition: all 0.5s;
          img {
            height: 14px;
            position: relative;
            animation: down 4s infinite;
          }
        }
        &:hover {
          .up-btn {
            transition: all 0.5s;
            transform: rotate(180deg);
          }
        }
      }
      .energy-list {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 160px;
        display: flex;
        .item {
          flex: 1;
          padding: 42px 0;
          padding-left: 51px;
          background-color: #fff;
          img {
            height: 30px;
          }
          p {
            font-weight: 600;
            color: #151515;
            margin-top: 16px;
            font-size: 20px;
          }
          &:hover {
            cursor: pointer;
          }
        }
        .itemActive {
          flex: 1;
          height: 240px;
          padding: 81px 0;
          padding-left: 51px;
          margin-top: -81px;
          background-color: #1868ee;
          border-radius: 6px;
          position: relative;
          .brand-logo {
            height: 30px;
          }
          p {
            font-weight: 600;
            color: #fff;
            margin-top: 16px;
            font-size: 20px;
          }
          &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: url("~@/assets/images/customerLogo/watermark.png") right
              bottom no-repeat;
            background-size: auto 100%;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  @keyframes down {
    0% {
      top: 14px;
    }
    25% {
      top: 6px;
    }
    50% {
      top: -14px;
    }
    75% {
      top: 6px;
    }
    100% {
      top: 14px;
    }
  }
  @media screen and (max-width: 1440px) {
    .consumer-digital {
      padding-top: 80px;
      height: 520px;
      .desc {
        font-size: 16px;
        line-height: 30px;
        padding-top: 42px;
      }
    }
    .consumer-system {
      height: 540px;
      padding-top: 60px;
      .layer-content {
        .item {
          height: 34px;
          line-height: 30px;
          font-size: 16px;
          top: 260px;
          width: 110px;
        }
        .item-01 {
          top: 112px;
          width: 96px;
        }
        .item-02 {
          left: 200px;
        }
        .item-03 {
          margin-left: -55px;
        }
        .item-04 {
          right: 200px;
        }
      }
    }
    .consumer-service {
      padding: 70px 0;
      .q-title {
        padding-bottom: 45px;
      }
      .service-list {
        margin: 0 -8px;
        .service-item {
          margin: 0 8px;
          height: 340px;
          .service-header {
            padding: 26px 0 0 26px;
            height: 110px;
            .name {
              font-size: 21px;
              padding-bottom: 8px;
            }
            .tag {
              font-size: 14px;
              height: 22px;
              line-height: 22px;
              width: 60px;
              .icon {
                height: 14px;
              }
            }
          }
          .service-body {
            padding: 20px 26px 0 26px;
            .title {
              font-size: 18px;
              padding-bottom: 12px;
            }
            ul li {
              font-size: 14px;
              line-height: 24px;
              padding-bottom: 6px;
              &::before {
                content: "";
                width: 6px;
                height: 6px;
                margin-right: 14px;
              }
              p {
                padding: 0 0 6px 61px;
              }
              .start {
                padding-top: 6px;
              }
            }
          }
        }
      }
    }
    .consumer-success {
      padding: 40px 0 80px 0;
      .q-title {
        padding-bottom: 22px;
      }
      .energy-content {
        height: 600px;
        .energy-content-over {
          height: 480px;
        }
        .energy-banner {
          height: 480px;
          padding: 50px 0 50px 0;
          .name-content {
            padding-bottom: 46px;
            .name {
              font-size: 22px;
            }
          }
          .scroll-content {
            height: 160px;
            .scroll-step {
              .scroll-step-flex {
                padding-top: 22px;
                .item {
                  padding: 0 26px;
                  .header {
                    font-size: 12px;
                    .up {
                      height: 16px;
                      margin-left: 10px;
                    }
                    .number {
                      font-size: 26px;
                    }
                  }
                  .desc {
                    font-size: 12px;
                    line-height: 20px;
                  }
                }
              }
              .scroll-step-02 .item {
                padding: 0 70px;
              }
              .scroll-step-03 .item {
                padding: 0 60px;
              }
              .info {
                padding-top: 28px;
                font-size: 12px;
                line-height: 20px;
              }
            }

            .next-step {
              .item {
                padding: 0 22px;
                .title {
                  font-size: 16px;
                  padding-bottom: 12px;
                }
                p {
                  font-size: 12px;
                  line-height: 24px;
                }
              }
            }
          }
          .big-scroll-content {
            height: 210px;
          }
        }
        .energy-list {
          height: 120px;
          .item {
            padding: 22px 0 22px 38px;
          }
          .itemActive {
            height: 180px;
            margin-top: -60px;
            padding: 50px 0 50px 51px;
          }
        }
      }
    }
  }
}
</style>