<template>
  <div class="replenish-container">
    <swipe
      title="智能补货"
      desc="如何实现从人工经验式补货向数据化分析补货的转型升级？"
      btn="产品咨询"
    />
    <div class="replenish-system">
      <Title>智能补货系统</Title>
      <div class="desc">
        智能补货系统，借助数据中台、数据智能模型及算法应用能力，以历史积累的商品销售数据及智能销量
        <br>预测模型为基础，为用户提供智能化、便捷化、可视化的人工智能补货策略服务，实现商品补货从人工
        <br>经验式分析向数据化分析的转型升级。
      </div>
    </div>
    <div class="business-advantage">
      <div class="common-wrap">
        <Title class="business-title">
          产品优势
        </Title>
        <ul>
          <li>
            <div class="icon">
              <img src="~@/assets/images/products/replenish/icon01.png">
            </div>
            <div class="info">
              <div class="title">
                行业经验沉淀
              </div>
              <div class="desc">
                配补货算法模型充分沉淀人工企划与配补货经验，融合业务规则、库存、物流等条件因素。
              </div>
            </div>
          </li>
          <li>
            <div class="icon">
              <img src="~@/assets/images/products/replenish/icon02.png">
            </div>
            <div class="info">
              <div class="title">
                分析精细精准
              </div>
              <div class="desc">
                计算模型精准到店；算法模型综合考虑商品生命周期特征及门店类型、物流路径，得出最优解。
              </div>
            </div>
          </li>
          <li>
            <div class="icon">
              <img src="~@/assets/images/products/replenish/icon03.png">
            </div>
            <div class="info">
              <div class="title">
                持续优化迭代
              </div>
              <div class="desc">
                配补货算法模型通过数据跟踪、AI智能分析持续优化；
                <br>系统与订单业务系统无缝衔接，并具有人工审核和调参机制，不断迭代、精细化规则。
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="replenish-logic">
      <Title>业务逻辑</Title>
      <div class="common-wrap">
        <div class="framework-cell framework-cell-01">
          <div class="label">
            应用层
          </div>
          <div class="content">
            <div class="item">
              商情分析
            </div>
            <div class="item">
              销量预测
            </div>
            <div class="item">
              智能补货
            </div>
            <div class="item">
              计划执行
            </div>
            <div class="item">
              结果分析
            </div>
          </div>
        </div>
        <div class="framework-cell framework-cell-02">
          <div class="label">
            <span>数据中台</span>
            <div class="logo">
              <img src="~@/assets/images/products/cockpit/simba-logo.png">
            </div>
          </div>
          <div class="data-content">
            <div class="sub-title">
              统一数据服务（API）
            </div>
            <div class="framework-form">
              <div class="title">
                数据中台（实时计算/批量计算）
                <img
                  class="line"
                  src="~@/assets/images/products/replenish/line01.png"
                >
              </div>
              <div class="framework-flex">
                <div class="flex-item">
                  <div class="title">
                    智能数据模型
                    <img
                      class="line"
                      src="~@/assets/images/products/replenish/line02.png"
                    >
                  </div>
                  <div class="framework-content">
                    <p>ODS 数据操作</p>
                    <p>CDM 数据汇总/维度建模</p>
                    <p>ADS 数据应用</p>
                  </div>
                </div>
                <div class="flex-item">
                  <div class="title">
                    算法模型
                    <img
                      class="line"
                      src="~@/assets/images/products/replenish/line02.png"
                    >
                  </div>
                  <div class="framework-content">
                    <p>机器学习</p>
                    <p>销量预测</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="sub-title">
              数据中台（DataSimba）
            </div>
          </div>
        </div>
        <div class="framework-cell framework-cell-03">
          <div class="label">
            数据源
          </div>
          <div class="content framework-list">
            <div class="list-item">
              <div class="title">
                历史数据
                <img
                  class="line"
                  src="~@/assets/images/products/replenish/line03.png"
                >
              </div>
              <div class="list-content">
                <p>商品数据</p>
                <p>门店数据</p>
                <p>补货记录</p>
                <p>销售数据</p>
                <p>库存数据</p>
                <p>运输成本</p>
              </div>
            </div>
            <div class="list-item">
              <div class="title">
                现时数据
                <img
                  class="line"
                  src="~@/assets/images/products/replenish/line03.png"
                >
              </div>
              <div class="list-content">
                <p>新商品</p>
                <p>商品库存</p>
                <p>现时门店</p>
                <p>销售数据</p>
                <p>其他数据</p>
              </div>
            </div>
            <div class="list-item">
              <div class="title">
                第三方数据
                <img
                  class="line"
                  src="~@/assets/images/products/replenish/line03.png"
                >
              </div>
              <div class="list-content">
                <p>天气数据</p>
                <p>节假日数据</p>
                <p>地理位置数据</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="replenish-successful">
      <div class="common-wrap">
        <Title>客户成功</Title>
        <energy :energy-data="energyData" />
      </div>
    </div>
  </div>
</template>

<script>
import Title from "./components/Title/index";
import Swipe from "./components/Swipe/index";
import energy from "./components/energy/index";

import xigeLogoHover from "~/assets/images/customerLogo/xigeshiye-hover.png";

export default {
  components: {
    Title,
    energy,
    Swipe
  },
  transition: "bounce",
  data() {
    return {
      energyData: [
        {
          name: "喜歌实业",
          logoHover: xigeLogoHover,
          banner: require("~/assets/images/customerLogo/xigeshiyeBanner.png"),
          des:
            "依托智能补货系统，助力服饰集团喜歌实业实现一键自动生成实时补货计划，补货工作比<br/>原来节约了95%的人力时间成本，补货工作考核指标（断码率、补货售罄率）达成率平均<br/>提升20%以上，大幅避免了人工经验水平差异和工作意外导致的补货效果波动。"
        }
      ]
    };
  },
  head() {
    return {
      title: "数据中台_数据智能平台_数据智能应用_智能补货_奇点云",
      meta: [
        {
          name: "keywords",
          content: "数据中台，数据智能平台，数据智能应用，智能补货，奇点云"
        },
        {
          name: "description",
          content:
            "如何实现智能补货？一站式大数据智能服务平台，为企业客户提供数字化消费者运营解决方案。借助数据中台、数据智能模型及算法应用能力，为用户提供智能化、便捷化、可视化的人工智能补货策略服务。"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.replenish-container {
//   padding-top: 68px;
  min-width: 1280px;
  .swipe-box {
    background: url("~@/assets/images/products/replenish/swipe.jpg") center 0;
    background-size: auto 100%;
  }
  .replenish-system {
    background: url("~@/assets/images/products/replenish/replenish-bg.png")
      center 0;
    background-size: auto 100%;
    height: 764px;
    padding-top: 120px;
    .desc {
      padding-top: 56px;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      color: #151515;
      line-height: 32px;
      letter-spacing: 1px;
    }
  }
  .business-advantage {
    background: url("~@/assets/images/products/replenish/power-bg.jpg") center 0;
    background-size: auto 100%;
    height: 890px;
    .business-title {
      color: #ffffff;
      padding: 110px 0 60px 0;
    }
    ul {
      li {
        display: flex;
        align-items: center;
        height: 160px;
        background: linear-gradient(
          90deg,
          rgba(187, 73, 250, 0.04) 0%,
          rgba(29, 85, 207, 0.5) 100%
        );
        border-radius: 6px;
        border-left: none;
        margin-bottom: 30px;
        .icon {
          padding: 0 66px 0 60px;
          img {
            width: 120px;
          }
        }
        .info {
          flex: 1;
          .title {
            font-size: 26px;
            font-weight: 600;
            color: #ffffff;
            letter-spacing: 1px;
            padding-bottom: 13px;
          }
          .desc {
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            line-height: 26px;
            opacity: 0.8;
          }
        }
      }
    }
  }
  .replenish-logic {
    padding: 110px 0 120px 0;
    .q-title {
      padding-bottom: 70px;
    }
    .framework-cell {
      display: flex;
      align-items: center;
      padding: 20px 0;
      background: #eff8ff;
      margin-bottom: 26px;
      .label {
        width: 181px;
        padding-left: 32px;
        font-size: 24px;
        font-weight: 600;
        color: #3cbdcb;
        line-height: 33px;
        background: linear-gradient(180deg, #45d8e8 0%, #005485 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: #ffffff;
        .logo {
          margin-top: 8px;
          font-size: 0;
          img {
            height: 32px;
          }
        }
      }
      .content {
        display: flex;
        flex: 1;
        margin: 0 -18px;
        padding-right: 55px;
        .item {
          flex: 1;
          height: 44px;
          line-height: 44px;
          text-align: center;
          background: #3cbdcb;
          margin: 0 18px;
          border-radius: 2px;
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .data-content {
        flex: 1;
        padding-right: 55px;
        .sub-title {
          height: 48px;
          background: #3e9cff;
          border-radius: 2px;
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
          text-align: center;
          line-height: 48px;
        }
        .framework-form {
          margin: 14px 0;
          background: #dfeeff;
          border-radius: 2px;
          padding: 20px 27px;
          font-size: 18px;
          font-weight: 600;
          text-align: center;
          .title {
            color: #3cbdcb;
            background: linear-gradient(180deg, #3e9cff 0%, #0073c5 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 28px;
            position: relative;
            z-index: 4;
          }
          .framework-flex {
            display: flex;
            margin: 0 -14px;
            .flex-item {
              flex: 1;
              .title {
                margin-bottom: 16px;
              }
              .framework-content {
                background: #ffffff;
                margin: 0 14px;
                height: 153px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                p {
                  color: #3cbdcb;
                  background: linear-gradient(180deg, #3e9cff 0%, #0073c5 100%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  line-height: 2;
                }
              }
            }
          }
        }
      }
      .line {
        position: absolute;
        height: 2px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .framework-list {
        margin: 0 -13px;
        text-align: center;
        .title {
          font-size: 18px;
          font-weight: 600;
          color: #3cbdcb;
          background: linear-gradient(180deg, #687eb0 0%, #0b2662 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 14px;
          position: relative;
        }
        .list-item {
          flex: 1;
          margin: 0 13px;
          .list-content {
            padding: 27px 44px 15px 44px;
            background: #e7eaf1;
            border-radius: 2px;
            p {
              width: 50%;
              float: left;
              padding-bottom: 12px;
              font-size: 18px;
              font-weight: 600;
              color: #3cbdcb;
              background: linear-gradient(180deg, #687eb0 0%, #0b2662 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            &::after {
              content: "";
              display: block;
              clear: both;
            }
          }
          &:nth-child(2) {
            .list-content {
              p:nth-last-child(1) {
                width: 100%;
              }
            }
          }
          &:nth-child(3) {
            .list-content {
              p {
                width: 100%;
                float: none;
              }
            }
          }
        }
      }
    }
    .framework-cell-02 {
      background: #eff8ff;
      .label span {
        color: #3cbdcb;
        background: linear-gradient(180deg, #3e9cff 0%, #0073c5 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .framework-cell-03 {
      background: #f8f9fb;
      margin-bottom: 0;
      .label {
        color: #3cbdcb;
        background: linear-gradient(180deg, #687eb0 0%, #0b2662 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .replenish-successful {
    background: #f7f9fc;
    padding: 75px 0 130px 0;
    margin-bottom: -100px;
    .q-title {
      padding-bottom: 50px;
    }
    .energy-content {
      height: 800px;
    }
  }
  @media screen and (max-width: 1440px) {
    .replenish-system {
      height: 580px;
      padding-top: 80px;
      .desc {
        padding: 34px;
        font-size: 14px;
        line-height: 28px;
      }
    }
    .business-advantage {
      height: 660px;
      .business-title {
        padding: 80px 0 40px 0;
      }
      ul li {
        height: 120px;
        margin-bottom: 20px;
        .icon {
          padding: 0 46px 0 40px;
          img {
            width: 80px;
          }
        }
        .info {
          .title {
            font-size: 20px;
            padding-bottom: 6px;
          }
          .desc {
            font-size: 12px;
          }
        }
      }
    }
    .replenish-logic {
      padding: 74px 0 80px 0;
      .q-title {
        padding-bottom: 50px;
      }
      .framework-cell {
        padding: 12px 0;
        .label {
          width: 154px;
          font-size: 20px;
          padding-left: 26px;
          .logo {
            margin-top: 4px;
            img {
              height: 24px;
            }
          }
        }
        .content {
          padding-right: 38px;
          margin: 0 -14px;
          .item {
            height: 38px;
            line-height: 38px;
            font-size: 16px;
            margin: 0 14px;
          }
        }
        .data-content {
          padding-right: 38px;
          .sub-title {
            height: 42px;
            line-height: 42px;
            font-size: 16px;
          }
          .framework-form {
            margin: 10px 0;
            padding: 16px 22px;
            font-size: 16px;
            .title {
              margin-bottom: 22px;
            }
            .framework-flex {
              margin: 0 -10px;
              .flex-item {
                .framework-content {
                  height: 140px;
                  margin: 0 10px;
                }
              }
            }
          }
        }
        .framework-list {
          margin: 0 -10px;
          .list-item {
            margin: 0 10px;
            .title {
              font-size: 16px;
              margin-bottom: 10px;
            }
            .list-content {
              padding: 22px 34px 10px 34px;
              p {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .replenish-successful {
      padding: 58px 0 84px 0;
      .q-title {
        padding-bottom: 32px;
      }
      .energy-content {
        height: 480px;
      }
    }
  }
}
</style>