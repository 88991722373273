<template>
  <div class="business-container">
    <swipe
      title="智能商情"
      desc="商情信息，一手掌握"
      btn="产品咨询"
    />
    <div class="business-system">
      <Title>智能商情系统</Title>
      <div class="desc">
        智能商情系统，依托大数据和AI能力，聚合多源数据，助力企业实现行业数据的多维度、多指标比对分
        <br>析，全方位洞察品类结构，实时获取一手商情，掌握流行趋势，为商品企划、订补铺、销售运营等环节
        <br>提供建议，提高商品ROI。
      </div>
    </div>
    <div class="business-advantage">
      <div class="common-wrap">
        <Title class="business-title">
          产品优势
        </Title>
        <ul>
          <li>
            <div class="icon">
              <img src="~@/assets/images/businessConditions/icon01.png">
            </div>
            <div class="info">
              <div class="title">
                360°品类结构洞察
              </div>
              <div class="desc">
                整合多源数据，基于商品分析方法论，进行行业品类结构分析->单品类分析->品类商品排行分析->单款商品分析等分析，
                <br>层层递进下钻，全面洞察剖析。构建品类单品竞争评估体系，360°评估单品，可视化呈现一目了然，有效辅助商品企划。
              </div>
            </div>
          </li>
          <li>
            <div class="icon">
              <img src="~@/assets/images/businessConditions/icon02.png">
            </div>
            <div class="info">
              <div class="title">
                多维度灵活比对分析
              </div>
              <div class="desc">
                将品类单品与行业同类单品横向比较，随时了解单品竞争力。用户可自主选择对比视角，多维度、多指标同时比对，多
                <br>方位发现差异，深入挖掘症结所在，为商品订、铺、补环节提供数据建议。
              </div>
            </div>
          </li>
          <li>
            <div class="icon">
              <img src="~@/assets/images/businessConditions/icon03.png">
            </div>
            <div class="info">
              <div class="title">
                实时掌握一手商情
              </div>
              <div class="desc">
                行业榜单实时更新，掌握商情第一手信息。完整描述榜单商品信息和对比信息，快速发现榜单商品差异。
              </div>
            </div>
          </li>
          <li>
            <div class="icon">
              <img src="~@/assets/images/businessConditions/icon04.png">
            </div>
            <div class="info">
              <div class="title">
                精准定制匹配行业
              </div>
              <div class="desc">
                针对企业行业与品类特点，专属定制商情范围，行业数据更具指导性。
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="business-logic">
      <div class="common-wrap">
        <Title class="business-title">
          业务逻辑
        </Title>
        <div class="logic-content">
          <img src="~@/assets/images/businessConditions/logic.png">
          <div class="logic-layer">
            <div class="logic-cell">
              <div class="logic-header">
                <div class="name">
                  BI 展现
                </div>
                <div class="list">
                  <div class="item">
                    行业分析
                  </div>
                  <div class="item">
                    品类分析
                  </div>
                  <div class="item">
                    商品分析
                  </div>
                  <div class="item">
                    商品排行榜
                  </div>
                </div>
              </div>
              <div class="logic-body">
                <div class="label">
                  BI 展现：
                </div>
                <div class="desc">
                  可视化展现各种维度指标，用数据支撑商品企划、设计企划、商品运营。
                </div>
              </div>
            </div>
            <div class="logic-cell">
              <div class="logic-header">
                <div class="name">
                  维度模型
                </div>
                <div class="list">
                  <div class="item">
                    ODS
                  </div>
                  <div class="item">
                    DWD / DIM
                  </div>
                  <div class="item">
                    DWS
                  </div>
                  <div class="item">
                    ADS
                  </div>
                </div>
              </div>
              <div class="logic-body">
                <div class="label">
                  维度模型：
                </div>
                <div class="desc">
                  汇总来自不同数据库的业务数据。统一命名规则、类型、精度后，在维度模型中进行数据汇聚和指标计算。
                </div>
              </div>
            </div>
            <div class="logic-cell">
              <div class="logic-header">
                <div class="name">
                  维度清洗
                </div>
                <div class="list">
                  <div class="item">
                    有效数据筛选
                  </div>
                  <div class="item">
                    商品数据规范化
                  </div>
                  <div class="item">
                    品类标准化
                  </div>
                </div>
              </div>
              <div class="logic-body">
                <div class="label">
                  维度清洗：
                </div>
                <div class="desc">
                  根据企业经营范围，聚合全网多源数据，剔除无效数据，利用大数据算法进行商品关键词拆解，依托规则引擎进行品类归类，对数据进行规范
                  <br>化处理，实现多源数据的聚合归一，并对商品品类进行统一映射管理。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="business-successful">
      <div class="common-wrap">
        <Title>客户成功</Title>
        <energy :energy-data="energyData" />
      </div>
    </div>
  </div>
</template>

<script>
import Title from "./components/Title/index";
import Swipe from "./components/Swipe/index";
import energy from "./components/energy/index";
import businessConditions from "~/assets/dictionary/businessConditions";

import zhuoshiniBanner from "~/assets/images/shouye/zhuoshiniBanner.png";
import zhuoshiniLogo from "~/assets/images/customerLogo/zhuoshini.png";
import zhuoshiniLogoHover from "~/assets/images/customerLogo/zhuoshini-hover.png";

export default {
  components: {
    Title,
    Swipe,
    energy
  },
  transition: "bounce",
  data() {
    return {
      ...businessConditions,
      title: "奇点云-智能商情",
      energyData: [
        {
          name: "卓诗尼",
          banner: zhuoshiniBanner,
          logo: zhuoshiniLogo,
          logoHover: zhuoshiniLogoHover,
          des:
            "依托智能商情，对行业数据和企业内部数据等多源数据做了拉通，多维度分析<br/>商品品类、爆款元素、价格、客户评价等要素，帮助卓诗尼找到了正确的发力<br/>方向，从而快速对自己的商品企划、定价甚至具体到页面细节作出调整。<br/>在疫情影响下，该品牌依旧逆势而上，比竞争对手更快「回血」，5月同比还<br/>增加了30%的营业额。",
          url:
            "https://mp.weixin.qq.com/s?__biz=MzIzMzczNjgyNQ==&mid=2247486443&idx=1&sn=4e5dd88834488edb8818b972a9a623b2&chksm=e88054fddff7ddeb4225d2fd1ca38fa358c10933d1a0f251238deba5093c79743a00dfb727a6&token=1116684313&lang=zh_CN#rd"
        }
      ]
    };
  },
  head() {
    return {
      title: "大数据_数字化转型_数据中台案例_智能商情_奇点云",
      meta: [
        {
          name: "keywords",
          content: "大数据，数字化转型，数据中台案例，智能商情，奇点云"
        },
        {
          name: "description",
          content:
            "如何运用大数据实现智能商情？一站式大数据智能服务平台，旨在为企业提供数字化转型、数据中台建设及解决方案，拥有大量数据中台案例。依托奇点云的大数据和AI能力，助力企业实时获取一手商情。"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.business-container {
//   padding-top: 68px;
  min-width: 1280px;
  .swipe-box {
    background: url("~@/assets/images/businessConditions/bg.jpg") center 0;
    background-size: auto 100%;
  }
  .business-system {
    background: url("~@/assets/images/businessConditions/system-ban.jpg") center
      0;
    background-size: auto 100%;
    height: 764px;
    padding-top: 120px;
    .desc {
      padding-top: 56px;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      color: #151515;
      line-height: 32px;
      letter-spacing: 1px;
    }
  }
  .business-advantage {
    background: url("~@/assets/images/businessConditions/power-bg.png") center 0;
    background-size: auto 100%;
    height: 1120px;
    .business-title {
      color: #ffffff;
      padding: 110px 0 60px 0;
    }
    ul {
      li {
        display: flex;
        align-items: center;
        height: 160px;
        background: linear-gradient(
          90deg,
          rgba(187, 73, 250, 0.04) 0%,
          rgba(29, 85, 207, 0.5) 100%
        );
        border-radius: 6px;
        border-left: none;
        margin-bottom: 30px;
        .icon {
          padding: 0 66px 0 60px;
          img {
            width: 120px;
          }
        }
        .info {
          flex: 1;
          .title {
            font-size: 26px;
            font-weight: 600;
            color: #ffffff;
            letter-spacing: 1px;
            padding-bottom: 13px;
          }
          .desc {
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            line-height: 26px;
            opacity: 0.8;
          }
        }
      }
    }
  }
  .business-logic {
    padding: 120px 0;
    .business-title {
      padding-bottom: 70px;
    }
    .logic-content {
      position: relative;
      > img {
        width: 100%;
      }
      .logic-layer {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        .logic-cell {
          margin: 0 40px 58px 40px;
          height: 150px;
          padding-top: 20px;
          .logic-header {
            display: flex;
            align-items: center;
            text-align: center;
            height: 72px;
            padding-right: 65px;
            .name {
              width: 380px;
              font-size: 24px;
              font-weight: 600;
              color: #3cbdcb;
              background: linear-gradient(180deg, #45d8e8 0%, #005485 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              padding-right: 60px;
            }
            .list {
              display: flex;
              flex: 1;
              align-items: center;
              margin: 0 -12px;
              .item {
                flex: 1;
                text-align: center;
                font-size: 18px;
                font-weight: 500;
                color: #ffffff;
                margin: 0 12px;
                height: 44px;
                line-height: 44px;
                background: #3cbdcb;
                border-radius: 2px;
              }
            }
          }
          .logic-body {
            font-size: 16px;
            font-weight: 400;
            color: #3cbdcb;
            line-height: 24px;
            display: flex;
            padding-top: 15px;
            .label {
              padding-left: 120px;
              font-weight: 600;
            }
          }
          &:nth-child(2) {
            .logic-header {
              .name {
                background: linear-gradient(180deg, #3e9cff 0%, #0073c5 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              .list {
                .item {
                  background: #3e9cff;
                }
              }
            }
            .logic-body {
              color: #3e9cff;
            }
          }
          &:nth-child(3) {
            .logic-header {
              .name {
                background: linear-gradient(180deg, #687eb0 0%, #0b2662 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              .list {
                .item {
                  background: #3c4f77;
                }
              }
            }
            .logic-body {
              color: #3c4f77;
            }
          }
        }
      }
    }
  }
  .business-successful {
    padding-top: 75px 0 130px 0;
    background: #f7f9fc;
    margin-bottom: -100px;
    .q-title {
      padding-bottom: 50px;
    }
    .energy-content {
      height: 800px;
    }
  }
  @media screen and (max-width: 1440px) {
    .business-system {
      height: 580px;
      padding-top: 80px;
      .desc {
        padding: 34px;
        font-size: 14px;
        line-height: 28px;
      }
    }
    .business-advantage {
      height: 860px;
      .business-title {
        padding: 80px 0 40px 0;
      }
      ul li {
        height: 120px;
        margin-bottom: 20px;
        .icon {
          padding: 0 46px 0 40px;
          img {
            width: 80px;
          }
        }
        .info {
          .title {
            font-size: 20px;
            padding-bottom: 6px;
          }
          .desc {
            font-size: 12px;
          }
        }
      }
    }
    .business-logic {
      padding: 80px 0;
      .business-title {
        padding-bottom: 46px;
      }
      .logic-content {
        .logic-layer {
          .logic-cell {
            margin: 0 31px 44px 31px;
            height: 118px;
            padding-top: 15px;
            .logic-header {
              height: 57px;
              padding-right: 45px;
              .name {
                width: 296px;
                padding-right: 40px;
                font-size: 18px;
              }
              .list {
                margin: 0 -8px;
                .item {
                  height: 36px;
                  line-height: 36px;
                  font-size: 14px;
                  margin: 0 8px;
                }
              }
            }
            .logic-body {
              padding-top: 8px;
              font-size: 12px;
              line-height: 20px;
              .label {
                padding-left: 90px;
              }
            }
          }
        }
      }
    }
    .business-successful {
      padding: 58px 0 84px 0;
      .q-title {
        padding-bottom: 32px;
      }
      .energy-content {
        height: 480px;
      }
      // .energy-banner {
      //   height: 600px;
      //   padding-top: 120px;
      // }
    }
  }
}
</style>

