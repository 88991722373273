<template>
  <div class="swipe-box">
    <div class="common-wrap">
      <div class="title">
        {{ title }}
      </div>
      <div
        class="desc"
        v-html="desc"
      />
      <!-- <a
        class="consult-btn"
        @click="showCode"
      >{{ btn }}</a> -->
    </div>
    <el-dialog
      width="400px"
      title="咨询方式"
      class="dialog"
      :visible.sync="dialogTableVisible"
    >
      <p class="tip"> 
        请扫描微信二维码
      </p>
      <img
        src="../../../../assets/image/contactCode.png"
        alt=""
        class="qrcode"
      >
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    desc: String,
    btn: String
  },
  data(){
      return {
        dialogTableVisible:false
      }
  },
  methods:{
      showCode(){
          this.dialogTableVisible = true
      }
  }
};
</script>


<style lang="scss" scoped>
.dialog{
    text-align: center;
}
.tip{
    margin-bottom: 20px;
}
.qrcode{
    width: 200px;
    height: 200px;
}
.swipe-box {
  height: 740px;
  .title {
    padding-top: 228px;
    font-size: 50px;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 1px;
    padding-bottom: 30px;
  }
  .desc {
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    padding-bottom: 112px;
    line-height: 32px;
  }
  .consult-btn {
      cursor: pointer;
    display: block;
    width: 140px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #07b4d4;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
  }
}
@media screen and (max-width: 1440px) {
  .swipe-box {
    height: 560px;
    .title {
      padding-top: 160px;
    }
    .desc {
      font-size: 16px;
      padding-bottom: 80px;
    }
  }
}
</style>