<template>
  <div class="cockpit-container">
    <swipe
      title="智能管理驾驶舱"
      desc="数据赋能企业管理，经营动态随时掌握"
      btn="产品咨询"
    />
    <div class="cockpit-introduce">
      <div class="common-wrap">
        <Title>智能管理驾驶舱</Title>
        <div class="desc">
          智能管理驾驶舱，为管理者提供数据决策支持的一站式管理信息中心系统。从管理者的决策环境、企
          <br>业管理综合指标的定义以及信息的表述，提供详尽的指标体系，实时反映企业运行状态。帮助企业沉
          <br>淀数据资产，深度挖掘数据价值，用数据赋能经营管理，为商业决策提供数据支持。
        </div>
      </div>
    </div>
    <div class="common-wrap">
      <div class="cockpit-advantage">
        <Title>产品优势</Title>
        <div class="advantage-list">
          <div class="advantage-item">
            <img src="~@/assets/images/products/cockpit/icon01.png">
            <div class="title">
              全面
            </div>
            <div class="content">
              全面整合、升级各部门及人员的零散
              <br>BI报表系统；深度数据治理，统一数
              <br>据管理、业务视角和数据口径。
            </div>
          </div>
          <div class="advantage-item">
            <img src="~@/assets/images/products/cockpit/icon02.png">
            <div class="title">
              高效
            </div>
            <div class="content">
              有效提升数据报表速度，满足实时、
              <br>准实时、T+1级需求。
            </div>
          </div>
          <div class="advantage-item">
            <img src="~@/assets/images/products/cockpit/icon03.png">
            <div class="title">
              智能
            </div>
            <div class="content">
              业务监测、分析和诊断平台具备自动
              <br>化、多维度、可逐层下钻的能力，深
              <br>度洞察运营数据，提供决策参考。
            </div>
          </div>
          <div class="advantage-item">
            <img src="~@/assets/images/products/cockpit/icon04.png">
            <div class="title">
              灵活
            </div>
            <div class="content">
              支持访问多种数据源，快速创建连
              <br>接；不同业务部门及管理层级的关注
              <br>指标和管理权限可灵活配置。
            </div>
          </div>
          <div class="advantage-item">
            <img src="~@/assets/images/products/cockpit/icon05.png">
            <div class="title">
              易用
            </div>
            <div class="content">
              提供丰富的web数据可视化组件、拖
              <br>拽式仪表版，按需自有搭配图表；移
              <br>动端、PC端均可使用。
            </div>
          </div>
          <div class="advantage-item">
            <img src="~@/assets/images/products/cockpit/icon06.png">
            <div class="title">
              安全
            </div>
            <div class="content">
              严密的权限管理系统和有力的安全防
              <br>护服务，保护数据资产安全。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cockpit-function">
      <div class="common-wrap">
        <Title>核心功能</Title>
        <div class="function-step">
          <div class="solid" />
          <div class="step">
            <img
              class="icon"
              src="~@/assets/images/products/cockpit/step01.png"
            >
            <div class="content">
              支持分层级多角度驾驶舱
              <br>KPI关键指标数据汇总、
              <br>分析显示。
            </div>
          </div>
          <div class="step">
            <img
              class="icon"
              src="~@/assets/images/products/cockpit/step02.png"
            >
            <div class="content">
              支持KPI指标模型智能构
              <br>建，以及OLAP多维分析
              <br>功能，迅速帮助企业洞察
              <br>数据背后的问题。
            </div>
          </div>
          <div class="step">
            <img
              class="icon"
              src="~@/assets/images/products/cockpit/step03.png"
            >
            <div class="content">
              支持众多的图表类型和样
              <br>式，可打造生动、炫酷的可
              <br>视化效果，更易懂、有冲击
              <br>力地展示数据。
            </div>
          </div>
          <div class="step">
            <img
              class="icon"
              src="~@/assets/images/products/cockpit/step04.png"
            >
            <div class="content">
              与数据中台产品打通，能实
              <br>现数据同步、数据研发、数
              <br>据质量、数据服务。
            </div>
          </div>
          <div class="step">
            <img
              class="icon"
              src="~@/assets/images/products/cockpit/step05.png"
            >
            <div class="content">
              支持构建集团、分区、门
              <br>店、会员等多维度分析业务
              <br>主题库。
            </div>
          </div>
        </div>
        <div class="pic">
          <img src="~@/assets/images/products/cockpit/function.png">
          <div class="hide-content">
            <div>企业介绍</div>
            <div>法定代表人：张拿咖</div>
          </div>
        </div>
      </div>
    </div>
    <div class="cockpit-framework">
      <div class="common-wrap">
        <Title>产品架构</Title>
        <div class="framework-cell framework-cell-01">
          <div class="label">
            数据应用层
          </div>
          <div class="content">
            <div class="item">
              运营大屏
            </div>
            <div class="item">
              总经理驾驶舱
            </div>
            <div class="item">
              分区驾驶舱
            </div>
            <div class="item">
              店长驾驶舱
            </div>
            <div class="item">
              商品推荐
            </div>
            <div class="item">
              智能人效
            </div>
            <div class="item">
              其他
            </div>
          </div>
        </div>
        <div class="framework-cell framework-cell-02">
          <div class="label">
            数据服务层
          </div>
          <div class="content">
            <div class="item">
              数据服务 API
            </div>
            <div class="item">
              Apache Phoenix
            </div>
          </div>
        </div>
        <div class="framework-cell framework-cell-03">
          <div class="label">
            数据存储层
          </div>
          <div class="content">
            <div class="item">
              Hybrid DB for Postgresql
              <img
                class="arrow"
                src="~@/assets/images/products/cockpit/arrow01.png"
              >
            </div>
            <div class="item">
              MYSQL
              <img
                class="arrow"
                src="~@/assets/images/products/cockpit/arrow01.png"
              >
            </div>
            <div class="item">
              HBASE
              <img
                class="arrow"
                src="~@/assets/images/products/cockpit/arrow01.png"
              >
            </div>
          </div>
        </div>
        <div class="framework-cell framework-cell-04">
          <div class="label">
            数据计算层
            <div class="logo">
              <img src="~@/assets/images/products/cockpit/simba-logo.png">
            </div>
          </div>
          <div class="content">
            <div class="big-item">
              <div class="name">
                算法开发
              </div>
              <div class="item">
                数据科学
              </div>
            </div>
            <div class="big-item">
              <img
                class="arrow"
                src="~@/assets/images/products/cockpit/arrow01.png"
              >
              <div class="name">
                离线计算
              </div>
              <div class="item">
                Hive / spark
              </div>
            </div>
            <div class="big-item">
              <div class="name">
                实时计算
              </div>
              <div class="item">
                Flink
              </div>
            </div>
          </div>
        </div>
        <div class="framework-cell framework-cell-05">
          <div class="label">
            数据同步
          </div>
          <div class="content">
            <div class="big-item big-item-2">
              <div class="item">
                Data - X（ 离线 / 准实时 ）
                <img
                  class="arrow"
                  style="top: -20px"
                  src="~@/assets/images/products/cockpit/arrow02.png"
                >
              </div>
              <div class="item">
                Data - S（ 实时 ）
              </div>
            </div>
            <div class="big-item last-big-item">
              <img
                class="arrow"
                style="top: -10px"
                src="~@/assets/images/products/cockpit/arrow02.png"
              >
              <div class="name">
                消息中间件（实时）
              </div>
              <div class="item">
                Kafka
              </div>
            </div>
          </div>
        </div>
        <div class="framework-cell framework-cell-06">
          <div class="label">
            数据源
          </div>
          <div class="content">
            <div class="item">
              文件/FTP/OTH
              <img
                class="arrow"
                src="~@/assets/images/products/cockpit/arrow03.png"
              >
            </div>
            <div class="item">
              Oracle
              <img
                class="arrow"
                src="~@/assets/images/products/cockpit/arrow03.png"
              >
            </div>
            <div class="item">
              RDS
              <img
                class="arrow"
                src="~@/assets/images/products/cockpit/arrow03.png"
              >
            </div>
            <div class="item">
              DRDS
              <img
                class="arrow"
                src="~@/assets/images/products/cockpit/arrow03.png"
              >
            </div>
            <div class="item last-big-item">
              应用( 双写 )
              <img
                class="arrow"
                src="~@/assets/images/products/cockpit/arrow03.png"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="business-successful">
      <div class="common-wrap">
        <Title>客户成功</Title>
        <energy :energy-data="energyData" />
      </div>
    </div>
  </div>
</template>

<script>
import Title from "./components/Title/index";
import Swipe from "./components/Swipe/index";
import energy from "./components/energy/index";

import zhuoshiniLogoHover from "~/assets/images/customerLogo/kuaiyufushi-hover.png";

export default {
  components: {
    Title,
    Swipe,
    energy
  },
  transition: "bounce",
  data() {
    return {
      energyData: [
        {
          name: "快鱼服饰",
          logoHover: zhuoshiniLogoHover,
          banner: require("~/assets/images/customerLogo/kuaiyufushiBanner.png"),
          des:
            "为该零售服饰集团搭建智能管理驾驶舱，设置了3级管理人员账户权限，覆盖2000+门<br/>店、10+区域大仓、20+个子类目，实现>600个动态经营数据指标实时监测，数据准确<br/>率达100%。<br/>在此基础上，各类业务单据输出速度由原来的10~30分钟进阶为秒级，各类业务报表输<br/>出速度由原来的30~60分钟提升为3~5分钟，各类经营管理类报表T+1每天准点自动呈<br/>现，新报表需求开发速度从原来一周以上缩短到1~2天。"
        }
      ]
    };
  },
  head() {
    return {
      title: "数据治理_数据可视化_数据资产管理_智能管理驾驶舱_奇点云",
      meta: [
        {
          name: "keywords",
          content: "数据治理，数据可视化，数据资产管理，智能管理驾驶舱，奇点云"
        },
        {
          name: "description",
          content:
            "奇点云智能管理驾驶舱，为管理者提供数据决策支持的一站式管理信息中心系统。帮助企业沉淀数据资产，呈现数据可视化，深度挖掘数据价值，实现数据治理，为商业决策提供数据支持。"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.cockpit-container {
//   padding-top: 68px;
  min-width: 1280px;
  .swipe-box {
    background: url("~@/assets/images/products/cockpit/swipe.png") center 0;
    background-size: auto 100%;
  }
  .cockpit-introduce {
    padding-top: 100px;
    .common-wrap {
      background: url("~@/assets/images/products/cockpit/cockpit-bg.png") center
        0;
      background-size: auto 100%;
      height: 580px;
      text-align: center;
      .q-title {
        padding: 142px 0 48px 0;
        color: #ffffff;
      }
      .desc {
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        letter-spacing: 1px;
      }
    }
  }
  .cockpit-advantage {
    padding-top: 120px;
    .advantage-list {
      display: flex;
      flex-wrap: wrap;
      padding: 80px 0 40px 0;
      .advantage-item {
        width: 33.3%;
        text-align: center;
        padding-bottom: 80px;
        img {
          width: 84px;
        }
        .title {
          padding: 12px 0;
          font-size: 26px;
          font-weight: 600;
          color: #151515;
        }
        .content {
          font-size: 16px;
          font-weight: 400;
          color: #151515;
          line-height: 34px;
        }
      }
    }
  }
  .cockpit-function {
    background: url("~@/assets/images/products/cockpit/function-bg.png") center
      0;
    background-size: auto 100%;
    height: 1188px;
    .q-title {
      font-size: 46px;
      font-weight: 600;
      color: #ffffff;
      padding: 100px 0 40px 0;
    }
    .function-step {
      display: flex;
      justify-content: space-between;
      position: relative;
      .step {
        position: relative;
        z-index: 2;
        .icon {
          height: 24px;
        }
        .content {
          color: #ffffff;
          font-size: 16px;
          font-weight: 400;
          line-height: 32px;
        }
      }
      .solid {
        background: #ffffff;
        position: absolute;
        top: 13px;
        left: 0;
        right: 0;
        opacity: 0.1;
        height: 1px;
        z-index: 1;
      }
    }
    .pic {
      margin: 0 -50px;
      img {
        width: 100%;
      }
    }
  }
  .cockpit-framework {
    padding: 122px 0 120px 0;
    .q-title {
      padding-bottom: 70px;
    }
    .framework-cell {
      display: flex;
      align-items: center;
      background: linear-gradient(
        270deg,
        rgba(76, 71, 252, 0.06) 0%,
        rgba(76, 71, 252, 0.06) 74%,
        rgba(76, 71, 252, 0) 100%
      );
      height: 82px;
      .label {
        width: 172px;
        font-size: 26px;
        font-weight: 500;
        color: #0f09dc;
        .logo {
          margin-top: 8px;
          font-size: 0;
          img {
            height: 32px;
          }
        }
      }
      .content {
        display: flex;
        flex: 1;
        margin: 0 -12px;
        padding-right: 30px;
        .item {
          height: 46px;
          line-height: 46px;
          background: rgba(15, 9, 220, 0.06);
          border-radius: 1px;
          border: 1px dotted #0f09dc;
          font-size: 19px;
          background: rgba(15, 9, 220, 0.06);
          font-weight: 400;
          color: #0f09dc;
          margin: 0 12px;
          flex: 1;
          text-align: center;
          position: relative;
        }
        .arrow {
          position: absolute;
          left: 50%;
          top: -22px;
          width: 18px;
          transform: translate(-50%, -100%);
        }
        .big-item {
          flex: 1;
          display: flex;
          align-items: center;
          margin: 0 12px;
          height: 68px;
          border: 1px dotted #0f09dc;
          position: relative;
          .name {
            padding-left: 16px;
            font-size: 19px;
            font-weight: 400;
            color: #005ac5;
          }
          .arrow {
            top: -40px;
          }
          .item {
            background: none;
          }
        }
        .big-item-2 {
          flex: 1;
        }
        .last-big-item {
          flex: none;
          width: 343.3px;
        }
      }
    }
    .framework-cell-01 {
      background: linear-gradient(
        270deg,
        rgba(17, 109, 219, 0.06) 0%,
        rgba(17, 109, 219, 0.06) 74%,
        rgba(17, 109, 219, 0) 100%
      );
      .label {
        color: #005ac5;
        width: 165px;
      }
      .content {
        padding-right: 16px;
        .item {
          background: rgba(0, 90, 197, 0.06);
          border: 1px dotted #005ac5;
          color: #005ac5;
          margin: 0 7px;
        }
      }
    }
    .framework-cell-02 {
      margin-bottom: 42px;
      .label {
        padding-right: 0;
      }
      .content {
        margin: 0 -37px;
        .item {
          margin: 0 37px;
        }
      }
    }
    .framework-cell-03 {
      margin-bottom: 42px;
    }
    .framework-cell-04 {
      margin-bottom: 32px;
      height: 137px;
    }
    .framework-cell-05 {
      background: linear-gradient(
        270deg,
        rgba(17, 109, 219, 0.06) 0%,
        rgba(17, 109, 219, 0.06) 74%,
        rgba(17, 109, 219, 0) 100%
      );
      margin-bottom: 32px;
      height: 110px;
      .label {
        color: #005ac5;
      }
      .content {
        .big-item {
          background: rgba(0, 90, 197, 0.06);
          border: 1px dotted #005ac5;
        }
        .item {
          border: 1px dotted #005ac5;
          color: #005ac5;
        }
      }
    }
    .framework-cell-06 {
      background: linear-gradient(
        270deg,
        rgba(0, 155, 195, 0.06) 0%,
        rgba(0, 155, 195, 0.06) 74%,
        rgba(0, 155, 195, 0) 100%
      );
      .label {
        color: #008caf;
      }
      .content {
        .item {
          background: rgba(0, 155, 195, 0.1);
          border: 1px dotted #008caf;
          color: #008caf;
        }
        .arrow {
          top: -8px;
        }
      }
    }
  }
  .business-successful {
    background: #f7f9fc;
    padding: 75px 0 130px 0;
    margin-bottom: -100px;
    .q-title {
      padding-bottom: 50px;
    }
    .energy-content {
      height: 800px;
    }
  }
  @media screen and (max-width: 1440px) {
    .cockpit-introduce {
      padding-top: 50px;
      .common-wrap {
        height: 454px;
        .q-title {
          padding: 92px 0 32px 0;
        }
        .desc {
          font-size: 16px;
          line-height: 32px;
        }
      }
    }
    .cockpit-advantage {
      padding-top: 70px;
      .advantage-list {
        padding: 50px 0 30px 0;
        .advantage-item {
          padding-bottom: 60px;
          img {
            width: 70px;
          }
          .title {
            padding: 8px 0;
            font-size: 22px;
          }
          .content {
            font-size: 14px;
            line-height: 28px;
          }
        }
      }
    }
    .cockpit-function {
      height: 940px;
      .q-title {
        padding: 70px 0 30px 0;
      }
      .function-step {
        .step {
          .icon {
            height: 20px;
          }
          .content {
            font-size: 14px;
            line-height: 28px;
          }
        }
        .solid {
          top: 11px;
        }
      }
      .pic {
        margin: 0 -40px;
      }
    }
    .cockpit-framework {
      padding: 82px 0 76px 0;
      .q-title {
        padding-bottom: 48px;
      }
      .framework-cell {
        height: 66px;
        margin-bottom: 30px;
        .label {
          font-size: 22px;
          width: 140px;
          .logo {
            margin-top: 4px;
            img {
              height: 28px;
            }
          }
        }
        .content {
          .item {
            height: 40px;
            line-height: 40px;
            font-size: 14px;
          }
          .last-big-item {
            width: 260px;
          }
          .arrow {
            width: 12px;
            top: -18px;
          }
          .big-item {
            .arrow {
              top: -24px;
            }
            .name {
              font-size: 16px;
            }
          }
        }
      }
      .framework-cell-01 {
        margin-bottom: 0;
      }
      .framework-cell-04 {
        height: 106px;
      }
      .framework-cell-05 {
        height: 96px;
      }
      .framework-cell-06 {
        margin-bottom: 0;
        .content .arrow {
          top: -11px;
        }
      }
    }
    .business-successful {
      padding: 58px 0 84px 0;
      .q-title {
        padding-bottom: 32px;
      }
      .energy-content {
        height: 480px;
      }
    }
  }
}
</style>