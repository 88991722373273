<template>
  <div class="simba-container">
    <div class="simba-swipe">
      <div class="common-wrap">
        <div class="title">
          DataSimba
        </div>
        <div class="desc">
          如何快速搭建安全易用的数据中台，让数据创造价值？
        </div>
        <!-- <a
          class="consult-btn"
          @click="showCode"
        >产品咨询</a> -->
      </div>
    </div>
    <div class="simba-introduce">
      <div class="common-wrap">
        <div class="logo">
          <img src="~@/assets/images/simba/logo.png">
        </div>
        <div class="desc">
          一站式大数据智能服务平台，旨在为企业提供全链路的产品+技术+方法论服务。
          <br>助力企业快速搭建安全、易用的数据中台，最大化释放价值，驱动业务增长与创新。
        </div>
      </div>
    </div>
    <div class="simba-architecture">
      <div class="common-wrap">
        <div class="architecture-header">
          <div class="simba-title">
            平台架构
          </div>
          <div class="sub-title">
            数智化转型的基础设施
          </div>
        </div>
        <div class="architecture-chart">
          <img
            width="100%"
            src="~@/assets/images/simba/graph.png"
          >
          <div class="architecture-layer">
            <div class="architecture-left">
              <div>大数据应用开发</div>
              <div>产品层</div>
              <div>引擎层</div>
              <div>数据存储</div>
              <div>数据源</div>
              <div>基础层</div>
            </div>
            <div class="architecture-center">
              <div>大数据开发套件 （可视化 IDE ）</div>
              <div>
                <dl>
                  <dt>历史数据</dt>
                  <dd>平台规划</dd>
                  <dd>业务规划</dd>
                  <dd>账号管理</dd>
                  <dd>项目管理</dd>
                  <dd>数据源</dd>
                  <dd>角色权限</dd>
                </dl>
                <dl>
                  <dt>研发</dt>
                  <dd>离线开发</dd>
                  <dd>实时开发</dd>
                  <dd>算法开发</dd>
                  <dd>API 开发</dd>
                  <dd>标签开发</dd>
                </dl>
                <dl>
                  <dt>数据运维</dt>
                  <dd>应用发布</dd>
                  <dd>数据质量</dd>
                  <dd>离线运维</dd>
                  <dd>实时运维</dd>
                  <dd>算法运维</dd>
                  <dd>API 运维</dd>
                  <dd>标签运维</dd>
                </dl>
                <dl>
                  <dt>数据服务</dt>
                  <dd>数据集成</dd>
                  <dd>API 市场</dd>
                  <dd>模型部署</dd>
                </dl>
                <dl>
                  <dt>数据资产</dt>
                  <dd>数据概览</dd>
                  <dd>数据地图</dd>
                  <dd>资产地图</dd>
                  <dd>数据治理</dd>
                  <dd>自助分析</dd>
                </dl>
              </div>
              <div>
                <div>离线计算 Hive</div>
                <div>实时计算 Flink</div>
                <div>内存计算 Spark</div>
                <div>交互式查询 Impala</div>
              </div>
              <div>
                <div>分布式存储系统 HDFS</div>
                <div>消息中间件 Kafka</div>
              </div>
              <div>
                <div>全量 / 非实时接入 （ DataX ）</div>
                <div>增量 / 实时接入 （ DataS ）</div>
              </div>
              <div>
                <div>阿里云</div>
                <div>华为云</div>
                <div>电信云</div>
                <div>腾讯云</div>
                <div>本地机房</div>
              </div>
            </div>
            <div class="architecture-right">
              <div>基础服务</div>
              <div>集群部署</div>
              <div>运维监控</div>
              <div>分布式调控系统</div>
              <div>多租户管理</div>
              <div>权限认证</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="simba-ability">
      <div class="simba-title">
        核心能力
      </div>
      <div class="ability-layer">
        <div>分析引擎</div>
        <div>
          提供可视化 IDE 分析界面，秒 级大批量的数据聚合查询技术，
          提升数据分析效率，快速支持 高层决策。
        </div>
        <div>数据科学</div>
        <div>提供算法开发平台，支持可视 化建模和 Notebook 编程两种 开发模式。</div>
        <div>离线计算</div>
        <div>基于 Hive / Spark 离线数据 开发平台</div>
        <div>标签工厂</div>
        <div>
          提供全域核心标签统计、标签 分布情况、标签使用情况查看
          功能，通过实体、关系和表的 关联来生产标签。
        </div>
        <div>实时计算</div>
        <div>基于 Flink 的 SQL 式流计算 开发平台</div>
        <div>数据质量</div>
        <div>
          可视化配置数据质量监控表、 字段，内置 20 多种统计函
          数，实时监控数据质量。
        </div>
        <div>数据 API</div>
        <div>提供可视化配置的方式进行 API 的低成本开发。</div>
        <div>数据资产</div>
        <div>提供全域的数据资产管理，支 持查询数据资产分布、调用以 及关联关系。</div>
        <div>数据分析</div>
        <div>算法开发</div>
        <div>数据开发</div>
        <div>数据分析</div>
        <div>数据开发</div>
        <div>数据管理</div>
        <div>业主方</div>
        <div>决策层</div>
        <div>决策层</div>
      </div>
    </div>
    <div class="simba-application">
      <div class="simba-title">
        应用场景
      </div>
      <div class="common-wrap">
        <div class="application-flex">
          <div class="application-item">
            <img
              class="icon"
              src="~@/assets/images/simba/application-icon01.png"
            >
            <div class="label">
              企业数据中台建设
            </div>
            <div class="value">
              集成-开发-数据服务，一站式完成
            </div>
          </div>
          <div class="application-item">
            <img
              class="icon"
              src="~@/assets/images/simba/application-icon02.png"
            >
            <div class="label">
              大数据计算及分析
            </div>
            <div class="value">
              利用集群及强大的计算引擎，完成海量数据分析
            </div>
          </div>
          <div class="application-item">
            <img
              class="icon"
              src="~@/assets/images/simba/application-icon03.png"
            >
            <div class="label">
              数据业务化创新
            </div>
            <div class="value">
              集成全域数据，利用算法模型快速构建
              <br>数据智能应用
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="simba-advantage">
      <div class="common-wrap">
        <div class="simba-title">
          产品优势
        </div>
        <ul>
          <li>
            <div class="icon">
              <img src="~@/assets/images/simba/icon01.png">
            </div>
            <div class="info">
              <div class="title">
                轻量级部署，低成本投入
              </div>
              <div class="desc">
                极速部署，即开即用，管控节点 docker 化，计算节点虚拟化；最小化 4
                台虚拟机，8C+16G 内存配置即可低成本上线。
              </div>
            </div>
          </li>
          <li>
            <div class="icon">
              <img src="~@/assets/images/simba/icon02.png">
            </div>
            <div class="info">
              <div class="title">
                多源数据适配，非入侵式数据集成
              </div>
              <div class="desc">
                适配主流关系型、非关系型数据源，快速构建数据同步作业；非入侵式数据集成，基于
                CDC 技术实时捕获增量日志变化，
                <br>对业务端零干扰。
              </div>
            </div>
          </li>
          <li>
            <div class="icon">
              <img src="~@/assets/images/simba/icon03.png">
            </div>
            <div class="info">
              <div class="title">
                简单易用低门槛，多人协作高效率
              </div>
              <div class="desc">
                集成大数据核心组件，提供智能开发 IDE，通过简单的 SQL
                编译即可让企业拥有大数据能力；多人协作管理、开发，大大
                <br>提高开发效率，有效缩短开发周期。
              </div>
            </div>
          </li>
          <li>
            <div class="icon">
              <img src="~@/assets/images/simba/icon04.png">
            </div>
            <div class="info">
              <div class="title">
                快速规范建模，高效稳定调度
              </div>
              <div class="desc">
                提供规范的数据分层模型、加工及服务能力，保障建模流程规范可控；用户可自定义数据处理逻辑，作业可实现定时、依赖
                <br>方式的自动调度，保障作业定时保质产出。
              </div>
            </div>
          </li>
          <li>
            <div class="icon">
              <img src="~@/assets/images/simba/icon05.png">
            </div>
            <div class="info">
              <div class="title">
                一站式数据开发，系统覆盖全链路
              </div>
              <div class="desc">
                数据开发在线化、可视化，从开发、调试、发布到监控运维，覆盖数据开发全链路，一站到底，流畅简便。
              </div>
            </div>
          </li>
          <li>
            <div class="icon">
              <img src="~@/assets/images/simba/icon06.png">
            </div>
            <div class="info">
              <div class="title">
                保障数据安全，数据自主权在握
              </div>
              <div class="desc">
                支持私有化部署及混合部署，用户无需将数据传输到外部环境，彻底保障数据自主权。同时支持数据权限控制、数据脱敏、
                <br>实时审计、高危风险告警，确保数据绝对安全。
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="simba-successful">
      <div class="common-wrap">
        <div class="simba-title">
          客户成功
        </div>
        <energy :energy-data="energyData" />
      </div>
    </div>
    <el-dialog
      width="400px"
      title="咨询方式"
      class="dialog"
      :visible.sync="dialogTableVisible"
    >
      <p class="tip"> 
        请扫描微信二维码
      </p>
      <img
        src="~@/assets/image/contactCode.png"
        alt=""
        class="qrcode"
      >
    </el-dialog>
  </div>
</template>

<script>
import energy from "./components/energy/index.vue";
import simba from "~/assets/dictionary/simba.js";
import zhuoshiniBanner from "~/assets/images/shouye/zhuoshiniBanner.png";
import zhuoshiniLogo from "~/assets/images/customerLogo/zhuoshini.png";
import zhuoshiniLogoHover from "~/assets/images/customerLogo/zhuoshini-hover.png";
import kejichengBanner from "~/assets/images/shouye/kejichengBanner.png";
import kejichengLogo from "~/assets/images/customerLogo/kejicheng.png";
import kejichengLogoHover from "~/assets/images/customerLogo/kejicheng-hover.png";

export default {
  components: {
    energy
  },
  data () {
    return {
      dialogTableVisible:false,
      ...simba,
      energyData: [
        {
          name: "Lululemon",
          banner: require("~/assets/images/simba/lululemon-bg.png"),
          logo: require("~/assets/images/customerLogo/lululemon.png"),
          logoHover: require("~/assets/images/customerLogo/lululemon_hover.png"),
          des:
            "依托DataSimba，完成数据集成、指标研发及离线计算。对不同来源的数据进行口径统<br/>一、流程规范和整理加工，通过数据服务便利地提供给上层应用。采集、加工数据的流<br/>程更加规范，数据加工效率大幅提升。<br/>企业可在次日及时了解前一天的营收情况，便于快速调整运营策略，用数据驱动业务。"
          // url:
          //   "https://mp.weixin.qq.com/s?__biz=MzIzMzczNjgyNQ==&mid=2247487195&idx=1&sn=239fcd51c0ac8a9efbe5b423eb187d17&chksm=e88051cddff7d8db153df66b3d3091032db59f3a711541667d3ebcf0ed8bb2314c0937ffe6d9&token=61507205&lang=zh_CN#rd",
        },
        {
          name: "上海电信",
          banner: require("~/assets/images/simba/bannerdian.png"),
          logo: require("~/assets/images/simba/logodian.png"),
          logoHover: require("~/assets/images/simba/dian_hover.png"),
          des:
            "依托DataSimba，上海电信打通多个系统，联结复杂场景，沉淀数据资产；搭建前端数<br/>据应用中台，实现能力的高水平复用；建立大数据标签体系，真正「以人为中心」来考<br/>量商品企划、营销和服务，用数据赋能商品与消费者运营。"
          // url:
          //   "https://mp.weixin.qq.com/s?__biz=MzIzMzczNjgyNQ==&mid=2247487195&idx=1&sn=239fcd51c0ac8a9efbe5b423eb187d17&chksm=e88051cddff7d8db153df66b3d3091032db59f3a711541667d3ebcf0ed8bb2314c0937ffe6d9&token=61507205&lang=zh_CN#rd",
        },
        {
          name: "卓诗尼",
          banner: zhuoshiniBanner,
          logo: zhuoshiniLogo,
          logoHover: zhuoshiniLogoHover,
          des:
            "依托DataSimba，整合POS、CRM、SAP、线上商城、老CRM、老商城等多个系统及历史备<br/>份数据，搭建数据模型，形成完整的会员全域数据库，并为会员标签体系做好充分的数据准备。<br/>配合DataNuza，实现更精准的活动投放及更低的市场费用，同时票券、营销活动的触达转化<br/>率不断刷新历史记录，三个月内触达转化率就从1%左右提升到了3%-6%，最高到了8%。",
          url:
            "https://mp.weixin.qq.com/s?__biz=MzIzMzczNjgyNQ==&mid=2247486443&idx=1&sn=4e5dd88834488edb8818b972a9a623b2&chksm=e88054fddff7ddeb4225d2fd1ca38fa358c10933d1a0f251238deba5093c79743a00dfb727a6&token=1116684313&lang=zh_CN#rd"
        },
        {
          name: "杭州未来科技城",
          banner: kejichengBanner,
          logo: kejichengLogo,
          logoHover: kejichengLogoHover,
          des:
            "作为智慧政务的先行者，杭州未来科技城2017年启动了「未来科技城企业数据大脑」，重构了大数据时代的政企<br/>关系，探索出了符合数字经济时代的政府服务企业的新路径。<br/>“企业数据大脑”是政务大数据平台，以DataSimba为底座，打破了各部门单位间的数据壁垒，融合<br/>了辖区内多源企业数据，实现地区经济发展的多维分析、智能判断、趋势预测等。其中，“1+N”建设模式（1个共<br/>建共享的大脑，叠加N个部门、N个场景的业务应用）让未来科技城快速响应需求，快速推出了企业复工平台、企<br/>业服务新干线等智能应用，让企业服务更加精准及时、人性合理。",
          url: "https://mp.weixin.qq.com/s/ZvGAFo0cHpn6Cut8Je6UzA"
        }
      ]
    };
  },
  methods:{
      showCode(){
          this.dialogTableVisible = true
      },
  },
  head () {
    return {
      title: "大数据_数据中台建设_数据智能应用_数据中台解决方案_奇点云",
      meta: [
        {
          name: "keywords",
          content: "数字化转型，数据中台建设，数据中台解决方案，奇点云"
        },
        {
          name: "description",
          content:
            "如何快速搭建安全易用的数据中台，让数据创造价值？一站式大数据智能服务平台，旨在为企业提供数字化转型、数据中台建设及解决方案。助力企业快速搭建安全、易用的数据中台，最大化释放价值，驱动业务增长与创新。"
        }
      ]
    };
  },
};
</script>

<style lang="scss" scoped>
.dialog{
    text-align: center;
}
.tip{
    margin-bottom: 20px;
}

.qrcode{
    width: 200px;
    height: 200px;
}
.simba-container {
  width: 100%;
//   padding-top: 68px;
  .simba-title {
    font-size: 46px;
    font-weight: 600;
    line-height: 65px;
    letter-spacing: 2px;
    text-align: center;
  }
  .simba-swipe {
    background: url("~@/assets/images/simba/banner.png") center 0;
    background-size: auto 100%;
    height: 740px;
    .title {
      padding-top: 228px;
      font-size: 50px;
      font-weight: 600;
      color: #ffffff;
      letter-spacing: 1px;
      padding-bottom: 30px;
    }
    .desc {
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      padding-bottom: 112px;
      line-height: 32px;
    }
    .consult-btn {
      display: block;
      width: 140px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #07b4d4;
      border-radius: 2px;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .simba-introduce {
    padding-top: 100px;
    .common-wrap {
      background: url("~@/assets/images/simba/simba-ban.png") center 0;
      background-size: auto 100%;
      height: 580px;
      text-align: center;
      .logo {
        padding: 204px 0 40px 0;
        img {
          height: 60px;
        }
      }
      .desc {
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        letter-spacing: 1px;
      }
    }
  }
  .simba-architecture {
    padding-bottom: 120px;
    .architecture-header {
      text-align: center;
      padding: 87px 0 36px 0;
      position: relative;
      .sub-title {
        font-size: 28px;
        font-weight: 600;
        color: #1868ee;
        letter-spacing: 1px;
      }
      // &::after{
      //   content: '';
      //   position: absolute;
      //   width: 0;
      //   height: 0;
      //   border-bottom: 300px solid #3C4C59;
      //   border-left: 600px solid transparent;
      //   border-right: 600px solid transparent;
      //   top: -54px;
      //   left: 50%;
      //   transform: translateX(-50%);
      //   z-index: -1;
      // }
    }
    .architecture-chart {
      position: relative;
      .architecture-layer {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        text-align: center;
        .architecture-left {
          width: 242px;
          padding-top: 44px;
        }
        .architecture-center {
          flex: 1;
        }
        .architecture-right {
          width: 240px;
        }
      }
    }
  }
  .simba-ability {
    background: url("~@/assets/images/simba/ability.jpg") center 0;
    background-size: auto 100%;
    height: 1300px;
    .simba-title {
      color: #ffffff;
      padding: 80px 0 20px 0;
    }
    .ability-layer {
      position: relative;
      display: none;
    }
  }
  .simba-application {
    padding: 100px 0 136px 0;
    background: #f7f9fc;
    .simba-title {
      padding-bottom: 70px;
    }
    .application-flex {
      display: flex;
      margin: 0 -12px;
      .application-item {
        flex: 1;
        margin: 0 12px;
        text-align: center;
        padding-top: 50px;
        height: 308px;
        background: #ffffff;
        border-radius: 6px;
        transition: all 1s;
        color: #151515;
        .icon {
          height: 102px;
        }
        .label {
          font-size: 26px;
          font-weight: 600;
          letter-spacing: 1px;
          padding: 20px 0 10px 0;
        }
        .value {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
        }
        &:hover {
          transition: all 1s;
          box-shadow: rgba(0, 0, 0, 0.08) 0 22px 36px 14px;
        }
      }
    }
  }
  .simba-advantage {
    background: url("~@/assets/images/simba/advantage-ban.png") center 0;
    background-size: auto 100%;
    height: 1500px;
    .simba-title {
      padding: 110px 0 60px 0;
      color: #ffffff;
    }
    ul {
      li {
        display: flex;
        align-items: center;
        height: 160px;
        background: linear-gradient(
          90deg,
          rgba(187, 73, 250, 0.04) 0%,
          rgba(29, 85, 207, 0.5) 100%
        );
        border-radius: 6px;
        border-left: none;
        margin-bottom: 30px;
        .icon {
          padding: 0 66px 0 60px;
          img {
            width: 120px;
          }
        }
        .info {
          flex: 1;
          .title {
            font-size: 26px;
            font-weight: 600;
            color: #ffffff;
            letter-spacing: 1px;
            padding-bottom: 13px;
          }
          .desc {
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            line-height: 26px;
            opacity: 0.8;
          }
        }
      }
    }
  }
  .simba-successful {
    padding: 75px 0 120px 0;
    background: #f7f9fc;
    margin-bottom: -100px;
    .simba-title {
      padding-bottom: 50px;
    }
  }
  @media screen and (max-width: 1440px) {
    .simba-title {
      font-size: 40px;
      line-height: 60px;
    }
    .simba-swipe {
      height: 560px;
      .title {
        padding-top: 170px;
        padding-bottom: 20px;
      }
      .desc {
        font-size: 16px;
        padding-bottom: 80px;
      }
    }
    .simba-introduce {
      padding-top: 50px;
      .common-wrap {
        height: 454px;
        .logo {
          padding: 164px 0 26px 0;
          img {
            height: 40px;
          }
        }
        .desc {
          font-size: 16px;
          line-height: 28px;
        }
      }
    }
    .simba-architecture {
      padding-bottom: 70px;
      .architecture-header {
        padding: 56px 0 16px 0;
        .sub-title {
          font-size: 24px;
        }
      }
    }
    .simba-ability {
      height: 980px;
      .simba-title {
        padding: 60px 0 10px 0;
      }
    }
    .simba-application {
      padding: 60px 0 86px 0;
      .simba-title {
        padding-bottom: 44px;
      }
      .application-flex {
        .application-item {
          padding-top: 30px;
          height: 248px;
          .icon {
            height: 88px;
          }
          .label {
            font-size: 22px;
            padding: 14px 0 6px 0;
          }
          .value {
            font-size: 14px;
          }
        }
      }
    }
    .simba-advantage {
      height: 1100px;
      .simba-title {
        padding: 70px 0 40px 0;
      }
      ul li {
        height: 120px;
        margin-bottom: 20px;
        .icon {
          padding: 0 46px 0 40px;
          img {
            width: 80px;
          }
        }
        .info {
          .title {
            font-size: 20px;
            padding-bottom: 6px;
          }
          .desc {
            font-size: 12px;
          }
        }
      }
    }
    .simba-successful {
      padding: 58px 0 84px 0;
      .simba-title {
        padding-bottom: 32px;
      }
    }
  }
}
</style>

