export default {
    topBriefContent: {
        title: `千人千面 ，精准推荐`,
        subTitle: `智能推荐系统，依托数据中台整合用户、交易、商品、商家等数据，基于数据算法模型，建立用户画像，帮助品牌洞察用户，实现千人千面的精准推荐，有效提升转化效果。`,
        titleStyle: {
            color: '#0475FF',
        },
        playVideo: false,
        videoPath: '',
        rightImg: require("~/assets/images/recommendation/banner.jpg"),
    },
    graphicFirstContent:{
        headline: '产品特点',
        summarize: '',
        descMaxWidth: '278px',
        graphicList:[{
            img: require('~/assets/images/recommendation/feature_1.png'),
            title: '规范标签',
            desc: `基于全域数据，构建数据的规范化、标准化，建立统一的标签体系。`,
            listStyle: {
                marginTop: '70px'
            },
            imgStyle: {
                width: '147px',
                height: '170px',
            }
        },{
            img: require('~/assets/images/recommendation/feature_2.png'),
            title: '精准画像',
            desc: '建立用户分析机制和精准营销的数据业务应用，帮助用户为客户建立标签，实现客户标签管理与客户精准画像。',
            listStyle: {
                marginTop: '80px'
            },
            imgStyle: {
                width: '145px',
                height: '160px',
            }
        },{
            img: require('~/assets/images/recommendation/feature_3.png'),
            title: '千人千面',
            desc: '基于标签体系与客户画像，实现精准的智能推荐，“千人千面”，有效提升连带率与转化率。',
            listStyle: {
                marginTop: '74px'
            },
            imgStyle: {
                width: '151px',
                height: '166px',
            }
        }]
    },
    appData: {
        title: '客户案例',
        bgImg: require('~/assets/images/recommendation/group.png'),
        list: [
            {
            title: '智能推荐',
            subTitle: '基于spark计算引擎调用算法模型，基于用户行为数据，完善用户画像和在线商品的匹配，推荐相关商品。',
            },
            {
            title: '个性化推荐',
            subTitle: '在品牌APP端实现首页、商详页、购物车、订单支付成功页等4个场景的智能推荐，对用户进行实时个性化的推荐，推荐商品千人千面。',
            },
            {
            title: '提升效率',
            subTitle: '应用智能推荐系统后，应用智能推荐引擎后，连带率同比提升21%，推荐商品转化率同比提升48%。',
            }
        ]
    }
}