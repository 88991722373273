<template>
  <div class="q-title">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.q-title {
  font-size: 46px;
  font-weight: 600;
  line-height: 65px;
  letter-spacing: 2px;
  text-align: center;
}
@media screen and (max-width: 1440px) {
  .q-title {
    font-size: 38px;
    line-height: 60px;
  }
}
</style>
