<template>
  <div class="energy-content">
    <div class="energy-content-over">
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          :key="energyContent.name"
          class="energy-banner"
          :style="banner"
        >
          <div>
            <img
              :src="energyContent.logoHover"
              alt=""
            >
            <p>{{ energyContent.name }}</p>
            <div class="des-content">
              <div
                class="des"
                v-html="energyContent.des"
              />
            </div>
          </div>
          <el-button
            v-if="energyContent.url"
            type="primary"
            size="medium"
            @click="toMore(energyContent.url)"
          >
            了解更多
          </el-button>
        </div>
      </transition>
    </div>
    <div
      v-if="energyData.length>1"
      class="energy-list"
    >
      <div
        v-for="(item, index) in energyData"
        :key="item.name"
        :class="index === activeIndex ? 'itemActive' : 'item'"
        @mouseenter="() => toSwitch(item, index)"
      >
        <img
          :src="index === activeIndex ? item.logoHover : item.logo"
          alt=""
        >
        <p>{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    props: {
      energyData: Array,
    },
  data() {
    return {
    activeIndex: 0,
      energyContent: {},
    };
  },
  computed:{
    banner(){
      return this.energyContent.banner ? {
            backgroundImage: 'url(' + this.energyContent.banner + ')',
            backgroundSize: 'cover',
          } : {}
    },
  },
  created(){
      const item = this.energyData[0]
    this.energyContent = {
        name: item.name,
        banner: item.banner,
        logo: item.logo,
        logoHover: item.logoHover,
        des: item.des,
        url: item.url,
    };
  },
  methods: {
    toMore(url) {
      window.open(url);
    },
    toSwitch(item, index) {
      this.energyContent = {
        name: item.name,
        banner: item.banner,
        logo: item.logo,
        logoHover: item.logoHover,
        des: item.des,
        url: item.url,
      };
      this.activeIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.energy-content {
  height: 800px;
  position: relative;
  overflow: hidden;
  .energy-content-over {
    height: 640px;
    width: 100%;
    overflow: hidden;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .energy-banner {
    width: 100%;
    height: 640px;
    text-align: center;
    color: #fff;
    padding: 159px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    img {
      height: 30px;
      margin-bottom: 10px;
    }
    p {
      font-size: 28px;
      font-weight: 600;
    }
      .des-content{
            display: flex;
            justify-content: center;
          }
    .des {
      font-size: 16px;
      margin: 39px auto 0 auto;
      line-height: 28px;
      padding-bottom: 40px;
      text-align: left;
    }
    .el-button--primary {
      background-color: #1ccada;
      border: 1px solid #1ccada;
      border-radius: 0;
      width: 160px;
      height: 40px;
      span {
        font-size: 16px;
      }
      &:hover {
        background-color: #1ccada;
        border: 1px solid #1ccada;
      }
    }
  }
  .energy-list {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 160px;
    display: flex;
    .item {
      flex: 1;
      padding: 42px 0;
      padding-left: 51px;
      background-color: #fff;
      img {
        height: 30px;
      }
      p {
        font-weight: 600;
        color: #151515;
        margin-top: 16px;
        font-size: 20px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .itemActive {
      flex: 1;
      height: 240px;
      padding: 81px 0;
      padding-left: 51px;
      margin-top: -81px;
      background-color: #1868ee;
      border-radius: 6px;
      position: relative;
      img {
        height: 30px;
      }
      p {
        font-weight: 600;
        color: #fff;
        margin-top: 16px;
        font-size: 20px;
      }
      &::after{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: url("~@/assets/images/customerLogo/watermark.png") right bottom no-repeat;
        background-size: auto 100%;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .energy-content {
    height: 600px;
    .energy-content-over {
      height: 480px;
    }
    .energy-banner {
      height: 480px;
      padding: 100px 0;
      .des {
        margin-top: 30px;
        padding-bottom: 26px;
      }
      .el-button--primary {
        width: 120px;
        height: 30px;
        padding: 0;
        line-height: 30px;
        span {
          font-size: 14px;
        }
      }
    }
    .energy-list {
      height: 120px;
      .item {
        padding: 22px 0 22px 38px;
      }
      .itemActive {
        height: 180px;
        margin-top: -60px;
        padding: 50px 0 50px 51px;
      }
    }
  }
}
</style>