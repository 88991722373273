<template>
  <div class="page-container nissin-container">
    <swipe
      title="智能日清"
      desc="更少的库存，更高的毛利"
      btn="产品咨询"
    />
    <System
      :pic="require('~/assets/images/products/nissin/system.png')"
      desc="智能日清，依托数据中台及AI算法能力，通过算法模型，综合多维度因素，分析缺货、断货及促<br/>销因素，在销量预测的基础上，提出销售和补货的策略，帮助生鲜企业、零售商超等实现日清智能化，<br/>提升售罄率，降低缺货率和损耗率，切实提升毛利。"
    >
      <Title>智能日清</Title>
    </System>
    <Advantage
      :pic="require('~/assets/images/products/nissin/power-bg.jpg')"
      :list="advantageList"
    >
      <Title>产品能力</Title>
    </Advantage>
    <div class="nissin-logic">
      <Title>产品架构</Title>
      <div class="common-wrap">
        <div class="framework-cell framework-cell-01">
          <div class="label">
            智能应用
          </div>
          <div class="content">
            <div class="title">
              数据挖掘
              <img
                class="line"
                src="~@/assets/images/products/nissin/line-green.png"
              >
            </div>
            <div class="application-list">
              <div class="item">
                销量预测
              </div>
              <div class="item">
                智能补货
              </div>
              <div class="item">
                计划执行
              </div>
            </div>
          </div>
        </div>
        <div class="framework-cell framework-cell-02">
          <div class="label">
            <span>数据中台</span>
            <div class="logo">
              <img src="~@/assets/images/products/cockpit/simba-logo.png">
            </div>
          </div>
          <div class="data-content">
            <div class="framework-form">
              <img
                class="arrows"
                src="~@/assets/images/products/nissin/arrows-green.png"
              >
              <div class="framework-flex">
                <div class="flex-item">
                  <div class="title">
                    特征提取
                    <img
                      class="line"
                      src="~@/assets/images/products/replenish/line02.png"
                    >
                  </div>
                  <div class="framework-content">
                    <p>商品特征</p>
                    <p>人群特征</p>
                    <p>环境特征</p>
                  </div>
                </div>
                <div class="flex-item">
                  <div class="title">
                    数据挖掘
                    <img
                      class="line"
                      src="~@/assets/images/products/replenish/line02.png"
                    >
                  </div>
                  <div class="framework-content">
                    <p>关联分析</p>
                    <p>聚类分析</p>
                  </div>
                </div>
                <div class="flex-item">
                  <div class="title">
                    机器学习
                    <img
                      class="line"
                      src="~@/assets/images/products/replenish/line02.png"
                    >
                  </div>
                  <div class="framework-content">
                    <p>回归模型 集成学习</p>
                    <p>神经网络 深度学习</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="framework-form framework-form-02">
              <img
                class="arrows"
                src="~@/assets/images/products/nissin/arrows-blue.png"
              >
              <div class="title">
                数据开发
                <img
                  class="line"
                  src="~@/assets/images/products/replenish/line02.png"
                >
              </div>
              <div class="framework-flex">
                <div class="flex-item-p">
                  <p>ODS 数据操作</p>
                </div>
                <div class="flex-item-p">
                  <p>CDM 数据汇总/维度建模</p>
                </div>
                <div class="flex-item-p">
                  <p>ADS 数据应用</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="framework-cell framework-cell-03">
          <div class="label">
            数据源
          </div>
          <div class="content">
            <div class="framework-list">
              <img
                class="arrows"
                src="~@/assets/images/products/nissin/arrows-black.png"
              >
              <div class="list-item">
                <div class="title">
                  历史数据
                  <img
                    class="line"
                    src="~@/assets/images/products/replenish/line03.png"
                  >
                </div>
                <div class="list-content">
                  <p>商超数据</p>
                  <p>近几年的销售数据</p>
                  <p>商品数据</p>
                </div>
              </div>
              <div class="list-item">
                <div class="title">
                  现时数据
                  <img
                    class="line"
                    src="~@/assets/images/products/replenish/line03.png"
                  >
                </div>
                <div class="list-content">
                  <p>新商品数据</p>
                  <p>现时商超</p>
                  <p>销售数据</p>
                </div>
              </div>
              <div class="list-item">
                <div class="title">
                  第三方数据
                  <img
                    class="line"
                    src="~@/assets/images/products/replenish/line03.png"
                  >
                </div>
                <div class="list-content">
                  <p>天气</p>
                  <p>节假日数据</p>
                  <p>其他数据</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="business-successful">
      <div class="common-wrap">
        <Title>客户成功</Title>
        <energy :energy-data="energyData" />
      </div>
    </div>
  </div>
</template>

<script>
import Title from "./components/Title/index";
import Swipe from "./components/Swipe/index";
import System from "./components/Swipe/system";
import Advantage from "./components/Swipe/advantage";
import energy from "./components/energy/index";

import LogoHover from "~/assets/images/customerLogo/xianfenshenghuo-hover.png";

export default {
  components: {
    Title,
    Swipe,
    System,
    Advantage,
    energy
  },
  transition: "bounce",
  data() {
    return {
      title: "奇点云-智能日清",
      energyData: [
        {
          name: "鲜风生活",
          logoHover: LogoHover,
          banner: require("~/assets/images/customerLogo/xianfenshenghuoBanner.png"),
          des:
            "基于数据中台，助力鲜风生活打通业务系统，统一数据标准和口径，为后续数据化经营管<br/>理打下基础。在数据基础上提炼、搭建算法模型，分析缺货、断货及促销因素。<br/>基于行业理解，全面挖掘影响销量的因素，完成价格变化、节假日、天气、库存等共计<br/>139个变量因素的梳理，大幅提升了销量预测精准度，同时辅助补货决策。<br/>与传统方式和手工预测模型相比，借助智能日清模型的销量预测准确率提升了20%以上。<br/>提升了售罄率，降低了缺货率和损耗率，减少了负毛利的促清打折，降低了非自然损耗<br/>量，从而了降低成本。自实施以来，单店当年提升了340万元毛利。"
        }
      ],
      advantageList: [
        {
          icon: require("~/assets/images/products/nissin/icon01.png"),
          title: "销量精准预测",
          desc:
            "根据历史数据、天气、交通等多维度数据，结合农历节日等特殊日期与品类的知识图谱，拟合折扣对销量的影响，得出精准<br/>的销量预测结果。"
        },
        {
          icon: require("~/assets/images/products/nissin/icon02.png"),
          title: "真实需求量还原",
          desc:
            "排除折扣等因素干扰，得出商品无折扣销量预测结果，从销售量还原真实需求量。"
        },
        {
          icon: require("~/assets/images/products/nissin/icon03.png"),
          title: "供应商及物流能力模型",
          desc: "综合上游供应商及供应链等因素，建立供应商及物流能力模型。"
        },
        {
          icon: require("~/assets/images/products/nissin/icon04.png"),
          title: "货损视觉识别",
          desc: "建立货损视觉识别，高效解决生鲜货损难以准确实时盘点的问题。"
        }
      ]
    };
  },
  head() {
    return {
      title: "数据中台_数据智能应用_数据中台解决方案_智能日清_奇点云",
      meta: [
        {
          name: "keywords",
          content: "数据中台，数据智能应用，数据中台解决方案，智能日清，奇点云"
        },
        {
          name: "description",
          content:
            "零售商如何实现日清智能化？奇点云智能日清，依托数据中台平台能力及数据中台解决方案，实现日清数据智能应用。"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.nissin-container {
  .swipe-box {
    background: url("~@/assets/images/products/nissin/swipe.jpg") center 0;
    background-size: auto 100%;
  }
  .advantage {
    height: 1120px;
  }
  .nissin-logic {
    padding: 110px 0 120px 0;
    .q-title {
      padding-bottom: 70px;
    }
    .framework-cell {
      display: flex;
      align-items: center;
      padding: 20px 0;
      background: #eff8ff;
      margin-bottom: 57px;
      .label {
        width: 181px;
        padding-left: 12px;
        font-size: 24px;
        font-weight: 600;
        color: #3cbdcb;
        line-height: 33px;
        background: linear-gradient(180deg, #45d8e8 0%, #005485 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: #ffffff;
        .logo {
          margin-top: 8px;
          font-size: 0;
          img {
            height: 32px;
          }
        }
      }
      .arrows {
        position: absolute;
        top: -62px;
        width: 20px;
        left: 50%;
        transform: translate(-50%, 0);
      }
      .content {
        flex: 1;
        padding-right: 55px;
        .title {
          font-size: 18px;
          font-weight: 600;
          color: #3cbdcb;
          text-align: center;
          background: linear-gradient(180deg, #45d8e8 0%, #005485 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 16px;
          position: relative;
          z-index: 4;
        }
        .application-list {
          display: flex;
          margin: 0 -18px;
        }
        .item {
          flex: 1;
          height: 44px;
          line-height: 44px;
          text-align: center;
          background: #3cbdcb;
          margin: 0 18px;
          border-radius: 2px;
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .data-content {
        flex: 1;
        padding-right: 55px;
        .framework-form {
          position: relative;
          background: #dfeeff;
          border-radius: 2px;
          padding: 20px 27px;
          font-size: 18px;
          font-weight: 600;
          text-align: center;
          .title {
            color: #3cbdcb;
            background: linear-gradient(180deg, #3e9cff 0%, #0073c5 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 16px;
            position: relative;
            z-index: 4;
          }
          .framework-flex {
            display: flex;
            margin: 0 -14px;
            .flex-item {
              flex: 1;
              .framework-content {
                background: #ffffff;
                margin: 0 14px;
                height: 153px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                p {
                  color: #3cbdcb;
                  background: linear-gradient(180deg, #3e9cff 0%, #0073c5 100%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  line-height: 2;
                }
              }
            }
            .flex-item-p {
              background: #ffffff;
              flex: 1;
              margin: 0 14px;
              line-height: 44px;
              p {
                color: #3cbdcb;
                background: linear-gradient(180deg, #3e9cff 0%, #0073c5 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
          }
        }
        .framework-form-02 {
          margin-top: 57px;
          .arrows {
            top: -40px;
          }
        }
      }
      .line {
        position: absolute;
        height: 2px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .framework-list {
        display: flex;
        margin: 0 -13px;
        text-align: center;
        position: relative;
        .title {
          font-size: 18px;
          font-weight: 600;
          color: #3cbdcb;
          background: linear-gradient(180deg, #687eb0 0%, #0b2662 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 14px;
          position: relative;
        }
        .list-item {
          flex: 1;
          margin: 0 13px;
          .list-content {
            padding: 27px 44px 15px 44px;
            background: #e7eaf1;
            border-radius: 2px;
            p {
              padding-bottom: 12px;
              font-size: 18px;
              font-weight: 600;
              color: #3cbdcb;
              background: linear-gradient(180deg, #687eb0 0%, #0b2662 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
    }
    .framework-cell-02 {
      background: #eff8ff;
      .label span {
        color: #3cbdcb;
        background: linear-gradient(180deg, #3e9cff 0%, #0073c5 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .framework-cell-03 {
      background: #f8f9fb;
      margin-bottom: 0;
      .label {
        color: #3cbdcb;
        background: linear-gradient(180deg, #687eb0 0%, #0b2662 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .business-successful {
    background: #f7f9fc;
    padding: 75px 0 130px 0;
    margin-bottom: -100px;
    .q-title {
      padding-bottom: 50px;
    }
    .energy-content {
      height: 800px;
    }
  }
  @media screen and (max-width: 1440px) {
    .advantage {
      height: 820px;
    }
    .nissin-logic {
      padding: 74px 0 80px 0;
      .q-title {
        padding-bottom: 50px;
      }
      .framework-cell {
        padding: 12px 0;
        margin-bottom: 32px;
        .label {
          width: 154px;
          font-size: 20px;
          padding-left: 8px;
          .logo {
            margin-top: 4px;
            img {
              height: 24px;
            }
          }
        }
        .line {
          height: 1.5px;
        }
        .arrows {
          width: 16px;
          top: -38px;
        }
        .content {
          padding-right: 38px;
          .title {
            margin-bottom: 8px;
          }
          .application-list {
            display: flex;
            margin: 0 -10px;
          }
          .item {
            height: 38px;
            line-height: 38px;
            font-size: 16px;
            margin: 0 10px;
          }
        }
        .data-content {
          padding-right: 38px;
          .framework-form {
            padding: 16px 22px;
            font-size: 16px;
            .title {
              margin-bottom: 8px;
            }
            .framework-flex {
              margin: 0 -10px;
              .flex-item {
                .framework-content {
                  height: 104px;
                  margin: 0 10px;
                  p {
                    line-height: 1.6;
                  }
                }
              }
              .flex-item-p {
                margin: 0 10px;
                line-height: 38px;
              }
            }
          }
          .framework-form-02 {
            margin-top: 32px;
            .arrows {
              top: -26px;
            }
          }
        }
        .framework-list {
          margin: 0 -10px;
          .list-item {
            margin: 0 10px;
            .title {
              font-size: 16px;
              margin-bottom: 6px;
            }
            .list-content {
              padding: 16px 34px 8px 34px;
              p {
                font-size: 16px;
                padding-bottom: 6px;
              }
            }
          }
        }
      }
    }
    .business-successful {
      padding: 58px 0 84px 0;
      .q-title {
        padding-bottom: 32px;
      }
      .energy-content {
        height: 480px;
      }
    }
  }
}
</style>